<div class="session-wrapper-denuncia">
    <div class="container container-full-width">
        <form [formGroup]="formGroup">
            <div class="row">
                <div class="col-sm-12 col-md-10 col-lg-8 mx-auto">
                    <h4>
                        Sobre o canal de Denúncias?<br></h4>
                    <p class="classe-texto-denuncia">
                        Este Canal de Denúncias foi criado em observância ao disposto no Código de Conduta e Ética da
                        Derminas, disponível neste Site.</p><br><br>
                    <h4>
                        Quem pode utilizar?<br></h4>
                    <p class="classe-texto-denuncia">
                        O Canal de Denúncias pode ser utilizado por empregados, participantes e outras partes
                        relacionadas
                        com a Derminas.</p><br><br>
                    <h4>
                        O que pode ser informado?</h4>
                    <p class="classe-texto-denuncia">
                        O Canal de Denúncias deve ser utilizado para comunicar problemas sobre a conduta ética
                        inadequada,
                        tais como:
                        conflitos de interesse, mau uso dos recursos da Derminas, fraude, corrupção, discriminação,
                        assédio,
                        atos
                        ilícitos e não conformidade com os normativos internos e legais.<br
                            style="box-sizing:border-box;"><br style="box-sizing:border-box;">Quando relatar uma
                        denúncia,
                        inclua o máximo de informações e
                        detalhes
                        possíveis, (identificação do denunciado, possíveis testemunhas, descrição do fato supostamente
                        ocorrido, materialidade etc.).
                        Você
                        também poderá incluir arquivos que comprovem os fatos relatados, como fotos, documentos e
                        outros.
                        Denúncias
                        vagas, não específicas ou sem comprovação do ocorrido podem dificultar ou inviabilizar a
                        apuração.
                    </p><br><br>
                    <h4>
                        Importante!</h4>
                    <ul>
                        <li style="box-sizing:border-box;" class="classe-texto-denuncia">Não é necessário se identificar
                            para realizar ocorrências.</li>
                        <li style="box-sizing:border-box;" class="classe-texto-denuncia">O(A) denunciante é responsável
                            pela
                            veracidade das informações
                            relatadas.
                        </li>
                        <li style="box-sizing:border-box;" class="classe-texto-denuncia">Caso seja detectado o mau uso
                            deste
                            canal de comunicação,
                            o denunciante estará sujeito às sanções cabíveis.</li>
                        <li style="box-sizing:border-box;" class="classe-texto-denuncia">Denúncia caluniosa é crime,
                            conforme artigo 339 do Código Penal.
                        </li>
                        <li style="box-sizing:border-box;" class="classe-texto-denuncia">Falsa denúncia também é crime,
                            conforme artigo 340 do Código
                            Penal.</li>
                    </ul><br><br>
                    <h4>
                        E se não é nada disso que quero falar?</h4>
                    <p class="classe-texto-denuncia">
                        Caso o motivo de seu contato não seja para relatar uma conduta ética inadequada ou infrações à
                        legislação e normativos internos,
                        conforme exemplificado anteriormente, utilize os nossos outros canais.</p><br><br>
                    <h4>
                        Como funciona o Canal de Denúncias?</h4>
                    <p class="classe-texto-denuncia">
                        Para relatar algum fato, clique no botão "Registrar uma nova denúncia" e você será direcionado para um
                        formulário que deve ser preenchido. Nessa ferramenta, você poderá anexar arquivos que
                        complementem a
                        sua ocorrência.

                        Ao final, você receberá um número de protocolo do seu registro e no botão "Acompanhar minha denúncia" você poderá, consultar o andamento da ocorrência. Os registros serão
                        encaminhados para a apuração do Comitê de Ética da
                        Derminas.
                    </p><br><br>
                    <h4>
                        O Canal de Denúncias é confidencial?</h4>
                    <p class="classe-texto-denuncia">
                        Todos os relatos serão avaliados e respondidos a cada usuário, individualmente, e com total
                        privacidade,
                        através desse Site e do número de protocolo.</p><br><br>
                </div>
            </div>

            <div class="row" style="margin-bottom: 20px;">
                <div class="col-sm-12 col-md-10 col-lg-8 mx-auto">
                    <div class="form-check">
                        <label class="form-check-label">
                            <input type="checkbox" formControlName="aceite"> Estou ciente das sanções em caso de
                            denúncia caluniosa ou falsa denúncia.
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-10 col-lg-8 mx-auto">
                    <div class="row">
                        <div class="col-6">
                            <button type="button" class="btn btn-primary" [disabled]="!formGroup.get('aceite').value"
                                (click)="nova()">Registrar uma nova
                                denúncia</button>
                        </div>

                        <div class="col-6">
                            <button type="button" class="btn btn-primary" (click)="acompanhar()">Acompanhar minha
                                denúncia</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>