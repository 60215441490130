<div id="topocontato" class="section-gap-mini"></div>
<div class="contact-widget section-gap">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p class="text-center" style="font-size: 30px; font-weight: bold;">Política de Privacidade e Proteção de
                    Dados Pessoais - Adequação a LGPD</p>
                <br />
                <p style="font-size: 16px; font-weight: bold;">1. Introdução</p>
                <p>A DERMINAS, em decorrência de sua condição de administradora de plano de benefício de
                    caráter previdenciário, coleta, classifica, armazena, utiliza, recebe e transfere dados pessoais,
                    sem o que é inviável o exercício de sua missão e por consequência de suas atividades
                    direcionadas para o bem-estar e a segurança de seus participantes, assistidos, colaboradores
                    e respectivos familiares.</p>
                <p>Entretanto, a DERMINAS, ao tratar tais dados, preocupa-se em preservar a privacidade dos
                    titulares desses dados e a autodeterminação informacional, ciente de que é direito
                    fundamental de todo indivíduo, garantido constitucionalmente, não sofrer intromissão em
                    sua vida privada e familiar nem ter acessadas as informações relacionadas à sua privacidade
                    sem a sua permissão.</p>
                <p>Nesse contexto, faz-se necessário estabelecer política de proteção de dados pessoais, descrita
                    neste documento, adequando as atividades, os processos de trabalho e as rotinas da
                    DERMINAS às disposições da Lei nº 13.709, de 14 de agosto de 2018, a chamada Lei Geral de
                    Proteção de Dados Pessoais - LGPD.</p>
                <p>Por meio deste instrumento, pretende-se assegurar às autoridades públicas e aos titulares das
                    informações, clareza, precisão e acessibilidade acerca do modo como a DERMINAS e os seus
                    colaboradores realizam o tratamento de dados pessoais.
                    Essa política é traçada com base na realidade inovadora que a LGPD estabeleceu no cenário
                    brasileiro de tratamento de dados pessoais, com parâmetros ainda incipientes estabelecidos
                    pela Autoridade Nacional de Proteção de Dados - ANPD, devendo, portanto, ser aperfeiçoada
                    ao longo do tempo.</p>

                <br />
                <p style="font-size: 16px; font-weight: bold;">2. Tratamento de dados pessoais</p>
                <br />
                <p style="font-size: 16px; font-weight: bold;">2.1 Dados pessoais</p>
                <p>A DERMINAS, por sua natureza de Entidade Fechada de Previdência Complementar - EFPC,
                    necessita lidar com uma quantidade substancial de dados de pessoas naturais identificadas
                    ou identificáveis, sem o que não seria capaz de cumprir suas obrigações legais e regulatórias
                    nem a missão institucional, basicamente:</p>

                <ol id="l3">
                    <li data-list-text="I.">
                        <p style="padding-left: 41pt; text-indent: -24pt;">De
                            participantes e seus dependentes, bem assim de assistidos do plano de benefícios
                            previdenciários que administra, como nome, qualificações, idade, relações de
                            parentesco, vencimentos, proventos, relacionamento bancário;</p>
                    </li>
                    <li data-list-text="II.">
                        <p style="padding-left: 41pt; text-indent: -24pt;"> Dados análogos de seus colaboradores com os
                            quais mantém relação de trabalho ou
                            estatutária;</p>
                    </li>
                    <li data-list-text="III.">
                        <p style="padding-left: 41pt; text-indent: -24pt;">Dados básicos, como nome, número de inscrição
                            no Cadastro de Pessoas Físicas - CPF
                            e endereço de representantes legais de empresas prestadoras de serviços que
                            fornecem mão de obra, serviços financeiros ou advocatícios, entre outros.</p>
                    </li>
                </ol>

                <p>Esses dados são guardados nos bancos de dados do sistema computacional e em registros
                    físicos, administrados pelos gestores e pelos demais componentes responsáveis por todas as
                    atividades relacionadas aos participantes e assistidos do plano de benefício, à administração
                    dos recursos humanos e materiais, aos investimentos, aos controles internos e à consultoria
                    jurídica.</p>
                <p>Em complemento, há dados pessoais que podem ser, eventualmente, compartilhados com
                    prestadores de serviços para a estrita finalidade de permitir a execução do objeto do contrato
                    celebrado, no qual se preveem obrigações e responsabilidades relacionadas à proteção de
                    dados pessoais.</p>
                <p>Como regra geral, são dados fornecidos voluntariamente pelos titulares, sem a necessidade
                    de consentimento expresso, quando aderem a plano de benefícios ou celebram contrato com
                    a DERMINAS, e adequados à finalidade do tratamento, pois são utilizados pela entidade para
                    realizar seus objetivos estatutários, não só para atender os interesses legítimos da DERMINAS,
                    de forma a cumprir a sua missão e as suas obrigações legais, regulatórias e contratuais, assim
                    também para satisfazer os interesses dos titulares que com ela estabelecem relação
                    contratual ou institucional.</p>
                <p>Em decorrência dos princípios e das regras da LGPD, a DERMINAS, no intuito de proteger a
                    privacidade com quem se relaciona, faz trabalho contínuo de diagnóstico, visando
                    permanecer em consonância com a lei e eliminar dados que não atendam aos princípios da
                    finalidade, da adequação e da necessidade.</p>
                <p>Há, contudo, dados pessoais que, apesar de não serem mais tratados após o fim da relação
                    jurídica entre a DERMINAS e o titular, permanecem guardados para eventual utilização como
                    meio de prova perante as autoridades públicas.</p>
                <p>Assim, em princípio, são mantidos pelo prazo prescricional previsto, conforme a situação, nas
                    leis administrativas, civis e processuais.</p>
                <p>Como administradora, a DERMINAS tem o dever de compartilhar informações com seu
                    patrocinador, o Departamento de Edificações e Estradas de Rodagem do Estado de Minas
                    Gerais e os órgãos públicos relacionados, SEPLAG, IPSEMG, tendo em vista que o
                    conhecimento dos dados é necessário ao controle por eles exercido, por autoridades públicas
                    que exercem funções regulatória e supervisora, de administração de tributos, de
                    administração previdenciária – como a Superintendência Nacional de Previdência
                    Complementar - Previc, a Secretaria Especial da Receita Federal do Brasil - RFB e o Instituto
                    Nacional de Seguro Social - INSS, respectivamente –, além do Poder Judiciário.</p>
                <p>Nas relações contratuais, excetuando-se as com previsão já abrangida por preceitos
                    originados da autorregulamentação, a DERMINAS vem inserindo cláusulas que obrigam os
                    contratantes a observar os princípios e as disposições da LGPD.</p>
                <br />
                <p style="font-size: 16px; font-weight: bold;">Base Legal</p>
                <p>O tratamento de dados realizado pela DERMINAS tem como principal embasamento o
                    disposto nos incisos II, V, VI, IX e X do art. 7º da Lei nº 13.709, de 2018. <span
                        class="s1">(1)</span></p>

                <p> <span class="s1">(1)</span> <i> Art. 7º O tratamento de dados pessoais somente poderá ser realizado
                        nas
                        seguintes hipóteses:</i></p>
                <p><i>II - Para o cumprimento de obrigação legal ou regulatória pelo controlador;</i></p>
                <p><i>V - Quando necessário para a execução de contrato ou de procedimentos preliminares relacionados a
                        contrato do qual seja parte o titular, a pedido do titular dos dados;</i></p>
                <p><i>VI - Para o exercício regular de direitos em processo judicial, administrativo ou arbitral, esse
                        último
                        nos termos da Lei nº 9.307, de 23 de setembro de 1996 (Lei de Arbitragem);</i></p>
                <p><i>IX - Quando necessário para atender aos interesses legítimos do controlador ou de terceiros,
                        exceto no
                        caso de
                        prevalecerem direitos e liberdades fundamentais do titular que exijam a proteção dos dados
                        pessoais; ou</i></p>
                <p><i>X - Para a proteção do crédito, inclusive quanto ao disposto na legislação pertinente.</i></p>
                <br />
                <p style="font-size: 16px; font-weight: bold;">2.2 Dados pessoais sensíveis</p>
                <p>A DERMINAS trata também dos dados pessoais sensíveis:</p>

                <ol id="l3">
                    <li data-list-text="I.">
                        <p style="padding-left: 41pt; text-indent: -24pt;">Origem racial ou étnica.</p>
                    </li>
                    <li data-list-text="II.">
                        <p style="padding-left: 41pt; text-indent: -24pt;"> Convicção religiosa.</p>
                    </li>
                    <li data-list-text="III.">
                        <p style="padding-left: 41pt; text-indent: -24pt;">Opinião política.</p>
                    </li>
                    <li data-list-text="IV.">
                        <p style="padding-left: 41pt; text-indent: -24pt;">Filiação a sindicato ou a organização de
                            caráter religioso, filosófico ou político.</p>
                    </li>
                    <li data-list-text="V.">
                        <p style="padding-left: 41pt; text-indent: -24pt;">Dados pessoais sobre saúde, utilizados pela
                            área de Recursos Humanos, uma vez que
                            os empregados da DERMINAS são assistidos por plano de saúde, e eventualmente
                            afastam-se do trabalho para tratamento de saúde; bem assim pela área de Benefícios,
                            porque grupo importante dos participantes é de idosos e muitos deles sofrem de
                            doenças que acarretam a isenção de Imposto de Renda Retido na Fonte - IRRF,
                            havendo também grupo de dependentes constituído por inválidos;</p>
                    </li>
                    <li data-list-text="VI.">
                        <p style="padding-left: 41pt; text-indent: -24pt;">Dados biométricos dos colaboradores, para o
                            ingresso na sede da entidade; e</p>
                    </li>
                    <li data-list-text="VII.">
                        <p style="padding-left: 41pt; text-indent: -24pt;">Estado civil dos participantes e assistidos
                            dos planos de benefícios, acompanhado do
                            respectivo detalhamento de informações, destinados à elaboração dos estudos e de
                            cálculos atuariais correspondentes.</p>
                    </li>
                </ol>

                <br />
                <p style="font-size: 16px; font-weight: bold;">Base legal</p>
                <p>O tratamento desse tipo de dados pela DERMINAS está embasado nas alíneas “a” e “d” do
                    inciso II do art. 11 da LGPD. <span class="s1">(2)</span></p>
                <p><span class="s1">(2)</span>
                    <i> Art. 11. O tratamento de dados pessoais sensíveis somente poderá ocorrer nas seguintes
                        hipóteses:</i>
                </p>
                <p><i> II - Sem fornecimento de consentimento do titular, nas hipóteses em que for indispensável
                        para:</i></p>
                <p><i> a) cumprimento de obrigação legal ou regulatória pelo controlador;</i></p>
                <p><i>d) exercício regular de direitos, inclusive em contrato e em processo judicial, administrativo e
                        arbitral, este último
                        nos termos da Lei nº 9.307, de 23 de setembro de 1996 (Lei de Arbitragem);</i></p>

                <br />
                <p style="font-size: 16px; font-weight: bold;">2.3 Dados pessoais de crianças e adolescentes</p>
                <p>
                    Os dados pessoais de crianças e adolescentes são tratados pela DERMINAS quando
                    registrados, a pedido e por indicação dos pais ou representantes legais, como
                    participante,dependente do participante ou do empregado, para fins de plano previdenciário o que é
                    feito
                    visando a proteção e o bem-estar deles.
                </p>

                <br />
                <p style="font-size: 16px; font-weight: bold;">Base legal</p>
                <p>O tratamento de dados de crianças e de adolescentes é respaldado pelo art. 14 da Lei nº
                    13.709, de 2018. <span class="s1">(3)</span></p>
                <p><span class="s1">(3)</span> <i>Art. 14. O tratamento de dados pessoais de crianças e de adolescentes
                        deverá ser realizado em seu
                        melhor
                        interesse, nos termos deste artigo e da legislação pertinente.</i></p>

                <br />
                <p style="font-size: 16px; font-weight: bold;">2.4 Direitos do titular dos dados pessoais</p>
                <p>
                    A DERMINAS reconhece que a privacidade e a autodeterminação informacional são direitos
                    individuais fundamentais e que tem o dever de proteger os dados pessoais sob sua guarda,
                    estabelecendo uma relação de boa-fé e de confiança com o titular do direito por meio da
                    transparência. </p>
                <p>O titular dos dados pessoais deve ter acesso facilitado às informações a ele relacionadas e
                    obter os esclarecimentos que considerar necessários a respeito dessas informações, podendo
                    solicitar a correção de dados incompletos, inexatos ou desatualizados e a eliminação de dados
                    desnecessários, excessivos ou tratados em desconformidade com a lei.</p>
                <p>Para tanto, a DERMINAS disponibiliza o acesso restrito aos dados pessoais por meio
                    eletrônico, constante de sua página na internet <a href="www.derminas.org.br" target="_blank">
                        www.derminas.org.br.</a></p>
                <p>Também dispõe, ainda, como canal de comunicação o e-mail <a href="mailto:derminas@derminas.org.br"
                        target="_blank">derminas@derminas.org.br</a>, com
                    o encarregado pelo tratamento de dados, por meio do qual podem ser dirigidos pedidos de
                    esclarecimentos e de providências, como também reclamações, cabendo-lhe envidar todos os
                    esforços para o pronto atendimento do interessado e a apresentação de solução ao eventual
                    problema.</p>

                <br />
                <p style="font-size: 16px; font-weight: bold;">3. Agentes de Tratamento de dados pessoais</p>
                <br />
                <p style="font-size: 16px; font-weight: bold;">3.1 Controlador</p>
                <p>Por intermédio de seus órgãos estatutários, a DERMINAS exerce a competência para adotar
                    as decisões referentes ao tratamento de dados pessoais. Esses órgãos estão integralmente
                    comprometidos e envolvidos com a implementação da Política de Proteção de Dados Pessoais
                    - PPDP, não medindo esforços para que toda a entidade se engaje nas atividades pertinentes.</p>

                <br />
                <p style="font-size: 16px; font-weight: bold;">3.2 Operador</p>
                <p>O prestador de serviço com o qual a DERMINAS mantém relação jurídica que, na realização do
                    objeto do contrato, trata dados pessoais em nome da entidade, de acordo com a finalidade e
                    as instruções por ela definidas.
                    As obrigações do Operador, referentes à proteção de dados pessoais, são estabelecidas na
                    LGPD e no contrato celebrado entre ele e a DERMINAS.</p>

                <br />
                <p style="font-size: 16px; font-weight: bold;">3.3 Encarregado</p>
                <p>O Diretor de Seguridade, é a pessoa que atua como canal de comunicação entre a DERMINAS,
                    os titulares e a autoridade nacional. Ele é o encarregado por recolher as reclamações e
                    comunicações dos titulares e da ANPD e provocar os órgãos competentes para a adoção das
                    iniciativas apontadas pela autoridade pública.</p>
                <p>Outrossim, incumbe-lhe orientar os órgãos afins da DERMINAS acerca das boas práticas para
                    a proteção de dados e a segurança da informação, além de cumprir e fazer cumprir as decisões
                    do Controlador, a DERMINAS.</p>
                <br />
                <p style="font-size: 16px; font-weight: bold;">3.4 Responsabilidade dos agentes de tratamento</p>
                <p>A DERMINAS, na condição de controladora, e os seus colaboradores estão comprometidos
                    com o exercício das atividades de tratamento de dados nos estritos termos da legislação
                    protetiva e engajados na adoção das medidas de segurança técnicas e administrativas
                    compatíveis com o porte da entidade, de modo a eliminar, dentro do possível, os riscos de
                    danos patrimonial e moral ao titular dos dados, cientes de que o tratamento ilícito ou
                    inadequado poderá obriga-los a ressarcir a vítima além de sujeita-los às sanções
                    administrativas aplicáveis pela ANPD.</p>

                <br />
                <p style="font-size: 16px; font-weight: bold;">4. Segurança de dados e boas práticas de salvaguardas</p>
                <p>A DERMINAS implementa medidas de segurança para proteger os dados pessoais daqueles
                    que com ela se relacionam, procurando evitar acessos indevidos e o uso inadequado ou ilícito
                    de tais informações. Nesse propósito, segue os padrões técnicos recomendados pelos
                    especialistas, conforme estabelecido no Regulamento de Segurança em Tecnologia da
                    Informação - RSTI.</p>
                <p>Na eventualidade de falhas envolvendo a segurança dos dados, como acidentes relacionados
                    a perda ou a vazamentos, a DERMINAS informará imediatamente o titular e a ANPD.</p>
                <p>Como adoção de caráter permanente buscamos estimular nossos colaboradores e
                    prestadores de serviços a contribuírem para a consolidação da cultura de salvaguarda dos
                    dados pessoais e das informações por eles mantidas e geridas, de modo a que sejam
                    incorporados na prática cotidiana os cuidados indispensáveis preconizados.</p>
                <br />
                <p><span style="font-weight: bold;">5.</span> Esta Política passa a vigorar a partir da data de sua
                    aprovação pelo Conselho Deliberativo
                    da DERMINAS.</p>
            </div>
            <!-- <div class="col-6 text-center" style="margin-top: 100px">
                <p style="font-weight: bold;">Álvaro Eduardo Goulart</p>
                <p>Presidente do Conselho Deliberativo</p>
            </div>
            <div class="col-6 text-center" style="margin-top: 100px">
                <p style="font-weight: bold;">Luiz Gonzaga Chaves Campos</p>
                <p>Diretor Superintendente</p>
            </div> -->
        </div>
    </div>
</div>