import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AcessoRestritoSiteService } from 'app/services/acesso-restrito.service';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ValidadorService } from 'app/services/validador.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-simulacao-emprestimo',
  templateUrl: './simulacao-emprestimo.component.html',
  styleUrls: ['./simulacao-emprestimo.component.css']
})
export class SimulacaoEmprestimoComponent implements OnInit {
  formGroup: FormGroup;
  erromessage = '';
  calculo: any;
  inSpinner = false;
  idades: any[] = [
    { displayName: 'Menor ou igual a 69 anos', value: 1 },
    { displayName: 'De 70 anos a 74 anos', value: 2 },
    { displayName: 'De 75 anos a 79 anos', value: 3 },
    { displayName: 'Maior ou igual a 80 anos', value: 4 }
  ];

  constructor(
    private formBuilder: FormBuilder,
    public validadorService: ValidadorService,
    public acessoRestritoSiteService: AcessoRestritoSiteService,
    public servico: SiteService,
    public router: Router,
    public mensagensService: MensagensService
  ) {
    this.formGroup = this.formBuilder.group({
      salarioLiquido: [null, Validators.required],
      margem: [null, Validators.required],
      valorSolicitado: [null],
      idade: [1, Validators.required],
      prazo: [null, Validators.required]
    });
  }

  ngOnInit(): void {
  }

  onBlurSalario() {
    this.formGroup.patchValue({
      margem: (this.formGroup.value.salarioLiquido / 5)
    });
  }

  calcular() {
    if (this.formGroup.valid) {
      this.erromessage = '';
      switch (parseInt(this.formGroup.value.idade, 10)) {
        case 1: {
          if (this.formGroup.value.prazo < 3 || this.formGroup.value.prazo > 48) {
            this.calculo = undefined;
            this.erromessage = 'A quantidade de parcelas não pode ser inferior a 3 ou maior que 48 nesta faixa etária.';
            return;
          }
          break;
        }
        case 2: {
          if (this.formGroup.value.prazo < 3 || this.formGroup.value.prazo > 36) {
            this.calculo = undefined;
            this.erromessage = 'A quantidade de parcelas não pode ser inferior a 3 ou maior que 36 nesta faixa etária.';
            return;
          }
          break;
        }
        case 3: {
          if (this.formGroup.value.prazo < 3 || this.formGroup.value.prazo > 24) {
            this.calculo = undefined;
            this.erromessage = 'A quantidade de parcelas não pode ser inferior a 3 ou maior que 24 nesta faixa etária.';
            return;
          }
          break;
        }
        case 4: {
          if (this.formGroup.value.prazo < 3 || this.formGroup.value.prazo > 12) {
            this.calculo = undefined;
            this.erromessage = 'A quantidade de parcelas não pode ser inferior a 3 ou maior que 12 nesta faixa etária.';
            return;
          }
          break;
        }
        default: {
          return;
        }
      }

      const model: any = {
        salarioLiquido: this.formGroup.value.salarioLiquido,
        margem: this.formGroup.value.margem,
        valorSolicitado: this.formGroup.value.valorSolicitado,
        idade: this.formGroup.value.idade,
        prazo: this.formGroup.value.prazo
      };

      this.servico.calculo(model)
        .pipe(finalize(() => {
        }))
        .subscribe(result => {
          this.calculo = result;
        },
          err => {
            this.calculo = undefined;
            const erro = this.mensagensService.recuperarMensagemErro(err);
            this.mensagensService.Error('Ocorreu um Erro!', erro);
          });


    } else {
      this.calculo = undefined;
      this.inSpinner = false;
      this.validadorService.validarTodosCampos(this.formGroup);
      this.erromessage = 'Existem campos obrigatórios sem o devido preenchimento';
    }

  }
  print() {
    window.print();
  }
  limpar() {
    this.formGroup.reset();
    this.calculo = undefined;
    setTimeout(() => {
      this.formGroup.patchValue({
        idade: 1
      });
    }, 5);
  }
}
