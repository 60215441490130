<!-- Sign In Layout -->
<div style="padding: 8rem 0 5rem; margin-bottom: 3rem;">
    <div class="container container-full-width">
        <div class="row">
            <div class="col-sm-12 col-md-11 col-lg-9 mx-auto">
                <div class="row no-gutters">
                    <div class="col-sm-12 col-md-3 d-xs-none">
                        &nbsp; &nbsp;
                    </div>

                    <div class="col-sm-12 col-md-6 py-5">
                        <div class="card border-0 p-4 rounded-0 card-shadow">
                            <div class="form-group mb-4" *ngIf="erromessage.length > 0">
                                <div class="alert alert-danger" role="alert">
                                    {{erromessage}}
                                </div>
                            </div>
                            <div class="form-group mb-4" *ngIf="sucessoRecuperar">
                                <div class="alert alert-success" role="alert">
                                    {{successmessage}}
                                </div>
                            </div>
                            <form class="session-form" [formGroup]="formGroupRecuperar">
                                <div class="social-widget mb-4" style="text-align: center;">
                                    <h2 class="text-capitalize font-2x mb-4">Recuperar Senha</h2>
                                </div><!-- Social widget closed -->
                                <div class="mb-5">
                                    <div class="form-group">
                                        <label for="InputEmail1">CPF <b style="color: red;">*</b></label>
                                        <input type="text" class="form-control form-control-lg" id="InputEmail1"
                                            formControlName="cpf" mask="000.000.000-00" (keyup.enter)="recuperar()"
                                            [ngClass]="validadorService.mostrarCSSErro('cpf', formGroupRecuperar)"
                                            required>
                                        <div class="invalid-feedback">
                                            O campo obrigatório!
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="matricula">Matricula <b style="color: red;">*</b></label>
                                        <input type="matricula" class="form-control form-control-lg" id="matricula"
                                            maxlength="6" formControlName="matricula" (keyup.enter)="recuperar()"
                                            [ngClass]="validadorService.mostrarCSSErro('matricula', formGroupRecuperar)"
                                            required>
                                        <div class="invalid-feedback">
                                            A Matricula é obrigatório!
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="email">E-mail <b style="color: red;">*</b></label>
                                        <input type="email" class="form-control form-control-lg" id="email" type="email"
                                            maxlength="250" formControlName="email" (keyup.enter)="recuperar()"
                                            [ngClass]="validadorService.mostrarCSSErro('email', formGroupRecuperar)"
                                            required>
                                        <div class="invalid-feedback">
                                            O E-mail é obrigatório!
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="button" (click)="recuperar()" [disabled]="inSpinnerRecuperar" *ngIf="!sucessoRecuperar"
                                        class="btn btn-primary btn-block btn-lg submit-btn">
                                        <i class="fa fa-spinner fa-spin" aria-hidden="true"
                                            *ngIf="inSpinnerRecuperar"></i>
                                        {{inSpinnerRecuperar ? ' Validando...' : ' Acessar'}}
                                    </button>
                                </div>
                                <div class="form-group text-center">
                                    <p><a style="cursor: pointer;" (click)="voltar()">
                                            Fazer Login</a></p>
                                </div>
                            </form><!-- session form closed -->
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-3 d-xs-none">
                        &nbsp; &nbsp;
                    </div>
                </div>
            </div>
        </div>
    </div><!-- container closed -->
</div><!-- session Wrapper closed -->