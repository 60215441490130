<div class="page-main-container" style="padding-top: 4%; margin-bottom: 120px;"
    [style.margin-bottom]="router?.url !== '/home' ? '120px' : '1px'">
    <div class="testimonial-section">
        <!-- section-gap -->
        <div class="">
            <div class="container">
                <app-fique-por-dentro></app-fique-por-dentro>
            </div>
        </div>
    </div>
    <div class="post-section bg-light section-gap-sessao2">
        <div class="container">

            <div class="card-deck">
                <app-mais-informacoes class="row"></app-mais-informacoes>
            </div>
        </div>
    </div>
    <div class="service section-gap-sessao3">
        <div class="container">
            <app-institucional-derminas class="row"></app-institucional-derminas>
        </div>
    </div>

    <!-- <div class="section-gap bg-light">
        <div class="container">
            <div class="row" style="padding: 20px;">
                <app-aniversariantes-home style="width: 100%;"></app-aniversariantes-home>
            </div>
        </div>
    </div> -->

    <!-- <div class="section-gap bg-light">
        <div class="container">
            <app-home-denuncia style="width: 100%;"></app-home-denuncia>
        </div>
    </div> -->



    <div class="contact-section section-gap-sessao4">
        <div class="container">
            <div class="section-title-wrapper">
                <div class="row">
                    <div class="col-sm-12 col-md-10 mx-auto text-center">
                        <!-- <h2>Onde Estamos </h2> -->
                        <div class="follow-us text-center" style="width: 100%; padding-bottom: 20px;">
                            <div class="container">
                                <div class="mb-5">
                                    <h5> Siga-nos nas redes sociais.</h5>
                                </div>
                                <div class="social-icon-widget">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item mx-2"><a
                                                (click)="abrirLink('https://www.facebook.com/derminasseguridade')"
                                                style="cursor: pointer;" class="square-60 rounded-circle bg-facebook"><i
                                                    class="fa fa-facebook fa-inverse"></i></a></li>
                                        <li class="list-inline-item mx-2"><a
                                                (click)="abrirLink('https://x.com/derminas1')" style="cursor: pointer;"
                                                class="square-60 rounded-circle bg-twitter-x"><i
                                                    class="fa fa-twitter fa-inverse"></i></a></li>
                                        <li class="list-inline-item mx-2"><a
                                                (click)="abrirWhatsApp(contact?.whatsApp, contact?.mensagemWhatsApp)"
                                                style="cursor: pointer;" class="square-60 rounded-circle bg-whatsapp"><i
                                                    class="fa fa-whatsapp fa-inverse"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row" angly-homeContact [contact]="contact"></div> -->
        </div>
    </div>
    <cookie-law #cookieLaw name="derminasCookieLaw" (isSeen)="cookieLawSeen = $event" learnMore="/false" target="_blank"
        expiration="30">
        <div class="row">
            <div class="col-sm-12 col-md-9 col-xl-9 col-lg-9 mx-auto text-center">
                Olá, o portal DERMINAS utiliza cookies para melhorar a sua experiência em nossos serviços.
                Ao navegar no nosso portal você concorda com estas condições.
            </div>
            <div class="col-sm-12 col-md-3 col-xl-3 col-lg-3 mx-auto text-center">
                <a href="javascript:void(0)" (click)="dismiss()" style="text-decoration: none!important;"
                    class="text-uppercase btn btn-outline-info btn-sm">
                    Aceitar e Continuar
                </a>
            </div>
        </div>
    </cookie-law>
</div>
<!-- <p-dialog [(visible)]="display" styleClass="avisosite">
    <a style="cursor: pointer;" (click)="abrir(aviso?.link)" *ngIf="aviso !== undefined">
        <button type="button" (click)="$event.stopPropagation(); fechar()" class="mfp-close">×</button>
        <img [src]="aviso?.imagem" alt="aviso site derminas" class="mydialog" />
    </a>
</p-dialog> -->