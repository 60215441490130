import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { AcessoRestritoSiteService } from 'app/services/acesso-restrito.service';
import { EventosService } from 'app/services/eventos.service';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ValidadorService } from 'app/services/validador.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-acesso-restrito-reset',
  templateUrl: './acesso-restrito-reset.component.html',
  styleUrls: ['./acesso-restrito-reset.component.css']
})
export class AcessoRestritoResetComponent implements OnInit, OnDestroy {
  formGroupRecuperar: FormGroup;
  inSpinner = false;
  inSpinnerNovo = false;
  sucessoCriar = false;
  sucessoRecuperar = false;
  inSpinnerRecuperar = false;
  erromessage = '';
  successmessage = '';
  private _unsubscribeAll: Subject<any>;

  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    private eventosService: EventosService,
    public validadorService: ValidadorService,
    public servico: SiteService,
    private acessoRestritoSiteService: AcessoRestritoSiteService,
    public router: Router,
    public mensagensService: MensagensService
  ) {
    /* Page title */
    this.pageTitleService.setTitle('Recuperar Minha Senha');
    /* Page subTitle */
    this.pageTitleService.setSubTitle('');

    this.formGroupRecuperar = this.formBuilder.group({
      matricula: [null, Validators.required],
      cpf: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]]
    });
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.eventosService.onUsuarioLogou
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(selectedTodos => {
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  voltar() {
    this.router.navigate(['acesso-restrito']);
  }


  recuperar() {
    this.erromessage = '';
    this.sucessoRecuperar = false;
    if (this.formGroupRecuperar.valid) {
      this.inSpinnerRecuperar = true;
      this.acessoRestritoSiteService.recuperar(this.formGroupRecuperar.value)
        .pipe(finalize(() => {
          this.inSpinnerRecuperar = false;
        }))
        .subscribe(result => {
          if (result.sucesso) {
            this.erromessage = '';
            this.sucessoCriar = false;
            this.sucessoRecuperar = true;
            this.successmessage = result.mensagem;
            this.formGroupRecuperar.disable();
          } else {
            this.erromessage = result.mensagem;
            this.sucessoCriar = false;
            this.sucessoRecuperar = false;
            this.successmessage = '';
          }
        },
          err => {
            this.inSpinnerRecuperar = false;
            this.sucessoRecuperar = false;
            const erro = this.mensagensService.recuperarMensagemErro(err);
            this.erromessage = erro;
          });
    } else {
      this.inSpinnerRecuperar = false;
      this.sucessoRecuperar = false;
      this.validadorService.validarTodosCampos(this.formGroupRecuperar);
      this.erromessage = 'Existem campos obrigatórios sem o devido preenchimento';
    }
  }

}
