<div id="toperror"></div>
<div class="session-wrapper" *ngIf="emPesquisa">
    <div class="container container-full-width">
        <div class="row">
            <div class="col-sm-12 col-md-10 col-lg-8 mx-auto">
                <div class="thankyou-wrap">
                    <div class="card border-0 border-rad card-shadow p-4 w-65 mx-auto card-hover">
                        <div class="social-without-bg mb-4">
                            <div class="form-group mb-4" *ngIf="erroresposta.length > 0">
                                <div class="alert alert-danger" role="alert" style="font-size: 14px;">
                                    {{erroresposta}}
                                </div>
                            </div>
                            <form [formGroup]="formGroup" *ngIf="erroresposta.length === 0">
                                <div class="form-group mb-4" *ngIf="erromessage.length > 0">
                                    <div class="alert alert-danger" role="alert">
                                        {{erromessage}}
                                    </div>
                                </div>
                                <!-- <div class="form-group mb-4 text-center">
                                    <a class="btn btn-sm" style="cursor: pointer;" (click)="selecionar('PENSIONISTA')"
                                        [ngStyle]="{'color': formGroup.get('tipoParticipante').value === 'PENSIONISTA' ? '#FFFFFF' : '#637282' }"
                                        [ngClass]="{'btn-primary': formGroup.get('tipoParticipante').value === 'PENSIONISTA', 'btn-outline-primary': formGroup.get('tipoParticipante').value !== 'PENSIONISTA' }">
                                        PENSIONISTA </a>
                                    <a style="cursor: pointer;" class="btn btn-outline-primary btn-sm"
                                        (click)="selecionar('PARTICIPANTE')"
                                        [ngStyle]="{'color': formGroup.get('tipoParticipante').value === 'PARTICIPANTE' ? '#FFFFFF' : '#637282' }"
                                        [ngClass]="{'btn-primary': formGroup.get('tipoParticipante').value === 'PARTICIPANTE', 'btn-outline-primary': formGroup.get('tipoParticipante').value !== 'PARTICIPANTE' }">
                                        PARTICIPANTE </a>
                                    <p>
                                        <small class="text-muted"
                                            *ngIf="formGroup.get('tipoParticipante').hasError('selecionarTipo')"><span
                                                style="color: red;">Selecione uma opção.</span></small>
                                    </p>

                                </div>
                                <div class="form-group mb-4">
                                    <input id="nomeParticipante" type="text" class="form-control"
                                        placeholder="Seu nome é opcional" maxlength="200"
                                        formControlName="nomeParticipante">
                                </div> -->
                                <div class="form-group mb-4">
                                    <div class="check-payment">
                                        <p>1 - De forma geral, quão satisfeito você está com nosso site?</p>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio5" name="resposta1"
                                                    value="5" type="radio" formControlName="resposta1">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Muito satisfeito</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio4" name="resposta1"
                                                    value="4" type="radio" formControlName="resposta1">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Satisfeito</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio3" name="resposta1"
                                                    value="3" type="radio" formControlName="resposta1">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Indiferente</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio2" name="resposta1"
                                                    value="2" type="radio" formControlName="resposta1">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Insatisfeito</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio1" name="resposta1"
                                                    value="1" type="radio" formControlName="resposta1">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Muito insatisfeito</span>
                                            </label>
                                        </div>
                                        <p>
                                            <small class="text-muted"
                                                *ngIf="formGroup.get('resposta1').hasError('selecionarOpcao')"><span
                                                    style="color: red;">Selecione uma resposta.</span></small>
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group mb-4">
                                    <div class="check-payment">
                                        <p>2 - Quão satisfeito você ficou com as informações incluídas em nosso site?
                                        </p>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio6" name="resposta2"
                                                    value="5" type="radio" formControlName="resposta2">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Muito satisfeito</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio7" name="resposta2"
                                                    value="4" type="radio" formControlName="resposta2">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Satisfeito</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio8" name="resposta2"
                                                    value="3" type="radio" formControlName="resposta2">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Indiferente</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio9" name="resposta2"
                                                    value="2" type="radio" formControlName="resposta2">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Insatisfeito</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio10" name="resposta2"
                                                    value="1" type="radio" formControlName="resposta2">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Muito insatisfeito</span>
                                            </label>
                                        </div>
                                        <p>
                                            <small class="text-muted"
                                                *ngIf="formGroup.get('resposta2').hasError('selecionarOpcao')"><span
                                                    style="color: red;">Selecione uma resposta.</span></small>
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group mb-4">
                                    <div class="check-payment">
                                        <p>3 - Qual o principal motivo que o trouxe ao site?</p>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio11" name="resposta3"
                                                    value="1" type="radio" formControlName="resposta3">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Alteração cadastral</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio12" name="resposta3"
                                                    value="2" type="radio" formControlName="resposta3">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Consulta empréstimo</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio13" name="resposta3"
                                                    value="3" type="radio" formControlName="resposta3">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Contracheques</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio14" name="resposta3"
                                                    value="4" type="radio" formControlName="resposta3">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Informações
                                                    institucionais</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio15" name="resposta3"
                                                    value="5" type="radio" formControlName="resposta3">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Documentos para requerer
                                                    benefícios</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio16" name="resposta3"
                                                    value="6" type="radio" formControlName="resposta3">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Documentos para solicitar
                                                    empréstimos</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio17" name="resposta3"
                                                    value="7" type="radio" formControlName="resposta3">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Educação financeira</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio18" name="resposta3"
                                                    value="8" type="radio" formControlName="resposta3">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Informativo</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio19" name="resposta3"
                                                    value="9" type="radio" formControlName="resposta3">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Informe de rendimentos</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio20" name="resposta3"
                                                    value="10" type="radio" formControlName="resposta3">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Recadastramento anual</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio21" name="resposta3"
                                                    value="11" type="radio" formControlName="resposta3">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Simulação de empréstimo</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio22" name="resposta3"
                                                    value="12" type="radio" formControlName="resposta3">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Outro</span>
                                            </label>
                                        </div>
                                        <p>
                                            <small class="text-muted"
                                                *ngIf="formGroup.get('resposta3').hasError('selecionarOpcao')"><span
                                                    style="color: red;">Selecione uma resposta.</span></small>
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group mb-4">
                                    <div class="check-payment">
                                        <p>4 - Você achou o que precisava?</p>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio23" name="resposta4"
                                                    value="1" type="radio" formControlName="resposta4">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Sim, tudo.</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio24" name="resposta4"
                                                    value="2" type="radio" formControlName="resposta4">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Sim, uma parte</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio24" name="resposta4"
                                                    value="3" type="radio" formControlName="resposta4">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Não, nada</span>
                                            </label>
                                        </div>
                                        <p>
                                            <small class="text-muted"
                                                *ngIf="formGroup.get('resposta4').hasError('selecionarOpcao')"><span
                                                    style="color: red;">Selecione uma resposta.</span></small>
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group mb-4">
                                    <div class="check-payment">
                                        <p>5 - Com relação ao seu grau de conhecimento sobre o mercado financeiro e os investimentos da Derminas você está?
                                        </p>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio26" name="resposta5"
                                                    value="5" type="radio" formControlName="resposta5">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Muito satisfeito</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio27" name="resposta5"
                                                    value="4" type="radio" formControlName="resposta5">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Satisfeito</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio28" name="resposta5"
                                                    value="3" type="radio" formControlName="resposta5">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Indiferente</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio29" name="resposta5"
                                                    value="2" type="radio" formControlName="resposta5">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Insatisfeito</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio30" name="resposta5"
                                                    value="1" type="radio" formControlName="resposta5">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Muito insatisfeito</span>
                                            </label>
                                        </div>
                                        <p>
                                            <small class="text-muted"
                                                *ngIf="formGroup.get('resposta5').hasError('selecionarOpcao')"><span
                                                    style="color: red;">Selecione uma resposta.</span></small>
                                        </p>
                                    </div>
                                </div>


                                <div class="form-group mb-4">
                                    <div class="check-payment">
                                        <p>6 - De forma geral, quão satisfeito você está com seus hábitos financeiros?
                                        </p>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio31" name="resposta6"
                                                    value="5" type="radio" formControlName="resposta6">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Muito satisfeito</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio32" name="resposta6"
                                                    value="4" type="radio" formControlName="resposta6">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Satisfeito</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio33" name="resposta6"
                                                    value="3" type="radio" formControlName="resposta6">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Indiferente</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio34" name="resposta6"
                                                    value="2" type="radio" formControlName="resposta6">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Insatisfeito</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio35" name="resposta6"
                                                    value="1" type="radio" formControlName="resposta6">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Muito insatisfeito</span>
                                            </label>
                                        </div>
                                        <p>
                                            <small class="text-muted"
                                                *ngIf="formGroup.get('resposta6').hasError('selecionarOpcao')"><span
                                                    style="color: red;">Selecione uma resposta.</span></small>
                                        </p>
                                    </div>
                                </div>

                                <div class="form-group mb-4">
                                    <div class="check-payment">
                                        <p>7 - Você faz poupança/investe pensando no seu futuro?
                                        </p>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio36" name="resposta7"
                                                    value="1" type="radio" formControlName="resposta7">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Sim, me preocupo e planejo meu futuro</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio37" name="resposta7"
                                                    value="2" type="radio" formControlName="resposta7">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Um pouco, as vezes poupo/invisto, mas gasto quando preciso</span>
                                            </label>
                                        </div>
                                        <div class="mb-3">
                                            <label class="custom-control custom-radio mb-0">
                                                <input class="custom-control-input" id="radio38" name="resposta7"
                                                    value="3" type="radio" formControlName="resposta7">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description">Não, não sobra para poupar/investir</span>
                                            </label>
                                        </div>
                                        <p>
                                            <small class="text-muted"
                                                *ngIf="formGroup.get('resposta7').hasError('selecionarOpcao')"><span
                                                    style="color: red;">Selecione uma resposta.</span></small>
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group mb-4">
                                    <p>8 - Se você não achou o que precisava, diga-nos quais informações você estava
                                        procurando.</p>
                                    <textarea id="mensagem" class="form-control" rows="5" maxlength="1495"
                                        formControlName="observacoes"></textarea>
                                </div>
                                <div class="form-group mb-4">
                                    <p>9 - Deixe um comentário, sugestão ou reclamação sobre nosso site.</p>
                                    <textarea id="mensagem" class="form-control" rows="5" maxlength="1495"
                                        formControlName="comentarios"></textarea>
                                </div>
                            </form>
                        </div>
                        <button class="btn btn-primary mr-2 mb-2 mb-md-0" type="button" [disabled]="inSpinner"
                            (click)="salvar()" placement="top" ngbTooltip="Salvar" *ngIf="erroresposta.length === 0">
                            <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf="inSpinner"></i>
                            <span class="fa fa-save" *ngIf="!inSpinner"></span>
                            {{inSpinner ? 'Aguarde...' : 'Salvar'}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="session-wrapper" *ngIf="!emPesquisa">
    <div class="container container-full-width">
        <div class="row">
            <div class="col-sm-12 col-md-10 col-lg-8 mx-auto">
                <div class="thankyou-wrap">
                    <div class="card text-white border-rad shadow-md border-0">
                        <img class="card-img img-fluid" src="assets/img/thankyou.png" alt="Card image" width="600"
                            height="300" />
                        <div class="card-img-overlay border-rad">
                            <div class="text-center">
                                <h2 class="ty-card-title fw-200">Muito Obrigado!</h2>
                            </div>
                        </div>
                    </div>
                    <div class="card border-0 border-rad card-shadow p-4 text-center w-65 mx-auto card-hover">
                        <h2 class="font-2x mb-4">Sua opinião é muito importante para nós.</h2>
                        <div class="social-without-bg mb-4">
                            <ul class="list-inline mb-0">
                                <li class="list-inline-item"><a
                                        (click)="abrirLink('https://www.facebook.com/derminasseguridade')"
                                        style="cursor: pointer;"><i class="fa fa-facebook" target="_blank"></i></a></li>
                                <li class="list-inline-item"><a (click)="abrirLink('https://x.com/derminas1')"
                                        style="cursor: pointer;"><i class="fa fa-twitter" target="_blank"></i></a></li>
                            </ul>
                        </div>
                        <a routerLink="/home" class="btn btn-primary btn-block submit-btn btn-lg">Ir para o site</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>