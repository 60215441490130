import { environment } from 'environments/environment';
import { Component, OnInit } from '@angular/core';
import { AcessoRestritoSiteService } from 'app/services/acesso-restrito.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-sub-menu-relatorios',
  templateUrl: './sub-menu-relatorios.component.html',
  styleUrls: ['./sub-menu-relatorios.component.css']
})
export class SubMenuRelatoriosComponent implements OnInit {

  componente = '';
  prestadores = '';
  organograma = '';
  featuresContent = [
    // {
    //   'image_path': './assets/img/dash_resolucao.png',
    //   'heading': 'Resolução CNPC Nº 32 – RDI',
    //   'url': 'RESOLUCAO'
    // },
    {
      'image_path': './assets/img/dash_grafico.png',
      'heading': 'Relatórios',
      'url': 'RELATORIOS'
    },
    {
      'image_path': './assets/img/dash_atas.png',
      'heading': 'Extrato das atas das reuniões',
      'url': 'ATAS'
    },
    {
      'image_path': './assets/img/dash_prestadores.png',
      'heading': 'Prestadores de serviços',
      'url': 'PRESTADORES'
    },
    {
      'image_path': './assets/img/dash_resolucao.png',
      'heading': 'Composição órgãos estatutários',
      'url': 'ORGANOGRAMA'
    },
  ];

  constructor(public acessoRestritoSiteService: AcessoRestritoSiteService) {
    this.prestadores = environment.pdfPrestadores;
    this.organograma = environment.pdfOrganograma;
  }

  ngOnInit(): void {
    this.obter();
  }

  obter() {
    this.acessoRestritoSiteService.composicaoestatutaria()
      .pipe(finalize(() => {
      }))
      .subscribe(result => {
        this.organograma = result?.composicao?.caminhoArquivo;
        this.prestadores = result?.prestador?.caminhoArquivo;
      },
        err => {
        });
  }

  menu(item: string): void {
    console.log(item);
    if (item === 'PRESTADORES') {
      window.open(this.prestadores, '_blank');
    } else if (item === 'ORGANOGRAMA') {
      window.open(this.organograma, '_blank');
    } else {
      this.componente = item;
    }
  }


}
