<div class="contact-widget">
    <div class="container">
        <!-- <div class="row" style="margin-bottom: 15px;">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
                        <li class="breadcrumb-item"><a routerLink="/minha-area">Minha Área</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Resolução CNPC Nº 32 – RDI</li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <p>Seja bem vindo(a) <b>{{nomeUsuario}}</b></p>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 text-right">
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="voltar()">
                    <i class="fa fa-arrow-left" aria-hidden="true"></i> Voltar
                </button>
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="sair()"
                    style="cursor: pointer; margin-left: 10px;" ngbTooltip="Sair"><i class="fa fa-sign-out"></i>
                    Sair</button>
            </div>
        </div> -->
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="alert alert-info" role="alert">
                    <p>Estes relatórios atendem o disposto no art. 6° da Resolução CNPC n° 32/2019</p>
                    <p>Clique no ano para exibir os relatórios mensais</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2" *ngFor="let item of resolucao?.anos">
                <button type="button" class="btn"
                    [ngClass]="{'btn-primary': anoSelecionado === item, 'btn-outline-primary' : anoSelecionado !== item }"
                    (click)="selecionarAno(item)">{{item}}</button>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="list-group" style="margin-top: 25px;">
                    <a style="cursor: pointer;" *ngFor="let item of tipos" (click)="exibirArqivos(item)"
                        class="list-group-item list-group-item-action"
                        [ngStyle]="{'color': tipoSelecionado === item ? '#FFFFFF' : '#637282' }"
                        [class.active]="tipoSelecionado === item">
                        {{item}}
                        <i class="fa fa-hand-o-right pull-right text-right" aria-hidden="true" style="color: #FFFFFF;"
                            *ngIf="tipoSelecionado === item"></i>
                    </a>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="list-group" style="margin-top: 25px;">
                    <a style="cursor: pointer;" *ngFor="let item of arquivos" (click)="baixarResolucao(item)"
                        [ngStyle]="{'color': idArquivosSelecionado === item.id ? '#FFFFFF' : '#637282' }"
                        class="list-group-item list-group-item-action"
                        [class.active]="idArquivosSelecionado === item.id">
                        {{item.mes}}/{{item.ano}}
                        <i class="fa fa-cloud-download pull-right text-right" aria-hidden="true" style="color: #FFFFFF;"
                            *ngIf="idArquivosSelecionado === item.id"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>