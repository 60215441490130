import { AcessoRestritoSiteService } from 'app/services/acesso-restrito.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { EventosService } from 'app/services/eventos.service';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ValidadorService } from 'app/services/validador.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { MustMatch } from 'app/core/must-match.validator';

@Component({
  selector: 'app-nova-senha-acesso',
  templateUrl: './nova-senha-acesso.component.html',
  styleUrls: ['./nova-senha-acesso.component.css']
})
export class NovaSenhaAcessoComponent implements OnInit, OnDestroy {

  formGroup: FormGroup;
  inSpinner = false;
  erromessage = '';
  sucessomessage = false;

  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    private eventosService: EventosService,
    public validadorService: ValidadorService,
    public acessoRestritoSiteService: AcessoRestritoSiteService,
    public router: Router,
    public mensagensService: MensagensService
  ) {
    /* Page title */
    this.pageTitleService.setTitle('Nova Senha Acesso');
    /* Page subTitle */
    this.pageTitleService.setSubTitle('');
    this.formGroup = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      repetiremail: [null, [Validators.required, Validators.email]],
      cpf: [null, Validators.required],
      matricula: [null, Validators.required],
      nomeMae: [null, Validators.required]
    }, {
      validator: MustMatch('email', 'repetiremail')
    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.formGroup.controls; }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }

  login() {
    this.router.navigate(['acesso-restrito']);
  }

  entrar() {
    this.erromessage = '';
    this.sucessomessage = false;
    if (this.formGroup.valid) {
      this.inSpinner = true;
      this.acessoRestritoSiteService.novasenhaacesso(this.formGroup.value)
        .pipe(finalize(() => {
          this.inSpinner = false;
        }))
        .subscribe(result => {
          this.sucessomessage = true;
          this.formGroup.reset();
          this.formGroup.disable();
        },
          err => {
            this.sucessomessage = false;
            this.inSpinner = false;
            const erro = this.mensagensService.recuperarMensagemErro(err);
            this.erromessage = erro;
          });
    } else {
      this.sucessomessage = false;
      this.inSpinner = false;
      this.validadorService.validarTodosCampos(this.formGroup);
      this.erromessage = 'Existem campos obrigatórios sem o devido preenchimento';
    }
  }

}
