import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { AcessoRestritoSiteService } from 'app/services/acesso-restrito.service';
import { ValidadorService } from 'app/services/validador.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-organograma',
  templateUrl: './organograma.component.html',
  styleUrls: ['./organograma.component.css']
})
export class OrganogramaComponent implements OnInit {
  formGroup: FormGroup;
  erromessage = '';
  calculo: any;
  inSpinner = false;

  src = 'https://api.derminas.org.br/Anexos/d0adac93-b8f0-451d-8892-029d20ae9f66.pdf';

  constructor(
    private formBuilder: FormBuilder,
    public validadorService: ValidadorService,
    public acessoRestritoSiteService: AcessoRestritoSiteService,
    public servico: SiteService,
    public router: Router,
    public mensagensService: MensagensService
  ) {
    this.formGroup = this.formBuilder.group({
      salarioLiquido: [null, Validators.required],
      margem: [null, Validators.required],
      valorSolicitado: [null],
      idade: [1, Validators.required],
      prazo: [null, Validators.required]
    });
  }

  ngOnInit(): void {
  }


}
