<!-- Portfolio Section -->
<div class="portfolio-wrapper section-gap">
    <div class="container">
       <div class="blog-alternate mb-5" *ngFor="let item of links">
          <div class="row">
             <div class="col-sm-12 col-md-6">
                <div class="overlay-wrap mb-3">
                   <img [src] ="item?.image_path" class="img-fluid border-rad w-100" alt="gallery images" style="width: 500px!important; height: 500px!important" />
                </div>
             </div>
             <div class="col-sm-12 col-md-6">
                <div class="portfolio-content py-4">
                   <h4 class="mb-3"><a href="javascript:void(0);">{{item?.title}}</a></h4>
                   <p class="mb-3">{{item?.content}}</p>
                   <a href="{{item?.read_more_url}}" target="_blank" class="btn btn-outline-secondary btn-sm btn-chk-dark"> SAIBA MAIS </a>
                </div>
             </div>
          </div>
       </div>
    </div>
 </div>
 