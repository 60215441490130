<div id="topocontato" class="section-gap-mini"></div>
<div class="contact-widget section-gap">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3 text-center">
                <div class="contact-icon mb-4">
                    <i class="fa fa-phone text-warning fa-3x"></i>
                </div>
                <h4 class="text-warning mb-3"> Telefone </h4>
                <h5>
                    <a class="text-dark" href="tel:{{dados?.telefone}}">{{dados?.telefone1}}</a>
                </h5>
            </div>
            <!-- <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3 text-center">
                <div class="contact-icon mb-4">
                    <i class="fa fa-phone text-warning fa-3x"></i>
                </div>
                <h4 class="text-warning mb-3"> Fax </h4>
                <h5>
                    <a class="text-dark" href="#">{{dados?.fax}}</a>
                </h5>
            </div>-->
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3 text-center" style="cursor: pointer;"
                (click)="abrirWhatsApp(dados?.whatsApp, dados?.mensagemWhatsApp)">
                <div class="contact-icon mb-4">
                    <i class="fa fa-whatsapp texto-contatos fa-3x"></i>
                </div>
                <h4 class="texto-contatos mb-3"> WhatsApp </h4>
                <h5>
                    <a class="text-dark" style="cursor: pointer;">{{dados?.whatsApp}}</a>
                </h5>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3 text-center" style="cursor: pointer;">
                <div class="contact-icon mb-4">
                    <i class="fa fa-calendar text-info fa-3x"></i>
                </div>
                <h4 class="text-info mb-3"> Horário de Funcionamento </h4>
                <h5>
                    <span *ngIf="contact?.horario">
                        {{contact?.horario}}
                    </span>
                    <span *ngIf="contact?.horaInicio">
                        <br /> de {{contact?.horaInicio}} às {{contact?.horaFim}} - {{contact?.horaInicio2}} às
                        {{contact?.horaFim2}}
                    </span>
                </h5>
            </div>
        </div>
    </div>
</div>
<div class="contact-info">
    <div class="container">

        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6" style="margin-bottom: 40px;">
                <div class="resp-container">
                    <iframe class="resp-iframe"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3750.6320060016587!2d-43.9377442856335!3d-19.939906343765486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa699c550497a91%3A0xecfb05ce6bf782d4!2sDerminas!5e0!3m2!1spt-BR!2sbr!4v1615324007507!5m2!1spt-BR!2sbr"
                        width="500" height="500" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </div>
                <!-- <div class="google-maps">
                    <div class="mapouter">
                        <div class="gmap_canvas">
                            <agm-map [latitude]="lat" [longitude]="lng" class="border-rad">
                                <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                            </agm-map>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="border-0 border-rad p-4 card-shadow">
                    <div class="mb-4" style="text-align: center;">
                        <h2> Nos envie uma mensagem </h2>
                    </div>
                    <form [formGroup]="formGroup">
                        <div class="form-group mb-4" *ngIf="sucesso">
                            <div class="alert alert-success" role="alert">
                                Sua mensagem foi enviada com sucesso!
                            </div>
                        </div>
                        <div class="form-group mb-4" *ngIf="erromessage.length > 0">
                            <div class="alert alert-danger" role="alert">
                                {{erromessage}}
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-12 col-md-6 col-lg-6 col-xl-6 mb-4">
                                <select id="setor" formControlName="setor" class="form-control" style="color: #000000;"
                                    placeholder="Enviar Para"
                                    [ngClass]="validadorService.mostrarCSSErro('setor', formGroup)" required>
                                    <option *ngFor="let item of setores" [value]="item.value">
                                        {{item.displayName}}</option>
                                </select>
                                <div class="invalid-feedback">
                                    Campo obrigatório!
                                </div>
                            </div>
                            <div class="form-group col-12 col-md-6 col-lg-6 col-xl-6 mb-4">
                                <input id="telefone" type="text" class="form-control" placeholder="Telefone"
                                    formControlName="telefone" appDerminasPhoneMaskBr
                                    [ngClass]="validadorService.mostrarCSSErro('telefone', formGroup)" required>
                                <div class="invalid-feedback">
                                    Campo obrigatório!
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-4">
                            <input id="nome" type="text" class="form-control" placeholder="Nome Completo"
                                maxlength="200" formControlName="nome"
                                [ngClass]="validadorService.mostrarCSSErro('nome', formGroup)" required>
                            <div class="invalid-feedback">
                                Campo obrigatório!
                            </div>
                        </div>
                        <div class="form-group mb-4">
                            <input id="email-address" type="email" class="form-control" placeholder="E-mail"
                                maxlength="200" formControlName="email"
                                [ngClass]="validadorService.mostrarCSSErro('email', formGroup)" required>
                            <div class="invalid-feedback">
                                Campo obrigatório!
                            </div>
                        </div>
                        <div class="form-group mb-4">
                            <input id="assunto" type="text" class="form-control" placeholder="Assunto" maxlength="250"
                                formControlName="assunto"
                                [ngClass]="validadorService.mostrarCSSErro('assunto', formGroup)" required>
                            <div class="invalid-feedback">
                                Campo obrigatório!
                            </div>
                        </div>
                        <div class="form-group mb-4">
                            <textarea id="mensagem" class="form-control" rows="5" placeholder="Mensagem"
                                maxlength="4000" formControlName="mensagem"
                                [ngClass]="validadorService.mostrarCSSErro('mensagem', formGroup)" required></textarea>
                            <div class="invalid-feedback">
                                Campo obrigatório!
                            </div>
                        </div>
                        <div class="form-group mb-4">
                            <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (success)="handleSuccess($event)"
                                [useGlobalDomain]="pGlobal" [size]="pSize" [hl]="pLang" [theme]="pTheme" [type]="pType"
                                formControlName="recaptcha">
                            </ngx-recaptcha2>
                            <div class="invalid-feedback">
                                Você deve selecionar a verificação!
                            </div>
                        </div>
                        <div class="form-group">
                            <button type="button" class="btn btn-dark btn-chk-dark inverse" [disabled]="inSpinner"
                                (click)="sendMessage()">
                                <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf="inSpinner"></i>
                                <span class="fa fa-share" *ngIf="!inSpinner"></span>
                                {{inSpinner ? ' Enviando...' : ' Enviar Mensagem'}}
                            </button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- <div class="follow-us text-center section-gap">
    <div class="container">
        <div class="mb-5">
            <h5> Siga nossas redes sociais e fique ligado em todas as novidades!</h5>
        </div>
        <div class="social-icon-widget">
            <ul class="list-inline mb-0">
                <li class="list-inline-item mx-2"><a (click)="abrirLink('https://www.facebook.com/derminasseguridade')"
                        style="cursor: pointer;" class="square-60 rounded-circle bg-facebook"><i
                            class="fa fa-facebook fa-inverse"></i></a></li>
                <li class="list-inline-item mx-2"><a (click)="abrirLink('https://x.com/derminas1')"
                        style="cursor: pointer;" class="square-60 rounded-circle bg-twitter"><i
                            class="fa fa-twitter fa-inverse"></i></a></li>
            </ul>
        </div>
    </div>
</div> -->