import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ValidadorService } from 'app/services/validador.service';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pesquisa-satisfacao',
  templateUrl: './pesquisa-satisfacao.component.html',
  styleUrls: ['./pesquisa-satisfacao.component.css']
})
export class PesquisaSatisfacaoComponent implements OnInit {

  emPesquisa = true;
  formGroup: FormGroup;
  erromessage = '';
  erroresposta = '';
  inSpinner = true;
  token = '';
  pesquisa: any;

  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    public validadorService: ValidadorService,
    public servico: SiteService,
    private _activatedRoute: ActivatedRoute,
    public mensagensService: MensagensService
  ) {
    this.pageTitleService.setTitle('Pesquisa de satisfação Derminas');
    this.pageTitleService.setSubTitle('');
    this.formGroup = this.formBuilder.group({
      nomeParticipante: [null],
      observacoes: [null],
      comentarios: [null],
      cpf: [null],
      tipoParticipante: [null, Validators.required],
      resposta1: [null, Validators.required],
      resposta2: [null, Validators.required],
      resposta3: [null, Validators.required],
      resposta4: [null, Validators.required],
      resposta5: [null, Validators.required],
      resposta6: [null, Validators.required],
      resposta7: [null, Validators.required],
      idPesquisa: this._activatedRoute.snapshot.params.token
    });
    this.token = this._activatedRoute.snapshot.params.token;
  }

  ngOnInit(): void {
    this.inSpinner = true;
    this.servico.pesquisaSatisfacao(this.token)
      .pipe(finalize(() => {
        this.inSpinner = false;
      }))
      .subscribe(result => {
        this.pesquisa = result;
        if (result.mensagemErro !== '') {
          this.erroresposta = result.mensagemErro;
        } else if (result.tipoParticipante !== 'ALEATORIO') {
          this.formGroup.patchValue({
            tipoParticipante: result.tipoParticipante,
            nomeParticipante: result.nomeParticipante,
            cpf: result.cpf
          });
        }
      },
        err => {
          this.inSpinner = false;
        });
  }

  abrirLink(url: string) {
    window.open(url, '_blank');
  }

  selecionar(tipo) {
    this.formGroup.patchValue({
      tipoParticipante: tipo
    });
  }

  toTopPosition() {
    // Hack: Scrolls to top of Page after page view initialized
    let top = document.getElementById('toperror');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }


  salvar() {
    this.erromessage = '';
    if (this.formGroup.valid) {
      this.inSpinner = true;
      this.servico.responderpesquisa(this.formGroup.value)
        .pipe(finalize(() => {
          this.inSpinner = false;
        }))
        .subscribe(result => {
          this.emPesquisa = false;
          this.formGroup.reset();
          this.formGroup.markAsUntouched();
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'A Derminas agradeçe pela sua participação!',
            showConfirmButton: false,
            timer: 5000
          });
        },
          err => {
            this.inSpinner = false;
            this.erromessage = this.mensagensService.recuperarMensagemErro(err);
          });
    } else {
      if (this.formGroup.get('resposta1').status === 'INVALID') {
        this.formGroup.get('resposta1').setErrors({ selecionarOpcao: true });
      }
      if (this.formGroup.get('resposta2').status === 'INVALID') {
        this.formGroup.get('resposta2').setErrors({ selecionarOpcao: true });
      }
      if (this.formGroup.get('resposta3').status === 'INVALID') {
        this.formGroup.get('resposta3').setErrors({ selecionarOpcao: true });
      }
      if (this.formGroup.get('resposta4').status === 'INVALID') {
        this.formGroup.get('resposta4').setErrors({ selecionarOpcao: true });
      }
      if (this.formGroup.get('resposta5').status === 'INVALID') {
        this.formGroup.get('resposta5').setErrors({ selecionarOpcao: true });
      }
      if (this.formGroup.get('resposta6').status === 'INVALID') {
        this.formGroup.get('resposta6').setErrors({ selecionarOpcao: true });
      }
      if (this.formGroup.get('resposta7').status === 'INVALID') {
        this.formGroup.get('resposta7').setErrors({ selecionarOpcao: true });
      }
      if (this.formGroup.get('tipoParticipante').status === 'INVALID') {
        this.formGroup.get('tipoParticipante').setErrors({ selecionarTipo: true });
      }

      // if (this.formGroup.get('recaptcha').invalid) {
      //   const element: HTMLElement = document.querySelector('#rc-anchor-container') as HTMLElement;
      //   if (element !== null && element !== undefined) {
      //     element.classList.add('captha-erro');
      //   }
      // }
      this.toTopPosition();
      this.inSpinner = false;
      this.validadorService.validarTodosCampos(this.formGroup);
      this.erromessage = 'Selecione os campos solicitados';
    }
  }

}
