import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ValidadorService } from 'app/services/validador.service';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-canal-denuncia-registro',
  templateUrl: './canal-denuncia-registro.component.html',
  styleUrls: ['./canal-denuncia-registro.component.css']
})
export class CanalDenunciaRegistroComponent implements OnInit {

  @ViewChild('captchaElem') captchaElem: ElementRef;
  formGroup: FormGroup;
  erromessage = '';
  siteKey = environment.chaveGoogleCaptcha; // '6LeOZ4YUAAAAAJ8clQYNtOXNDHsd58KZHyJeILk6'; // '6LeDKSgUAAAAABtKiV4Uhxd2lvrXPVyhJKOY9iyJ';
  inSpinner = false;
  pLang = 'pt-BR';
  pTheme = 'light';
  pType = 'image';
  pGlobal = false;
  pSize = 'normal';
  protocoloResposta = '';
  sucesso = false;
  identificar = false;
  validarRecaptcha = false;

  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    public validadorService: ValidadorService,
    public servico: SiteService,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    private el: ElementRef,
    public mensagensService: MensagensService
  ) {
    /* Page title */
    this.pageTitleService.setTitle('Você em contato com a Derminas');

    /* Page subTitle */
    this.pageTitleService.setSubTitle('');
    this.formGroup = this.formBuilder.group({
      nome: [null],
      identificar: ['N', Validators.required],
      email: [null],
      assunto: ['D', Validators.required],
      telefone: [null],
      texto: [null, Validators.required],
      recaptcha: [null, Validators.required],
      anexo: [null]
    });
  }

  ngOnInit(): void {

  }

  addClass(className: string, element: any) {
    this.renderer.addClass(element, className);
    // or use the host element directly
    // this.renderer.addClass(this.elementRef.nativeElement, className);
  }

  removeClass(className: string, element: any) {
    this.renderer.removeClass(element, className);
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    const element: HTMLElement = document.querySelector('#pdfConteudo') as HTMLElement;
    element.click();
  }

  handleFileInput(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < fileList.length; i++) {
        const file: File = fileList[i];
        const element: HTMLElement = document.querySelector('#pdfConteudo + .input-group .file-upload-info') as HTMLElement;
        const fileName = event.target.files[0].name;
        element.setAttribute('value', fileName);
        const extension = file.name.substring(file.name.lastIndexOf('.') + 1);
        const fileTypes = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'txt', 'jpg', 'jpeg', 'png'];  // acceptable file types
        const isSuccess = fileTypes.indexOf(extension.toLowerCase()) > -1;  // is extension in acceptable types
        if (isSuccess) {
          const reader = new FileReader();
          reader.readAsDataURL(fileList[i]);
          reader.onload = () => {
            const anexo = {
              base64: reader.result,
              extensao: extension,
              nomeArquivo: fileName,
            };
            this.formGroup.patchValue({
              anexo: anexo
            });
          };
          reader.onerror = (error) => {
            console.log('Error: ', error);
          };
        } else {
          element.setAttribute('value', '');
          this.mensagensService.Info('Atenção', 'Somente arquivos PDF, DOC, Excel e imagens são aceitos.');
        }
      }
    }
  }

  alterarExibicao(event) {
    this.identificar = event === 'S';
    if (event === 'S') {
      this.formGroup.get('email').setValidators([Validators.required, Validators.email]);
      this.formGroup.get('email').markAsUntouched();
      this.formGroup.get('email').updateValueAndValidity();

      this.formGroup.get('telefone').setValidators([Validators.required]);
      this.formGroup.get('telefone').markAsUntouched();
      this.formGroup.get('telefone').updateValueAndValidity();

      this.formGroup.get('nome').setValidators([Validators.required]);
      this.formGroup.get('nome').markAsUntouched();
      this.formGroup.get('nome').updateValueAndValidity();
    } else {
      this.formGroup.get('email').setValidators(null);
      this.formGroup.get('email').updateValueAndValidity();

      this.formGroup.get('telefone').setValidators(null);
      this.formGroup.get('telefone').updateValueAndValidity();

      this.formGroup.get('nome').setValidators(null);
      this.formGroup.get('nome').updateValueAndValidity();
    }
    this.formGroup.updateValueAndValidity();
  }

  handleSuccess(event: any) {
    this.formGroup.patchValue({
      recaptcha: event
    });
  }

  irParaTopo() {
    let top = document.getElementById('topodenuncia');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  abrirLink(url: string) {
    window.open(url, '_blank');
  }

  sendMessage() {
    this.sucesso = false;
    this.erromessage = '';
    if (this.formGroup.valid) {
      this.spinner.show();
      const element: HTMLElement = document.querySelector('#rc-anchor-container') as HTMLElement;
      if (element !== null && element !== undefined) {
        element.classList.remove('captha-erro');
      }
      this.validarRecaptcha = false;
      this.inSpinner = true;
      this.servico.denuncia(this.formGroup.value)
        .pipe(finalize(() => {
          this.inSpinner = false;
          this.spinner.hide();
        }))
        .subscribe(result => {
          this.spinner.hide();
          this.sucesso = true;
          this.protocoloResposta = result;
          this.irParaTopo();
          this.formGroup.reset();
          this.formGroup.markAsUntouched();
        },
          err => {
            this.inSpinner = false;
            this.spinner.hide();
            const erro = this.mensagensService.recuperarMensagemErro(err);
          });
    } else {
      if (this.formGroup.get('recaptcha').invalid) {
        this.validarRecaptcha = true;
        const element: HTMLElement = document.querySelector('#rc-anchor-container') as HTMLElement;
        if (element !== null && element !== undefined) {
          element.classList.add('captha-erro');
        }
      }
      this.inSpinner = false;
      this.spinner.hide();
      this.validadorService.validarTodosCampos(this.formGroup);
      this.erromessage = 'Existem campos obrigatórios sem o devido preenchimento';
      this.irParaTopo();
    }
  }

}
