export const environment = {
  production: true,
  apiUrl: 'https://api.derminas.org.br',
  // apiUrl: 'http://192.168.0.197:5080/api',
  apiKey: 'X6PuhsytsyhgmB-sPY3hBr5s4a57sa5868es8-7b7$uOSOwJMXK#84h3q5tGRqw$',
  urlAnexo: 'https://api.derminas.org.br/Anexos',
  // urlAnexo: 'http://192.168.0.197:5080/api',
  RecaptchaClientKey: '6LeDKSgUAAAAABtKiV4Uhxd2lvrXPVyhJKOY9iyJ',
  RecaptchaSecret: '6LeDKSgUAAAAADUm04PnSjWxsUbEq5nVvcFqx3Zi',
  pdfPrestadores: 'https://api.derminas.org.br/Anexos/prestadores_servicos.pdf',
  pdfOrganograma: 'https://api.derminas.org.br/Anexos/Composicao.pdf',
  chaveGoogleCaptcha: '6LeDKSgUAAAAABtKiV4Uhxd2lvrXPVyhJKOY9iyJ'
};
