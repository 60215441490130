import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-avisos-site',
  templateUrl: './avisos-site.component.html',
  styleUrls: ['./avisos-site.component.css']
})
export class AvisosSiteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  close() {

  }

}
