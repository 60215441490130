<div class="contact-widget">
    <div class="container">
        <!-- <div class="row" style="margin-bottom: 15px;">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
                        <li class="breadcrumb-item"><a routerLink="/minha-area">Minha Área</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Empréstimos</li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <p>Seja bem vindo(a) <b>{{nomeUsuario}}</b></p>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 text-right">
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="voltar()">
                    <i class="fa fa-arrow-left" aria-hidden="true"></i> Voltar
                </button>
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="sair()"
                    style="cursor: pointer; margin-left: 10px;" ngbTooltip="Sair"><i class="fa fa-sign-out"></i>
                    Sair</button>
            </div>
        </div> -->
        <div class="row" style="margin-bottom: 25px;">
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        CPF: <b>{{dados?.cpf}}</b>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        Situação: <span class="badge badge-success">{{dados?.situacaoContrato}}</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        Data Crédito: <b>{{dados?.dataCredito}}</b>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <ul class="list-group">
                    <li class="list-group-item" style="text-align: center;font-weight: bold;">Posição Atual</li>
                    <li class="list-group-item">Saldo devedor: <b>{{dados?.saldoDevedor | currency:'BRL'}}</b></li>
                    <li class="list-group-item">Saldo devedor (fim do mês): <b>{{dados?.saldoFimMes |
                            currency:'BRL'}}</b></li>
                    <li class="list-group-item">Valor prestação atual: <b>{{dados?.valorPrestacao | currency:'BRL'}}</b>
                    </li>
                    <li class="list-group-item">Nº prestações Restantes: <b>{{dados?.numeroPrestacaoRestante}}</b></li>
                    <li class="list-group-item">Nº prestações em atraso: <b>{{dados?.numeroPrestacaoAtraso}}</b></li>
                    <li class="list-group-item">Data carência para reforma: <b>{{dados?.dataCarenciaReforma}}</b></li>
                    <li class="list-group-item">Data previsão último vencimento: <b>{{dados?.dataUltimoVencimento}}</b>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>