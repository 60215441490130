import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutside } from './click-outside.directive';
import { DerminasPhoneMaskBrDirective } from './telefone-directive';
import { AutocompleteOffDirective } from './autocomplete-off.directive';
import { BlockCopyPasteDirective } from './block-copy-paste.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ClickOutside,
    AutocompleteOffDirective,
    DerminasPhoneMaskBrDirective,
    BlockCopyPasteDirective
  ],
  exports: [
    ClickOutside,
    AutocompleteOffDirective,
    DerminasPhoneMaskBrDirective,
    BlockCopyPasteDirective
  ]
})
export class DirectivesModule { }
