import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-canal-denuncia',
  templateUrl: './canal-denuncia.component.html',
  styleUrls: ['./canal-denuncia.component.css']
})
export class CanalDenunciaComponent implements OnInit {
  formGroup: FormGroup;

  constructor(
    public router: Router,
    private formBuilder: FormBuilder
  ) {
    this.formGroup = this.formBuilder.group({
      aceite: [false, [Validators.required]]
    });
  }

  ngOnInit(): void {
  }

  acompanhar() {
    this.router.navigate(['canal-denuncia-protocolo']);
  }
  nova() {
    this.router.navigate(['canal-denuncia-registro']);
  }

}
