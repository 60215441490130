<div class="section-gap-medium"></div>
<div class="contact-widget section-gap-mini">
    <div class="container">
        <form [formGroup]="formGroup" style="width: 100%;">
            <div class="row no-print" style="margin-bottom: 15px;">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Organograma</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div class="container-fluid" style="margin-top:20px">
                <!-- <div class="row">

                    <div class="organograma">
                        <div class="box level1">Prefeito</div>
                        <div class="box level1">Prefeito</div>
                        <div class="linhaPrefeito"></div>
                        <div class="linhaAssistente"></div>
                        <div class="linhaAssistente2"></div>
                        <div>
                            <div class="box assistente gabinete">Gabinete do<br>Prefeito</div>
                            <div class="box assistente juridico">Asessoria<br>Jurídica</div>
                            <div class="box assistente controle">Sistema de<br>Controle Interno</div>
                        </div>
                        <div class="linhaGrande"></div>
                        <div>
                            <div class="dpto admin">
                                <div class="linhaDpto"></div>
                                <div class="linhaDptoHorizontal linhaAdmin"></div>
                                <div class="box level2">Administração e Planejamento</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Expedição de Documentos</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Material e Patrimônio</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Serviços<br>Gerais</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3 alinhartexto">Imprensa</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3 alinhartexto">Informática</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Recursos<br>Humanos</div>
                            </div>
                            <div class="dpto fin">
                                <div class="linhaDpto"></div>
                                <div class="linhaDptoHorizontal linhaFin"></div>
                                <div class="box level2 alinhartexto">Finanças</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3 alinhartexto">Contabilidade</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3 alinhartexto">Tesouraria</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Cadastro e Tributação</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Fiscalização Externa de Tributos</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3 alinhartexto">Compras</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Licitações e Contratos</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Asessoramento à Contabilidade</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Controle de Contratos e Convênios</div>
                            </div>
                            <div class="dpto ind">
                                <div class="linhaDpto"></div>
                                <div class="linhaDptoHorizontal linhaInd"></div>
                                <div class="box level2">Indústria, Comércio e Turismo</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Assistência à Indústria</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Assistência ao Comércio</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Assistência ao Turismo</div>
                            </div>
                            <div class="dpto rod">
                                <div class="linhaDpto"></div>
                                <div class="linhaDptoHorizontal linhaRod"></div>
                                <div class="box level2">Obras e Serviços Rodoviários</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3 alinhartexto">Obras</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Serviços<br>Rodoviários</div>
                            </div>
                            <div class="dpto urb">
                                <div class="linhaDpto"></div>
                                <div class="linhaDptoHorizontal linhaUrb"></div>
                                <div class="box level2">Obras e Serviços Urbanos</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3 alinhartexto">Engenharia</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Obras<br>Urbanas</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Serviços<br>Urbanos</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Logradouros Públicos</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Utilidade<br>Pública</div>
                            </div>
                            <div class="dpto social">
                                <div class="linhaDpto"></div>
                                <div class="linhaDptoHorizontal linhaSocial"></div>
                                <div class="box level2">Ação Social</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Assistência<br>Social</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Assessoramento a Programas Sociais</div>
                            </div>
                            <div class="dpto saude">
                                <div class="linhaDpto"></div>
                                <div class="linhaDptoHorizontal linhaSaude"></div>
                                <div class="box level2 alinhartexto">Saúde</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3 alinhartexto">Saúde</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Vigilância<br>Sanitária</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Vigilância Epidemiológica</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Asessoramento a Programas de Saúde</div>
                            </div>
                            <div class="dpto educacao">
                                <div class="linhaDpto"></div>
                                <div class="linhaDptoHorizontal linhaEducacao"></div>
                                <div class="box level2">Educação, Cultura e Esportes</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3 alinhartexto">Ensino</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3 alinhartexto">Cultura</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3 alinhartexto">Esporte</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Educação<br>Física</div>
                            </div>
                            <div class="dpto agricultura">
                                <div class="linhaDpto"></div>
                                <div class="linhaDptoHorizontal linhaAgricultura"></div>
                                <div class="box level2">Agricultura, Pecuária e Meio Ambiente</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Fomento<br>Agrícola</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Fomento<br>Pecuário</div>
                                <div class="linhaDiv"></div>
                                <div class="box level3">Proteção ao Meio Ambiente</div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- <pdf-viewer [src]="src" [rotation]="0" [original-size]="false" [show-all]="true" [fit-to-page]="false"
                    [zoom]="1" [zoom-scale]="'page-width'" [stick-to-page]="false" [render-text]="true"
                    [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
                    style="width: 100%; height: 100vh;"></pdf-viewer> -->


                <div class="row">
                    <div class="col-md-12">
                        <div class="tree">


                            <ul>
                                <li>

                                    <a href="#">

                                        <div class="container-fluid">
                                            <div class="row">
                                                Conselho Deliberativo
                                            </div>
                                            <div class="row" style="margin-top: 35px;">

                                            </div>
                                            <div class="row">
                                                João da Silva
                                            </div>
                                        </div>

                                    </a>

                                    <ul>
                                        <li>
                                            <a href="#">

                                                <div class="container-fluid">
                                                    <div class="row">
                                                        Superindentencia
                                                    </div>
                                                    <div class="row" style="margin-top: 35px;">

                                                    </div>
                                                    <div class="row">
                                                        João da Silva
                                                    </div>
                                                </div>

                                            </a>
                                            <ul>

                                                <li>
                                                    <a href="#">

                                                        <div class="container-fluid">
                                                            <div class="row">
                                                                Diretoria de Seguridade
                                                            </div>
                                                            <div class="row" style="margin-top: 35px;">
                                                                <i class="fa fa-exclamation-circle fa-2x"></i>
                                                            </div>
                                                            <div class="row">
                                                                3 Failed Tests
                                                            </div>
                                                        </div>

                                                    </a>
                                                    <ul>
                                                        <li>
                                                            <a href="#">

                                                                <div class="container-fluid">
                                                                    <div class="row">
                                                                        Gestão de Relacionamento
                                                                    </div>
                                                                    <div class="row" style="margin-top: 35px;">
                                                                        <i class="fa fa-exclamation-circle fa-2x"></i>
                                                                    </div>
                                                                    <div class="row">
                                                                        3 Failed Tests
                                                                    </div>
                                                                </div>

                                                            </a>


                                                            <ul>
                                                                <li>
                                                                    <a href="#">

                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                Beneficios
                                                                            </div>
                                                                            <div class="row" style="margin-top: 35px;">
                                                                                <i
                                                                                    class="fa fa-exclamation-circle fa-2x"></i>
                                                                            </div>
                                                                            <div class="row">
                                                                                3 Failed Tests
                                                                            </div>
                                                                        </div>

                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">

                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                Emprestimos
                                                                            </div>
                                                                            <div class="row" style="margin-top: 35px;">
                                                                                <i
                                                                                    class="fa fa-exclamation-circle fa-2x"></i>
                                                                            </div>
                                                                            <div class="row">
                                                                                3 Failed Tests
                                                                            </div>
                                                                        </div>

                                                                    </a>
                                                                </li>
                                                            </ul>



                                                        </li>
                                                    </ul>
                                                </li>




                                                <li>
                                                    <a href="#">

                                                        <div class="container-fluid">
                                                            <div class="row">
                                                                Diretoria Financeira
                                                            </div>
                                                            <div class="row" style="margin-top: 35px;">
                                                                <i class="fa fa-exclamation-circle fa-inv fa-2x"></i>
                                                            </div>
                                                            <div class="row">
                                                                7 Failed Tests
                                                            </div>
                                                        </div>

                                                    </a>

                                                    <ul>
                                                        <li>
                                                            <a href="#">

                                                                <div class="container-fluid">
                                                                    <div class="row">
                                                                        Gestão de Investimentos
                                                                    </div>
                                                                    <div class="row" style="margin-top: 35px;">
                                                                        <i class="fa fa-exclamation-circle fa-2x"></i>
                                                                    </div>
                                                                    <div class="row">
                                                                        3 Failed Tests
                                                                    </div>
                                                                </div>

                                                            </a>

                                                        </li>

                                                        <li>
                                                            <a href="#">

                                                                <div class="container-fluid">
                                                                    <div class="row">
                                                                        Gestão Administrativa
                                                                    </div>
                                                                    <div class="row" style="margin-top: 35px;">
                                                                        <i class="fa fa-exclamation-circle fa-2x"></i>
                                                                    </div>
                                                                    <div class="row">
                                                                        3 Failed Tests
                                                                    </div>
                                                                </div>

                                                            </a>


                                                            <ul>
                                                                <li>
                                                                    <a href="#">

                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                Administrativo
                                                                            </div>
                                                                            <div class="row" style="margin-top: 35px;">
                                                                                <i
                                                                                    class="fa fa-exclamation-circle fa-2x"></i>
                                                                            </div>
                                                                            <div class="row">
                                                                                3 Failed Tests
                                                                            </div>
                                                                        </div>

                                                                    </a>
                                                                </li>
                                                            </ul>

                                                        </li>


                                                        <li>
                                                            <a href="#">

                                                                <div class="container-fluid">
                                                                    <div class="row">
                                                                        Gestão Contabil
                                                                    </div>
                                                                    <div class="row" style="margin-top: 35px;">
                                                                        <i class="fa fa-exclamation-circle fa-2x"></i>
                                                                    </div>
                                                                    <div class="row">
                                                                        3 Failed Tests
                                                                    </div>
                                                                </div>

                                                            </a>

                                                            <ul>
                                                                <li>
                                                                    <a href="#">

                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                Tesouraria
                                                                            </div>
                                                                            <div class="row" style="margin-top: 35px;">
                                                                                <i
                                                                                    class="fa fa-exclamation-circle fa-2x"></i>
                                                                            </div>
                                                                            <div class="row">
                                                                                3 Failed Tests
                                                                            </div>
                                                                        </div>

                                                                    </a>
                                                                </li>
                                                            </ul>

                                                        </li>


                                                    </ul>
                                                </li>

                                            </ul>
                                        </li>


                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </div>
</div>