import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';

@Component({
  selector: 'app-institucional-derminas',
  templateUrl: './institucional-derminas.component.html',
  styleUrls: ['./institucional-derminas.component.css']
})
export class InstitucionalDerminasComponent implements OnInit {

  institucionaisUmaColuna: any[] = [];
  institucionaisDuasColunas: any[] = [];
  institucionaisTresColunas: any[] = [];

  constructor(
    public servico: SiteService,
    public router: Router,
    public mensagensService: MensagensService
  ) {
    this.servico.listarInstitucional().
      subscribe(response => {
        this.institucionaisUmaColuna = response.itensUmaColuna;
        this.institucionaisDuasColunas = response.itensDuasColunas;
        this.institucionaisTresColunas = response.itensTresColunas;
      },
        err => console.log(err)
      );
  }

  ngOnInit(): void {
  }

  redirecionar(item: any) {
    if (item.redirecionamento.indexOf('http') > -1) {
      window.open(item.redirecionamento, '_blank');
    } else {
      this.router.navigate([item.redirecionamento]);
    }
  }

}
