<div class="modal-header" style="background:#f5f5f5">
    <h6 class="modal-title pull-left" style="color:#000000">
        <i class="fa fa-files-o"></i> Dependente
    </h6>
    <button type="button" (click)="close()" class="close pull-right" data-dismiss="modal" aria-label="Close"
        style="color:#000000">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">

    <div class="row">
        <div class="col-xl-12">
            <form [formGroup]="formGroupDep" style="width: 100%;">
                <div class="row" *ngIf="erromessageDep.length > 0">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="alert alert-danger" role="alert">
                            {{erromessageDep}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <input type="hidden" formControlName="cod_Dependente">
                    <input type="hidden" formControlName="cod_Participante">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="form-group">
                            <label class="contro-label required">Nome</label>
                            <input name="nome" id="nome" formControlName="nome_Dependente" class="form-control"
                                maxlength="500" placeholder="Nome"
                                [ngClass]="validadorService.mostrarCSSErro('nome_Dependente', formGroupDep)" />
                            <div class="invalid-feedback">
                                Campo obrigatório!
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div class="form-group">
                            <label class="contro-label">Sexo</label>
                            <select id="sexodep" formControlName="sexo_Dependente" class="form-control"
                                style="color: #000000;"
                                [ngClass]="validadorService.mostrarCSSErro('sexo_Dependente', formGroupDep)">
                                <option *ngFor="let item of sexosdependentes" [value]="item.value">
                                    {{item.displayName}}</option>
                            </select>
                            <div class="invalid-feedback">
                                Campo obrigatório!
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div class="form-group">
                            <label class="contro-label">Data de Nascimento</label>
                            <p-calendar formControlName="data_Nascimento" dateFormat="dd/mm/yy"
                                [ngClass]="validadorService.mostrarCSSErro('data_Nascimento', formGroupDep)"
                                [showIcon]="true" inputId="icon"></p-calendar>

                            <!-- <div class="input-group" (click)="d3.toggle()">
                                <input class="form-control" placeholder="dd/mm/aaaa" name="dp3"
                                    formControlName="data_Nascimento" ngbDatepicker #d3="ngbDatepicker"
                                    [ngClass]="validadorService.mostrarCSSErro('data_Nascimento', formGroupDep)">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-light btn-icon calendar" type="button">
                                        <i class="fa fa-calendar text-secondary"></i>
                                    </button>
                                </div>
                                <div class="invalid-feedback">
                                    Campo obrigatório!
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div class="form-group">
                            <label class="contro-label">Estado Civil</label>
                            <select id="estado_Civil" formControlName="estado_Civil" class="form-control"
                                style="color: #000000;"
                                [ngClass]="validadorService.mostrarCSSErro('estado_Civil', formGroupDep)">
                                <option *ngFor="let item of estadosCivilDependentes" [value]="item.value">
                                    {{item.displayName}}</option>
                            </select>
                            <div class="invalid-feedback">
                                Campo obrigatório!
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div class="form-group">
                            <label class="contro-label">Parentesco</label>
                            <select id="parentesco" formControlName="parentesco" class="form-control"
                                style="color: #000000;"
                                [ngClass]="validadorService.mostrarCSSErro('parentesco', formGroupDep)">
                                <option *ngFor="let item of parentescos" [value]="item.value">
                                    {{item.displayName}}</option>
                            </select>
                            <div class="invalid-feedback">
                                Campo obrigatório!
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="form-group">
                            <label class="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
                                <input type="checkbox" class="custom-control-input" formControlName="invalidoValue">
                                <span class="custom-control-indicator"></span>
                                <span class="custom-control-description">Inválido?</span>
                            </label>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div class="text-left" style="float:left">
        <button type="button" class="btn btn-secondary" (click)="close()">
            <span class="feather icon-x-circle"></span> Fechar
        </button>
    </div>
    <div class="text-right" style="float:right">
        <button class="btn btn-primary mr-2 mb-2 mb-md-0" type="button" [disabled]="inSpinnerDep"
            (click)="salvarDependente()" placement="top" ngbTooltip="Enviar Anexo">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="inSpinnerDep"></span>
            <span class="fa fa-save" *ngIf="!inSpinnerDep"></span>
            {{inSpinnerDep ? 'Aguarde...' : 'Salvar'}}
        </button>
    </div>
</div>