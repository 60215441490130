<div class="product-wrapper section-gap">
    <div class="container">
        <div class="section-gap-mini"></div>
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{area}}</li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="related-post mb-3">
            <!-- <h2 class="mb-5 text-capitalize">Documentos</h2> -->
            <div class="row">
                <div class="col-sm-6 col-md-3 col-lg-3" *ngFor="let doc of documentos">
                    <div class="card border-0 mb-4">
                        <div class="overlay-wrap mb-4 card-shadow border-rad">
                            <img class="img-fluid border-rad w-100" src="assets/derminas//documentos.jpg"
                                alt="Documento" width="280" height="260" />
                            <a style="cursor: pointer;" (click)="baixar(doc)"
                                class="card-img-overlay primary-tp-layer pos-center text-center">
                                <span class="center-holder">
                                    <a class="ih-fade-down square-40 rounded-circle bg-white shadow-md">
                                        <i class="fa fa-cloud-download align-middle"></i>
                                    </a>
                                    <br />
                                    <span style="text-transform: uppercase;font-weight: bold; color: #FFFFFF;"> Clique
                                        para
                                        baixar</span>
                                </span>
                            </a>
                            <!-- <div class="cart-icon">
                                <ul class="list-unstyled">
                                    <li>
                                        <a style="cursor: pointer;" (click)="baixar(doc)"
                                            class="square-40 bg-white rounded-circle text-dark card-shadow">
                                            <i class="fa fa-cloud-download"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                        <div class="card-body p-0" style="text-align: center;">
                            <h6 class="text-capitalize mb-3">{{doc.titulo}}</h6>
                            <p *ngIf="doc.subTitulo !== null">{{doc.subTitulo}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>