
import { Injectable } from '@angular/core';
import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';
import { DataService } from './data.services';

@Injectable()
export class AcessoRestritoSiteService extends DataService {

    context = 'AcessoRestritoSite';

    eleicoes(): Observable<any[]> {
        return this.get<any[]>('portal-eleicao');
    }

    menusRelatorioSite(): Observable<any[]> {
        return this.get<any[]>('menusRelatorioSite');
    }
    listarContraCheques(cpf: string): Observable<any[]> {
        return this.get<any[]>(`listarContraCheques?cpf=${cpf}`);
    }
    atas(id: number): Observable<any> {
        return this.get<any>('atas', id);
    }
    conselhos(): Observable<any> {
        return this.get<any>('conselhos');
    }
    obterRelatoriosSite(id: number): Observable<any> {
        return this.get<any>('obterRelatoriosSite', id);
    }
    contacheque(id: number): Observable<any> {
        return this.get<any>('contacheque', id);
    }
    resolucoes(): Observable<any> {
        return this.get<any>('resolucoes');
    }
    informe(id: number): Observable<any> {
        return this.get<any>('informe', id);
    }
    listarInformesAnteriores(): Observable<any[]> {
        return this.get<any[]>('listarInformesAnteriores');
    }
    disponibilizarInformeAtual(cpf: string): Observable<any> {
        return this.get<any>(`disponibilizarInformeAtual?cpf=${cpf}`);
    }
    emprestimo(cpf: string): Observable<any> {
        return this.get<any>(`emprestimo?cpf=${cpf}`);
    }
    printRecadastramento(model: any): Observable<any> {
        return this.post<any>('printRecadastramento', model);
    }
    salvarDependente(model: any): Observable<any[]> {
        return this.post<any[]>('salvarDependente', model);
    }
    atualizarParticipante(model: any): Observable<any[]> {
        return this.post<any[]>('atualizarParticipante', model);
    }
    printParticipante(model: any): Observable<any[]> {
        return this.post<any[]>('printParticipante', model);
    }
    excluirDependente(codDependente: number, codParticipante): Observable<any> {
        return this.get<any>(`excluirDependente?codDependente=${codDependente}&codParticipante=${codParticipante}`);
    }
    primeiroacesso(model: any): Observable<any> {
        return this.post<any>('primeiroacesso', model);
    }
    alterarminhasenha(model: any): Observable<any> {
        return this.post<any>('alterarminhasenha', model);
    }
    criaracesso(model: any): Observable<any> {
        return this.post<any>('criaracesso', model);
    }
    novasenhaacesso(model: any): Observable<any> {
        return this.post<any>('novasenhaacesso', model);
    }
    recuperar(model: any): Observable<any> {
        return this.post<any>('recuperar', model);
    }
    composicaoestatutaria(): Observable<any> {
        return this.get<any>('composicao-estatutaria');
    }
}
