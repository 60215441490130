import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { Subscription } from 'rxjs';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-documentos-site',
  templateUrl: './documentos-site.component.html',
  styleUrls: ['./documentos-site.component.css']
})
export class DocumentosSiteComponent implements OnInit {
  navSubscription: Subscription = null;
  documentos: any[] = [];
  area = '';

  constructor(
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    public servico: SiteService,
    public mensagensService: MensagensService
  ) {
    this.navSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }
      if (event instanceof NavigationEnd) {
        this.obterDocumentosSite(this._activatedRoute.snapshot.params.id);
        // Hide loading indicator
      }
      if (event instanceof NavigationError) {
        // Hide loading indicator
        // Present error to user
        console.log(event.error);
      }
    });

  }

  ngOnInit(): void {

  }

  baixar(item: any) {
    window.open(item.arquivo, '_blank');
  }

  obterDocumentosSite(idConteudo) {
    this.servico.obterDocumentosSite(idConteudo).
      subscribe(response => {
        this.documentos = response.arquivos;
        this.area = response.area;
        if (response.notFound) {
          this.router.navigate(['not-found']);
        }
      },
        err => console.log(err),
        () => this.documentos
      );
  }

}
