<div id="topodenuncia" class="section-gap-mini"></div>
<div class="session-wrapper">
    <div class="container container-full-width">
        <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
            <p style="font-size: 18px; color: white">Aguarde...</p>
        </ngx-spinner>
        <div class="row">
            <div class="col-sm-12 col-md-10 col-lg-8 mx-auto" *ngIf="sucesso">
                <div class="container container-full-width">
                    <div class="row">
                        <div class="col-sm-12 col-md-10 col-lg-8 mx-auto">
                            <div class="thankyou-wrap">
                                <div class="card text-white border-rad shadow-md border-0">
                                    <img class="card-img img-fluid" src="assets/img/thankyou.png" alt="Card image"
                                        width="600" height="300" />
                                    <div class="card-img-overlay border-rad">
                                        <div class="text-center">
                                            <h2 class="ty-card-title fw-200">Muito Obrigado!</h2>
                                        </div>
                                    </div>
                                </div>
                                <div style="margin-top: -16px!important;"
                                    class="card border-0 border-rad card-shadow p-4 text-center w-80 mx-auto card-hover">
                                    <p class="mb-4">Sua opinião é muito importante para nós, vamos prosseguir
                                        com seu protocolo e em breve você terá a resposta em nosso site.
                                        Guarde o número do seu protocolo <b>{{protocoloResposta}}</b> para consultar o
                                        andamento no nosso
                                        site. </p>
                                    <div class="social-without-bg mb-4">
                                        <ul class="list-inline mb-0">
                                            <li class="list-inline-item"><a
                                                    (click)="abrirLink('https://www.facebook.com/derminasseguridade')"
                                                    style="cursor: pointer;"><i class="fa fa-facebook"
                                                        target="_blank"></i></a></li>
                                            <li class="list-inline-item"><a
                                                    (click)="abrirLink('https://x.com/derminas1')"
                                                    style="cursor: pointer;"><i class="fa fa-twitter"
                                                        target="_blank"></i></a></li>
                                        </ul>
                                    </div>
                                    <a routerLink="/home" class="btn btn-primary btn-block submit-btn btn-lg">Ir para o
                                        site</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-10 col-lg-8 mx-auto" *ngIf="!sucesso">

                <form [formGroup]="formGroup">
                    <div class="form-group mb-4" *ngIf="erromessage.length > 0">
                        <div class="alert alert-danger" role="alert">
                            {{erromessage}}
                        </div>
                    </div>

                    <div class="form-group mb-4">
                        <label class="contro-label required">Você gostaria de se identificar?</label>
                        <br />
                        <div class="form-check form-check-inline">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="identificar"
                                    formControlName="identificar" id="nao"
                                    (change)="alterarExibicao($event.target.value)" value="N">
                                <i class="input-frame"></i>
                                Não
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="identificar"
                                    formControlName="identificar" id="sim"
                                    (change)="alterarExibicao($event.target.value)" value="S">
                                <i class="input-frame"></i>
                                Sim
                            </label>
                        </div>
                    </div>
                    <div class="form-group mb-4" *ngIf="identificar">
                        <label class="contro-label required">Nome Completo</label>
                        <input id="nome" type="text" class="form-control" placeholder="Nome Completo" maxlength="200"
                            formControlName="nome" [ngClass]="validadorService.mostrarCSSErro('nome', formGroup)"
                            required>
                        <div class="invalid-feedback">
                            Campo obrigatório!
                        </div>
                    </div>
                    <div class="form-group mb-4" *ngIf="identificar">
                        <label class="contro-label required">Email</label>
                        <input id="email-address" type="email" class="form-control" placeholder="E-mail" maxlength="200"
                            formControlName="email" [ngClass]="validadorService.mostrarCSSErro('email', formGroup)"
                            required>
                        <div class="invalid-feedback">
                            Campo obrigatório!
                        </div>
                    </div>
                    <div class="form-group mb-4" *ngIf="identificar">
                        <label class="contro-label required">Telefone</label>
                        <input id="telefone" type="text" class="form-control" placeholder="Telefone"
                            formControlName="telefone" appDerminasPhoneMaskBr
                            [ngClass]="validadorService.mostrarCSSErro('telefone', formGroup)" required>
                        <div class="invalid-feedback">
                            Campo obrigatório!
                        </div>
                    </div>
                    <!-- <div class="form-group mb-4">
                        <input id="assunto" type="text" class="form-control" placeholder="Denúncia" maxlength="250"
                            formControlName="assunto" [ngClass]="validadorService.mostrarCSSErro('assunto', formGroup)"
                            required>
                        <div class="invalid-feedback">
                            Campo obrigatório!
                        </div>
                    </div> -->
                    <div class="form-group mb-4">
                        <label class="contro-label required">Denúncia</label>
                        <textarea id="texto" class="form-control" rows="5" placeholder="Denúncia" maxlength="4000"
                            formControlName="texto" [ngClass]="validadorService.mostrarCSSErro('texto', formGroup)"
                            required></textarea>
                        <div class="invalid-feedback">
                            Campo obrigatório!
                        </div>
                    </div>

                    <div class="form-group mb-4">
                        <input (change)="handleFileInput($event)" type="file" name="anexo" class="file-upload-default"
                            id="pdfConteudo">
                        <div class="input-group col-xs-12" (click)="openFileBrowser($event)">
                            <input type="text" class="form-control file-upload-info" multiple="false" disabled=""
                                placeholder="Upload Anexo">
                            <span class="input-group-append">
                                <button class="file-upload-browse btn btn-primary" type="button">Adicionar
                                    Arquivo</button>
                            </span>
                        </div>
                    </div>

                    <div class="form-group mb-4">
                        <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (success)="handleSuccess($event)"
                            [useGlobalDomain]="pGlobal" [size]="pSize" [hl]="pLang" [theme]="pTheme" [type]="pType"
                            formControlName="recaptcha">
                        </ngx-recaptcha2>
                        <div *ngIf="validarRecaptcha" style="color: red;font-size: 12px;">
                            Você deve selecionar a verificação!
                        </div>
                    </div>

                    <div class="form-group" style="text-align: right;">
                        <button type="button" class="btn btn-dark btn-chk-dark inverse" (click)="sendMessage()">
                            <span class="fa fa-share"></span> 
                            Registrar Ocorrência
                        </button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>