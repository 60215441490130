<div class="contact-widget">
    <div class="container">
        <!-- <div class="row" style="margin-bottom: 15px;">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
                        <li class="breadcrumb-item"><a routerLink="/minha-area">Minha Área</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Informes</li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <p>Seja bem vindo(a) <b>{{nomeUsuario}}</b></p>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 text-right">
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="voltar()">
                    <i class="fa fa-arrow-left" aria-hidden="true"></i> Voltar
                </button>
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="sair()"
                    style="cursor: pointer; margin-left: 10px;" ngbTooltip="Sair"><i class="fa fa-sign-out"></i>
                    Sair</button>
            </div>
        </div> -->
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="alert alert-primary" role="alert"
                    style="text-align: center; font-size: 16px; font-weight: bold;">
                    Ano Calendário {{ano}}
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!disponibilizado && retornou">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <p style="text-align: center; font-size: 16px; font-weight: bold; color: red;">* Informe não disponível,
                    entre em contato com a Derminas.</p>
            </div>
        </div>
        <div class="row" *ngIf="disponibilizado">
            <div class="col-sm-12 col-md-12 col-lg-12" style="text-align: center;">
                <button type="button" class="btn btn-outline-primary" (click)="baixar()" [disabled]="inSpinner">
                    <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf="inSpinner"></i>
                    <i class="fa fa-file-pdf-o" aria-hidden="true" *ngIf="!inSpinner"></i>
                    &nbsp;&nbsp;{{inSpinner ? ' Gerando o arquivo...' : ' Clique aqui para baixar seu informe'}}
                </button>
            </div>
        </div>
    </div>
</div>