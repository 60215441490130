<!-- Sign In Layout -->
<div style="padding: 8rem 0 5rem; margin-bottom: 3rem;">
    <div class="container container-full-width">
        <div class="row">
            <div class="col-sm-12 col-md-11 col-lg-9 mx-auto">
                <div class="row no-gutters">
                    <div class="col-sm-12 col-md-3 d-xs-none">
                        &nbsp; &nbsp;
                    </div>
                    <div class="col-sm-12 col-md-6 py-5">
                        <div class="card border-0 p-4 rounded-0 card-shadow">
                            <div class="form-group mb-4" *ngIf="erromessage.length > 0">
                                <div class="alert alert-danger" role="alert">
                                    {{erromessage}}
                                </div>
                            </div>
                            <form class="session-form" [formGroup]="formGroup">
                                <div class="social-widget mb-4" style="text-align: center;">
                                    <h2 class="text-capitalize font-2x mb-4">Minha Área</h2>
                                </div><!-- Social widget closed -->
                                <div class="mb-5">
                                    <div class="form-group">
                                        <label for="InputEmail1" class="contro-label">CPF <b
                                                style="color: red;">*</b></label>
                                        <input type="text" class="form-control form-control-lg" id="InputEmail1"
                                            formControlName="cpf" mask="000.000.000-00" (keyup.enter)="entrar()"
                                            [ngClass]="validadorService.mostrarCSSErro('cpf', formGroup)" required>
                                        <div class="invalid-feedback">
                                            O campo obrigatório!
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="password" class="contro-label">Senha <b
                                                style="color: red;">*</b></label>
                                        <div class="input-group">
                                            <input [type]="tipoexibicao" class="form-control form-control-lg" id="password"
                                                formControlName="password" (keyup.enter)="entrar()" maxlength="10"
                                                [ngClass]="validadorService.mostrarCSSErro('password', formGroup)"
                                                required>
                                            <div class="input-group-addon">
                                                <i class="fa fa-eye" aria-hidden="true" *ngIf="ocultarSenha" style="cursor: pointer;" (click)="ocultarClick(false)"></i>
                                                <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="!ocultarSenha" style="cursor: pointer;" (click)="ocultarClick(true)"></i>
                                            </div>
                                        </div>
                                        <div class="invalid-feedback">
                                            O campo obrigatório!
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="button" (click)="entrar()" [disabled]="inSpinner"
                                        class="btn btn-primary btn-block btn-lg submit-btn">
                                        <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf="inSpinner"></i>
                                        {{inSpinner ? ' Validando...' : ' Acessar'}}
                                    </button>
                                </div>
                                <div class="form-group text-center">
                                    <p> <a style="cursor: pointer;" (click)="reset()">
                                            Esqueci Minha Senha!</a></p>
                                    <p> <a style="cursor: pointer;" (click)="novasenha()">
                                            Solicitar Nova Senha!</a></p>
                                </div>
                            </form><!-- session form closed -->
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-3 d-xs-none">
                        &nbsp; &nbsp;
                    </div>
                </div>
            </div>
        </div>
    </div><!-- container closed -->
</div><!-- session Wrapper closed -->