import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ValidadorService } from 'app/services/validador.service';
import { finalize } from 'rxjs/operators';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-canal-denuncia-protocolo',
  templateUrl: './canal-denuncia-protocolo.component.html',
  styleUrls: ['./canal-denuncia-protocolo.component.css']
})
export class CanalDenunciaProtocoloComponent implements OnInit {

  protocoloForm: FormGroup;
  formGroupReplica: FormGroup;
  inSpinner = false;
  inSpinnerReplica = false;
  sucesso = false;
  sucessoReplica = false;
  emreplica = false;
  erromessage = '';
  mensagemNaoEncontrado = '';
  erromessageReplica = '';
  protocolo: any;
  textoFinal = 'Vamos analizar sua resposta, em breve você poderá acompanhar o andamento em nosso site.';

  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    public validadorService: ValidadorService,
    public servico: SiteService,
    private spinner: NgxSpinnerService,
    public mensagensService: MensagensService
  ) {
    this.protocoloForm = this.formBuilder.group({
      protocolo: [null, [Validators.required]]
    });

    this.formGroupReplica = this.formBuilder.group({
      texto: [null, [Validators.required]],
      idCanalDenuncia: [null, [Validators.required]]
    });
  }

  ngOnInit(): void {
  }

  irParaTopo() {
    let top = document.getElementById('topodenuncia');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  replica() {
    this.emreplica = true;
  }

  abrirLink(url: string) {
    window.open(url, '_blank');
  }

  finalizar() {
    const options = {
      title: 'Finalizar Protocolo!',
      text: 'Tem certeza que deseja finalizar esse protocolo?',
      type: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    };
    Swal.fire(options).then((resultado) => {
      if (resultado.value) {
        this.spinner.show();
        this.servico.finalizar(this.protocolo.protocolo)
          .pipe(finalize(() => {
            this.spinner.hide();
          }))
          .subscribe(result => {
            this.spinner.hide();
            this.textoFinal = 'Protocolo finalizado com sucesso, a DERMINAS agradeçe seu contato.';
            this.sucessoReplica = true;
            this.irParaTopo();
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'A DERMINAS agradeçe pelo seu contato',
              showConfirmButton: false,
              timer: 7000
            });
          },
            err => {
              this.spinner.hide();
              const erro = this.mensagensService.recuperarMensagemErro(err);
              this.erromessageReplica = erro;
            });
      }
    });
  }

  sendReplica() {
    if (this.formGroupReplica.valid) {
      this.spinner.show();
      this.inSpinnerReplica = true;
      this.servico.replica(this.formGroupReplica.value)
        .pipe(finalize(() => {
          this.spinner.hide();
          this.inSpinnerReplica = false;
        }))
        .subscribe(result => {
          this.spinner.hide();
          this.sucessoReplica = true;

          this.irParaTopo();
          this.formGroupReplica.reset();
          this.formGroupReplica.markAsUntouched();
        },
          err => {
            this.spinner.hide();
            this.inSpinnerReplica = false;
            const erro = this.mensagensService.recuperarMensagemErro(err);
          });
    } else {
      this.spinner.hide();
      this.inSpinnerReplica = false;
      this.validadorService.validarTodosCampos(this.formGroupReplica);
      this.erromessage = 'Existem campos obrigatórios sem o devido preenchimento';
      this.irParaTopo();
    }
  }

  getTextoLabel() {
    if (this.protocolo.situacao === 'F' || this.protocolo.situacao === 'X') {
      return `O protocolo <b>${this.protocolo?.protocolo}</b> foi <b>${this.protocolo?.situacaoLabel}</b> em <b>${this.protocolo?.dataFinalizacaoFormatada}</b>.`;
    } else {
      return `O protocolo <b>${this.protocolo?.protocolo}</b> encontra-se em <b>${this.protocolo?.situacaoLabel}.`;
    }
  }

  pesquisar() {
    if (this.protocoloForm.valid) {
      this.spinner.show();
      this.sucesso = false;
      this.mensagemNaoEncontrado = '';
      this.inSpinner = true;
      this.servico.protocolo(this.protocoloForm.value.protocolo)
        .pipe(finalize(() => {
          this.inSpinner = false;
          this.spinner.hide();
        }))
        .subscribe(result => {
          this.spinner.hide();
          if (result.mensagemErro.length > 0) {
            this.mensagemNaoEncontrado = result.mensagemErro;
            this.protocolo = null;
            this.formGroupReplica.patchValue({
              idCanalDenuncia: null
            });
          } else {
            this.sucesso = true;
            this.protocolo = result;
            this.formGroupReplica.patchValue({
              idCanalDenuncia: result.idCanalDenuncia
            });
            this.irParaTopo();
            this.protocoloForm.reset();
            this.protocoloForm.markAsUntouched();
          }
        },
          err => {
            this.spinner.hide();
            this.inSpinner = false;
            const erro = this.mensagensService.recuperarMensagemErro(err);
          });
    } else {
      this.spinner.hide();
      this.inSpinner = false;
      this.validadorService.validarTodosCampos(this.protocoloForm);
      this.erromessage = 'Existem campos obrigatórios sem o devido preenchimento';
      this.irParaTopo();
    }
  }

}
