<!-- Sign In Layout -->
<div class="session-wrapper" style="padding: 5rem 0 5rem!important;">
    <div class="container container-full-width">
        <div class="row">
            <div class="col-sm-12 col-md-11 col-lg-9 mx-auto">
                <div class="row no-gutters">
                    <div class="col-sm-12 col-md-3 d-xs-none">
                        &nbsp; &nbsp;
                    </div>
                    <div class="col-sm-12 col-md-6 py-5">
                        <div class="card border-0 p-4 rounded-0 card-shadow">
                            <div class="form-group mb-4" *ngIf="erromessage.length > 0">
                                <div class="alert alert-danger" role="alert">
                                    {{erromessage}}
                                </div>
                            </div>
                            <div class="form-group mb-4" *ngIf="sucessomessage">
                                <div class="alert alert-success" role="alert" style="text-align: center;">
                                    Uma nova senha foi enviada para o <br />
                                    e-mail informado e você deverá
                                    <br />alterá-la no seu primeiro acesso.
                                </div>
                            </div>
                            <form class="session-form" [formGroup]="formGroup">
                                <div class="social-widget mb-4" *ngIf="!sucessomessage">
                                    <h2 class="text-capitalize font-2x mb-4">Receber nova senha</h2>
                                </div>
                                <div class="mb-5" *ngIf="!sucessomessage">
                                    <div class="form-group">
                                        <label for="InputCpf">CPF</label>
                                        <input type="text" class="form-control form-control-lg" id="InputCpf"
                                            appAutocompleteOff formControlName="cpf" mask="000.000.000-00"
                                            (keyup.enter)="entrar()"
                                            [ngClass]="validadorService.mostrarCSSErro('cpf', formGroup)" required>
                                    </div>
                                    <div class="form-group">
                                        <label for="InputMatricula">Matricula</label>
                                        <input type="text" class="form-control form-control-lg" id="InputMatricula"
                                            maxlength="6" appAutocompleteOff formControlName="matricula"
                                            (keyup.enter)="entrar()"
                                            [ngClass]="validadorService.mostrarCSSErro('matricula', formGroup)"
                                            required>
                                    </div>
                                    <div class="form-group">
                                        <label for="InputMae">Primeiro Nome da Mãe</label>
                                        <input type="text" class="form-control form-control-lg" id="InputMae"
                                            maxlength="20" appAutocompleteOff formControlName="nomeMae"
                                            (keyup.enter)="entrar()" [popover]="myPopover4"
                                            [ngClass]="validadorService.mostrarCSSErro('nomeMae', formGroup)" required>
                                        <popover-content #myPopover4 title="Instruções!" [closeOnClickOutside]="true">
                                            Informar o primeiro nome da <b>mãe do titular do CPF digitado</b>.
                                        </popover-content>
                                    </div>
                                    <div class="form-group">
                                        <label for="email">E-mail</label>
                                        <input type="email" class="form-control form-control-lg" id="email"
                                            maxlength="250" appAutocompleteOff formControlName="email"
                                            (keyup.enter)="entrar()"
                                            [ngClass]="validadorService.mostrarCSSErro('email', formGroup)" required>
                                    </div>
                                    <div class="form-group">
                                        <label for="repetiremail">Confirme o e-mail</label>
                                        <input type="email" class="form-control form-control-lg" id="repetiremail"
                                            appAutocompleteOff formControlName="repetiremail" maxlength="250"
                                            appBlockCopyPaste (keyup.enter)="entrar()"
                                            [ngClass]="validadorService.mostrarCSSErro('repetiremail', formGroup)"
                                            required>
                                        <div *ngIf="f.repetiremail.errors" class="invalid-feedback">
                                            <div *ngIf="f.repetiremail.errors.required">Confirme o e-mail é obrigatório
                                            </div>
                                            <div *ngIf="f.repetiremail.errors.mustMatch">Os e-mails digitados não
                                                conferem</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="!sucessomessage">
                                    <button type="button" (click)="entrar()" [disabled]="inSpinner"
                                        class="btn btn-primary btn-block btn-lg submit-btn">
                                        <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf="inSpinner"></i>
                                        {{inSpinner ? ' Validando...' : ' Salvar'}}
                                    </button>
                                </div>
                                <div class="form-group text-center">
                                    <p> <a style="cursor: pointer;" (click)="login()">
                                            Acessar Minha Área!</a></p>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3 d-xs-none">
                        &nbsp; &nbsp;
                    </div>
                </div>
            </div>
        </div>
    </div><!-- container closed -->
</div><!-- session Wrapper closed -->