import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // const agora = moment();
        // const currentUser = JSON.parse(localStorage.getItem('localUsuarioDerminas'));
        // console.log('validade --> ' + currentUser?.validade);
        // console.log('time --> ' + agora);
        if (this.isAuthenticated()) {
            return true;
        }
        localStorage.removeItem('localUsuarioDerminas');
        this.router.navigate(['acesso-restrito']);
        return false;
        // return true;
    }

    isAuthenticated(): boolean {
        const agora = moment();
        const currentUser = JSON.parse(localStorage.getItem('localUsuarioDerminas'));
        if (currentUser === null) {
            return false;
        }
        if (currentUser.validade === null) {
            return false;
        }
        if (!moment(currentUser.validade).isAfter(agora)) {
            return false;
        }
        if (currentUser.validade === undefined) {
            return false;
        }
        return true;
    }
}
