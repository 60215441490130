import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { AcessoRestritoSiteService } from 'app/services/acesso-restrito.service';
import { EventosService } from 'app/services/eventos.service';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ValidadorService } from 'app/services/validador.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-acesso-restrito',
  templateUrl: './acesso-restrito.component.html',
  styleUrls: ['./acesso-restrito.component.css']
})
export class AcessoRestritoComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  formGroupNovo: FormGroup;
  formGroupRecuperar: FormGroup;
  inSpinner = false;
  inSpinnerNovo = false;
  sucessoCriar = false;
  sucessoRecuperar = false;
  inSpinnerRecuperar = false;
  erromessage = '';
  tipocontrole = 'L';
  successmessage = '';
  private _unsubscribeAll: Subject<any>;
  tipoexibicao = 'password';
  ocultarSenha = true;

  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    private eventosService: EventosService,
    public validadorService: ValidadorService,
    public servico: SiteService,
    private acessoRestritoSiteService: AcessoRestritoSiteService,
    public router: Router,
    public mensagensService: MensagensService
  ) {
    /* Page title */
    this.pageTitleService.setTitle('Minha Área');
    /* Page subTitle */
    this.pageTitleService.setSubTitle('');
    this.formGroup = this.formBuilder.group({
      password: [null, Validators.required],
      cpf: [null, Validators.required]
    });

    this.formGroupNovo = this.formBuilder.group({
      matricula: [null, Validators.required],
      cpf: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      celular: [null, Validators.required]
    });

    this.formGroupRecuperar = this.formBuilder.group({
      matricula: [null, Validators.required],
      cpf: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]]
    });
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.eventosService.onUsuarioLogou
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(selectedTodos => {
      });
  }

  ocultarClick(event): void {
    if (event) {
      this.tipoexibicao = 'password';
      this.ocultarSenha = true;
    } else {
      this.tipoexibicao = 'text';
      this.ocultarSenha = false;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  reset() {
    this.router.navigate(['recuperar-acesso']);
  }

  novasenha() {
    this.router.navigate(['nova-senha-acesso']);
  }

  criar() {
    this.erromessage = '';
    if (this.formGroupNovo.valid) {
      this.inSpinnerNovo = true;
      this.acessoRestritoSiteService.criaracesso(this.formGroupNovo.value)
        .pipe(finalize(() => {
          this.inSpinnerNovo = false;
        }))
        .subscribe(result => {
          if (result.sucesso) {
            this.erromessage = '';
            this.sucessoCriar = true;
            this.sucessoRecuperar = false;
            this.successmessage = result.mensagem;
          } else {
            this.erromessage = result.mensagem;
            this.sucessoCriar = false;
            this.sucessoRecuperar = false;
            this.successmessage = '';
          }
        },
          err => {
            this.inSpinnerNovo = false;
            const erro = this.mensagensService.recuperarMensagemErro(err);
            this.erromessage = erro;
          });
    } else {
      this.inSpinnerNovo = false;
      this.validadorService.validarTodosCampos(this.formGroupNovo);
      this.erromessage = 'Existem campos obrigatórios sem o devido preenchimento';
    }
  }

  recuperar() {
    this.erromessage = '';
    this.sucessoRecuperar = false;
    if (this.formGroupRecuperar.valid) {
      this.inSpinnerRecuperar = true;
      this.acessoRestritoSiteService.recuperar(this.formGroupRecuperar.value)
        .pipe(finalize(() => {
          this.inSpinnerRecuperar = false;
        }))
        .subscribe(result => {
          if (result.sucesso) {
            this.erromessage = '';
            this.sucessoCriar = false;
            this.sucessoRecuperar = true;
            this.successmessage = result.mensagem;
          } else {
            this.erromessage = result.mensagem;
            this.sucessoCriar = false;
            this.sucessoRecuperar = false;
            this.successmessage = '';
          }
        },
          err => {
            this.inSpinnerRecuperar = false;
            const erro = this.mensagensService.recuperarMensagemErro(err);
            this.erromessage = erro;
          });
    } else {
      this.inSpinnerRecuperar = false;
      this.validadorService.validarTodosCampos(this.formGroupRecuperar);
      this.erromessage = 'Existem campos obrigatórios sem o devido preenchimento';
    }
  }

  entrar() {
    this.erromessage = '';
    if (this.formGroup.valid) {
      this.inSpinner = true;
      this.servico.arearestrita(this.formGroup.value)
        .pipe(finalize(() => {
          this.inSpinner = false;
        }))
        .subscribe(result => {
          localStorage.removeItem('pesquisa');
          if (result.primeiroAcesso === 'S') {
            this.router.navigate(['primeiro-acesso']);
          } else {
            localStorage.setItem(
              'localUsuarioDerminas',
              JSON.stringify({
                validade: result.validade,
                nomeUsuario: result.nomeUsuario,
                idUsuario: result.idUsuario,
                tipoUsuario: result.tipoUsuario,
                userId: result.userId,
                tipo: result.tipo,
                mesAniversario: result.mesAniversario,
                cpf: result.cpf,
                primeiroAcesso: result.primeiroAcesso,
                temQueAtualizar: result.temQueAtualizar,
                exibirEmprestimo: result.exibirEmprestimo,
                exibirPesquisa: result.exibirPesquisa,
                idPensionista: result?.idPensionista,
                idParticipante: result?.idParticipante
              })
            );
            this.eventosService.logou(true);
            this.router.navigate(['minha-area']);
          }
        },
          err => {
            this.eventosService.logou(false);
            this.inSpinner = false;
            const erro = this.mensagensService.recuperarMensagemErro(err);
            this.erromessage = erro;
          });
    } else {
      this.inSpinner = false;
      this.validadorService.validarTodosCampos(this.formGroup);
      this.erromessage = 'Existem campos obrigatórios sem o devido preenchimento';
    }
  }

}
