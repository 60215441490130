import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';

@Component({
  selector: 'app-fique-por-dentro',
  templateUrl: './fique-por-dentro.component.html',
  styleUrls: ['./fique-por-dentro.component.css']
})
export class FiquePorDentroComponent implements OnInit {

  noticias: any[] = [];
  inicializacao = true;

  constructor(
    public servico: SiteService,
    public router: Router,
    public mensagensService: MensagensService
  ) {
    this.servico.listarNoticias().
      subscribe(response => {
        this.noticias = response;
        this.inicializacao = false;
      },
        err => console.log(err),
        () => this.noticias
      );
  }

  ngOnInit(): void {
  }

  redirecionar(item: any) {
    if (item.tipo === 'S') {
      this.router.navigate([item.redirecionamento]);
    } else {
      window.open(item.redirecionamento, '_blank');
    }
  }


}
