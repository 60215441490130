import { StringUtils } from './string-utils';
import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Injectable()
export class MensagensService {


    constructor() { }

    public ErrorConfirm(title: string = 'Erro!', text: string = 'Ocorreu um erro') {
        // const options = {
        //     title: 'Abandonar o Atendimento?',
        //     text: 'Se você sair sem finalizar o atendimento, os dados não serão salvos.',
        //     type: 'warning',
        //     showCancelButton: true,
        //     allowOutsideClick: false,
        //     cancelButtonText: 'Não, voltar ao atendimento',
        //     confirmButtonColor: '#d33',
        //     cancelButtonColor: '#3085d6',
        //     confirmButtonText: 'Sim'
        // };
        const options = {
            title: title,
            text: text,
            type: 'error',
            allowOutsideClick: false
        };
        Swal.fire(options);
    }

    public Error(title: string = 'Erro!', text: string = 'Ocorreu um erro') {
        const options = {
            title: title,
            text: text,
            type: 'error',
            allowOutsideClick: false
        };
        Swal.fire(options);
    }

    public isNullOrEmpty(val): boolean {
        if (val === undefined || val === null || val.trim() === '') {
            return true;
        }
        return false;
    }

    recuperarMensagemErro(err: any) {
        console.log(err);
        if (err.status === 400) {
            if (this.isNullOrEmpty(err.error.message)) {
                return err.error;
            }
            return err.error.message;
        } else if (err.status === 404) {
            if (this.isNullOrEmpty(err.error)) {
                return 'Erro ao processar a requisição';
            }
            if (this.isNullOrEmpty(err.error.message)) {
                return err.error;
            }
            return err.error.message;
        } else if (err.status === 500) {
            if (this.isNullOrEmpty(err.error.message)) {
                return err.error;
            } else if (!this.isNullOrEmpty(err.error.exceptionMessage)) {
                return err.error.message + ' - ' + err.error.exceptionMessage;
            }
            return err.error.message;
        }
    }

    public SuccessConfirm(title: string = 'Sucesso!', text: string = 'Atualizado com sucesso!') {
        const options = {
            title: title,
            text: text,
            type: 'success',
            allowOutsideClick: false,
            confirmButtonText: 'OK'
        };
        Swal.fire(options);
    }

    public Success(title: string = 'Sucesso!', text: string = 'Atualizado com sucesso') {
        const options = {
            title: title,
            text: text,
            type: 'success',
            allowOutsideClick: false
        };
        Swal.fire(options);
    }

    public InfoConfirm(title: string = 'Atenção!', text: string = 'Atenção!') {
        const options = {
            title: title,
            text: text,
            type: 'info',
            confirmButtonText: 'OK',
            allowOutsideClick: false
        };
        Swal.fire(options);
    }

    public Info(title: string = 'Atenção!', text: string = 'Atenção') {
        const options = {
            title: title,
            text: text,
            type: 'info',
            allowOutsideClick: false
        };
        Swal.fire(options);
    }

    public WarningConfirm(title: string = 'Atenção!', text: string = 'Atenção!') {
        const options = {
            title: title,
            text: text,
            type: 'warning',
            confirmButtonText: 'OK',
            allowOutsideClick: false
        };
        Swal.fire(options);
    }

    public Warning(title: string = 'Atenção!', text: string = 'Atenção') {
        const options = {
            title: title,
            text: text,
            type: 'warning',
            allowOutsideClick: false
        };
        Swal.fire(options);
    }

    public HtmlMensagem(title: string = 'Atenção!', html: string) {
        const options = {
            title: title,
            html: html,
            allowEscapeKey: false,
            type: 'info',
            allowOutsideClick: false
        };
        Swal.fire(options);
    }
}
