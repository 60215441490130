<!-- Sign In Layout -->
<div class="session-wrapper" style="padding: 5rem 0 5rem!important;">
    <div class="container container-full-width">
        <div class="row">
            <div class="col-sm-12 col-md-11 col-lg-9 mx-auto">
                <div class="row no-gutters">
                    <div class="col-sm-12 col-md-3 d-xs-none">
                        &nbsp; &nbsp;
                    </div>
                    <div class="col-sm-12 col-md-6 py-5">
                        <div class="card border-0 p-4 rounded-0 card-shadow">
                            <div class="form-group mb-4">
                                <div class="alert alert-info" role="alert">
                                    Esse é o seu primeiro acesso, você precisa trocar sua senha.
                                </div>
                            </div>
                            <div class="form-group mb-4" *ngIf="erromessage.length > 0">
                                <div class="alert alert-danger" role="alert">
                                    {{erromessage}}
                                </div>
                            </div>
                            <form class="session-form" [formGroup]="formGroup">
                                <div class="social-widget mb-4">
                                    <h2 class="text-capitalize font-2x mb-4">Troca de senha</h2>
                                </div>
                                <div class="mb-5">
                                    <div class="form-group">
                                        <label for="InputCpf">CPF</label>
                                        <input type="text" class="form-control form-control-lg" id="InputCpf"
                                            appAutocompleteOff formControlName="cpf" mask="000.000.000-00"
                                            (keyup.enter)="entrar()"
                                            [ngClass]="validadorService.mostrarCSSErro('cpf', formGroup)" required>
                                    </div>
                                    <div class="form-group">
                                        <label for="InputMatricula">Matricula</label>
                                        <input type="text" class="form-control form-control-lg" id="InputMatricula"
                                            maxlength="6" appAutocompleteOff formControlName="matricula"
                                            (keyup.enter)="entrar()"
                                            [ngClass]="validadorService.mostrarCSSErro('matricula', formGroup)"
                                            required>
                                    </div>
                                    <div class="form-group">
                                        <label for="InputMae">Primeiro Nome da Mãe</label>
                                        <input type="text" class="form-control form-control-lg" id="InputMae"
                                            maxlength="20" appAutocompleteOff formControlName="nomeMae"
                                            (keyup.enter)="entrar()" [popover]="myPopover4"
                                            [ngClass]="validadorService.mostrarCSSErro('nomeMae', formGroup)" required>
                                        <popover-content #myPopover4 title="Instruções!" [closeOnClickOutside]="true">
                                            Informar o primeiro nome da <b>mãe do titular do CPF digitado</b>.
                                        </popover-content>
                                    </div>
                                    <div class="form-group">
                                        <label for="senha">Senha Atual</label>
                                        <input type="password" class="form-control form-control-lg" id="senha"
                                            maxlength="10" appAutocompleteOff formControlName="senha"
                                            (keyup.enter)="entrar()"
                                            [ngClass]="validadorService.mostrarCSSErro('senha', formGroup)" required>
                                    </div>
                                    <div class="form-group">
                                        <label for="novaSenha">Nova Senha</label>
                                        <input type="password" class="form-control form-control-lg" id="novaSenha"
                                            appAutocompleteOff #password2 formControlName="novaSenha" maxlength="10"
                                            (keyup.enter)="entrar()" [popover]="myPopover2"
                                            [ngClass]="validadorService.mostrarCSSErro('novaSenha', formGroup)"
                                            required>
                                        <password-strength-meter [password]="password2.value"></password-strength-meter>
                                        <popover-content #myPopover2 title="Instruções!" [closeOnClickOutside]="true">
                                            A senha deve ter no <b>mínimo 6 e no máximo 10</b> caracteres,
                                            podendo conter <b>números, letras e símbolos</b>.
                                        </popover-content>
                                        <small class="text-muted"
                                            *ngIf="formGroup.get('novaSenha').hasError('minlength')"><span
                                                style="color: red;">A nova senha deve
                                                conter pelo
                                                menos 6 caracteres.</span></small>
                                    </div>
                                    <div class="form-group">
                                        <label for="repetirNovaSenha">Confirme Nova Senha</label>
                                        <input type="password" class="form-control form-control-lg" maxlength="10"
                                            id="repetirNovaSenha" formControlName="repetirNovaSenha" appAutocompleteOff
                                            (keyup.enter)="entrar()" [popover]="myPopover3"
                                            [ngClass]="validadorService.mostrarCSSErro('repetirNovaSenha', formGroup)"
                                            required>
                                        <popover-content #myPopover3 title="Instruções!" [closeOnClickOutside]="true">
                                            A senha deve ter no <b>mínimo 6 e no máximo 10</b> caracteres,
                                            podendo conter <b>números, letras e símbolos</b>.
                                        </popover-content>
                                        <small class="text-muted"
                                            *ngIf="formGroup.get('repetirNovaSenha').hasError('minlength')"><span
                                                style="color: red;">O Confirme
                                                nova senha deve
                                                conter pelo
                                                menos 6 caracteres.</span></small>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="button" (click)="entrar()" [disabled]="inSpinner"
                                        class="btn btn-primary btn-block btn-lg submit-btn">
                                        <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf="inSpinner"></i>
                                        {{inSpinner ? ' Validando...' : ' Salvar'}}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3 d-xs-none">
                        &nbsp; &nbsp;
                    </div>
                </div>
            </div>
        </div>
    </div><!-- container closed -->
</div><!-- session Wrapper closed -->