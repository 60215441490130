<div id="toporecadastramento" class="contact-widget">
    <div class="container">
        <!-- <div class="row" style="margin-bottom: 15px;">
            <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <p>Seja bem vindo(a) <b>{{nomeUsuario}}</b></p>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 text-right">
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="voltar()"
                    *ngIf="!temQueAtualizar">
                    <i class="fa fa-arrow-left" aria-hidden="true"></i> Voltar
                </button>
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="sair()"
                    style="cursor: pointer; margin-left: 10px;" ngbTooltip="Sair"><i class="fa fa-sign-out"></i>
                    Sair</button>
            </div>
        </div> -->
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="alert alert-warning" role="alert" *ngIf="!temQueAtualizar">
                    <p><b>Prezado Participante,</b></p>
                    <p>Gentileza conferir seus dados, alterando as informações incorretas ou desatualizadas quando for o
                        caso. </p>
                    <p> Ao terminar a validação, você deverá clicar em "Salvar", enviado assim os seus dados de forma
                        online
                        para atualização. Em seguida clique em "<b>Gerar Formulário em PDF</b>" para imprimir o
                        formulário, assinar e encaminhar à Derminas.</p>

                    <p><i>Em observância à LGPD (Lei Geral de Proteção de Dados), informamos que os dados aqui coletados
                            têm por finalidade o cumprimento de obrigações legais e regulatórias por parte da DERMINAS e
                            que a entidade os preservará em sigilo. A eventual utilização e tratamento dos dados
                            pessoais somente ocorrerá quando estritamente necessária para cumprimento dos propósitos
                            previdenciários assegurados e das obrigações relacionadas, sempre observadas as regras e o
                            princípios descritos na LGPD. Caso tenha dúvidas entre em contato pelo e-mail
                            <u>derminas@derminas.org.br</u> </i></p>

                </div>

                <div class="alert alert-warning" role="alert" *ngIf="temQueAtualizar">
                    <p>Esse é seu primeiro acesso, valide seus dados para continuar.</p>
                    <p><i>Em observância à LGPD (Lei Geral de Proteção de Dados), informamos que os dados aqui coletados
                            têm por finalidade o
                            cumprimento de obrigações legais e regulatórias por parte da DERMINAS e que a entidade os
                            preservará em sigilo.
                            A eventual utilização e tratamento dos dados pessoais somente ocorrerá quando estritamente
                            necessária para cumprimento
                            dos propósitos previdenciários assegurados e das obrigações relacionadas, sempre observadas
                            as regras e o
                            princípios descritos na LGPD. Caso tenha dúvidas entre em contato pelo e-mail
                            <u>derminas@derminas.org.br</u> </i></p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="alert alert-light" role="alert">
                    <p style="color: #b71c1c;"> Os campos com informações cadastrais tais como nome, data de nascimento,
                        filiação,
                        CPF,
                        identidade, não podem ser alterados pelo usuário. Gentileza entrar em contato através do e-mail
                        <u>site@derminas.org.br</u> e solicitar a atualização.
                    </p>
                </div>
            </div>
        </div>
        <form [formGroup]="formGroup" style="width: 100%;">
            <div class="row">
                <input type="hidden" formControlName="matricula">
                <input type="hidden" formControlName="masp">
                <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8">
                    <div class="form-group">
                        <label class="contro-label">Nome</label>
                        <input name="nome" id="nome" formControlName="nomeParticipante" class="form-control"
                            maxlength="500" placeholder="Nome do Participante" />
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                    <div class="form-group">
                        <label class="contro-label required">Matrícula</label>
                        <input name="matricula" id="matricula" formControlName="matriculaValue" class="form-control"
                            maxlength="50" placeholder="Matrícula" />
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                    <div class="form-group">
                        <label class="contro-label required">MASP</label>
                        <input name="masp" id="masp" formControlName="maspValue" class="form-control" maxlength="50"
                            placeholder="MASP" />
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Situação</label>
                        <select id="estadoCivil" formControlName="situacao" class="form-control" style="color: #000000;"
                            [ngClass]="validadorService.mostrarCSSErro('situacao', formGroup)">
                            <option *ngFor="let item of situacoes" [value]="item.value">
                                {{item.displayName}}</option>
                        </select>
                        <div class="invalid-feedback">
                            Campo obrigatório!
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Cargo</label>
                        <input name="cargo" id="cargo" formControlName="cargo" class="form-control" maxlength="150"
                            placeholder="Cargo" />
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Local Trabalho</label>
                        <select id="estadoCivil" formControlName="localTrabalho" class="form-control"
                            style="color: #000000;"
                            [ngClass]="validadorService.mostrarCSSErro('localTrabalho', formGroup)">
                            <option *ngFor="let item of locais" [value]="item.value">
                                {{item.displayName}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Sexo</label>
                        <select id="estadoCivil" formControlName="sexo" class="form-control" style="color: #000000;">
                            <option *ngFor="let item of sexos" [value]="item.value">
                                {{item.displayName}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Data Nascimento</label>
                        <div class="input-group" (click)="d.toggle()">
                            <input class="form-control" placeholder="dd/mm/aaaa" name="dp"
                                formControlName="dataNascimento" ngbDatepicker #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-light btn-icon calendar" type="button">
                                    <i class="fa fa-calendar text-secondary"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Data Trabalho</label>
                        <div class="input-group" (click)="d2.toggle()">
                            <input class="form-control" placeholder="dd/mm/aaaa" name="dp2"
                                formControlName="dataTrabalho" ngbDatepicker #d2="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-light btn-icon calendar" type="button">
                                    <i class="fa fa-calendar text-secondary"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                    <div class="form-group">
                        <label class="contro-label">RG</label>
                        <input name="carteiraIdentidade" id="carteiraIdentidade" formControlName="carteiraIdentidade"
                            class="form-control" maxlength="20" placeholder="Carteira Identidade" />
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                    <div class="form-group">
                        <label class="contro-label">Órgão Emissor </label>
                        <input name="orgaoEmissor" id="orgaoEmissor" formControlName="orgaoEmissor" class="form-control"
                            maxlength="50" placeholder="Órgão Emissor" />
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                    <div class="form-group">
                        <label class="contro-label">CPF</label>
                        <input name="cpf" id="cpf" formControlName="cpf" class="form-control" maxlength="20"
                            mask="000.000.000-00" placeholder="CPF" />
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                    <div class="form-group">
                        <label class="contro-label">Estado Civil</label>
                        <select id="estadoCivil" formControlName="estadoCivil" class="form-control"
                            style="color: #000000;"
                            [ngClass]="validadorService.mostrarCSSErro('estadoCivil', formGroup)">
                            <option *ngFor="let item of estadosCivil" [value]="item.value">
                                {{item.displayName}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-5 col-lg-4 col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Naturalidade</label>
                        <input name="naturalidade" id="naturalidade" formControlName="naturalidade" class="form-control"
                            maxlength="500" placeholder="Naturalidade" />
                    </div>
                </div>
                <div class="col-sm-12 col-md-7 col-lg-8 col-xl-8">
                    <div class="form-group">
                        <label class="contro-label">Nome da Mãe </label>
                        <input name="nomeMae" id="nomeMae" formControlName="nomeMae" class="form-control"
                            maxlength="500" placeholder="Nome da Mãe" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                    <div class="form-group">
                        <label class="contro-label">CEP </label>
                        <input name="cep" id="cep" formControlName="cep" class="form-control" maxlength="10"
                            mask="00.000-000" placeholder="CEP"
                            [ngClass]="validadorService.mostrarCSSErro('cep', formGroup)" />
                        <div class="invalid-feedback">
                            Campo obrigatório!
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-7 col-xl-7">
                    <div class="form-group">
                        <label class="contro-label">Logradouro </label>
                        <input name="logradouro" id="logradouro" formControlName="logradouro" class="form-control"
                            maxlength="500" placeholder="Logradouro"
                            [ngClass]="validadorService.mostrarCSSErro('logradouro', formGroup)" />
                        <div class="invalid-feedback">
                            Campo obrigatório!
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                    <div class="form-group">
                        <label class="contro-label">Número </label>
                        <input name="numero" id="numero" formControlName="numeroLogradouro" class="form-control"
                            maxlength="20" placeholder="Número" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Bairro</label>
                        <input name="bairro" id="bairro" formControlName="bairro" class="form-control" maxlength="200"
                            placeholder="Bairro" [ngClass]="validadorService.mostrarCSSErro('bairro', formGroup)" />
                        <div class="invalid-feedback">
                            Campo obrigatório!
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Cidade</label>
                        <input name="cidade" id="cidade" formControlName="cidade" class="form-control" maxlength="200"
                            placeholder="Cidade" [ngClass]="validadorService.mostrarCSSErro('cidade', formGroup)" />
                        <div class="invalid-feedback">
                            Campo obrigatório!
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Estado</label>
                        <select id="estado" formControlName="estado" class="form-control" style="color: #000000;"
                            [ngClass]="validadorService.mostrarCSSErro('estado', formGroup)"
                            [ngClass]="validadorService.mostrarCSSErro('estado', formGroup)">
                            <option *ngFor="let item of estados" [value]="item.value">
                                {{item.displayName}}</option>
                        </select>
                        <div class="invalid-feedback">
                            Campo obrigatório!
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="form-group">
                        <label class="contro-label">Complemento</label>
                        <input name="cidade" id="complemento" formControlName="complemento" class="form-control"
                            maxlength="4000" placeholder="Complemento" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Telefone Residencial</label>
                        <input name="telefone1" id="telefone1" formControlName="numeroTelefone1" class="form-control"
                            appDerminasPhoneMaskBr maxlength="15" placeholder="Telefone"/>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Telefone Celular</label>
                        <input name="telefone2" id="numeroCelular" formControlName="numeroTelefone2"
                            style="background-color: #e1f5fe;" class="form-control" appDerminasPhoneMaskBr
                            maxlength="15" placeholder="Telefone Celular" />
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Telefone Comercial</label>
                        <input name="telefone3" id="telefone3" formControlName="numeroTelefone3" class="form-control"
                            appDerminasPhoneMaskBr maxlength="15" placeholder="Telefone Comercial" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="form-group">
                        <label class="contro-label">E-mail</label>
                        <input name="email" id="email" formControlName="email" type="email" class="form-control"
                            style="background-color: #e1f5fe;" maxlength="200" placeholder="E-mail"
                            [ngClass]="validadorService.mostrarCSSErro('email', formGroup)" />
                        <div class="invalid-feedback">
                            Campo obrigatório!
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <p><i>Em cumprimento a legislação, se você não exerceu nos últimos 5 (cinco) anos, nenhum dos cargos
                            ou
                            funções a
                            que se refere a Instrução MPS/SPC nº 26, de 01/09/2008, e não tem conhecimento de que nenhum
                            de
                            seus
                            familiares, representantes ou pessoas de seu relacionamento próximo os tenha exercido,
                            declare
                            que
                            <b>não se
                                enquadra como pessoa politicamente exposta</b>, caso contrário informe que você <b>se
                                enquadra na condição
                                de
                                pessoa politicamente exposta</b></i>.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-left: 22px;">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="pessoaExposta"
                            name="pessoaExposta" id="pessoaexposta1" value="N">
                        <label class="form-check-label" for="pessoaexposta1">
                            <b>NÃO</b> me enquadro na condição de <b>PESSOA POLITICAMENTE EXPOSTA</b>.
                        </label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-left: 22px;">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="pessoaExposta"
                            name="pessoaExposta" id="pessoaexposta2" value="S">
                        <label class="form-check-label" for="pessoaexposta2">
                            Me enquadro na condição de <b>PESSOA POLITICAMENTE EXPOSTA</b>.
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="form-group">
                        <label class="contro-label">Local</label>
                        <input name="local" id="local" formControlName="local" type="text" class="form-control"
                            maxlength="200" placeholder="Local"
                            [ngClass]="validadorService.mostrarCSSErro('local', formGroup)" />
                        <div class="invalid-feedback">
                            Campo obrigatório!
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="text-align: right;">
                    <button type="button" class="btn btn-primary" (click)="addDependente(null)">
                        Adicionar Dependente
                    </button>
                </div>
            </div>



            <div class="row" style="margin-top: 20px;">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <p-table [value]="dependentes" styleClass="p-datatable-responsive-demo">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 30%;">
                                    Nome Dependente
                                </th>
                                <th style="width: 14%;">
                                    Data Nascimento
                                </th>
                                <th style="width: 10%;">
                                    Sexo
                                </th>
                                <th style="width: 12%;">
                                    Parentesco
                                </th>
                                <th style="width: 12%;">
                                    Estado Civil
                                </th>
                                <th style="width: 10%;">
                                    Inválido?
                                </th>
                                <th style="width: 10%;">
                                    Ações
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td style="width: 30%;">
                                    <span class="p-column-title">Nome Dependente</span>
                                    {{rowData['nome_Dependente']}}
                                </td>
                                <td style="width: 14%;">
                                    <span class="p-column-title">Data Nascimento</span>
                                    {{rowData['data_Nascimento'] | date: 'dd/MM/yyyy'}}
                                </td>
                                <td style="width: 10%;">
                                    <span class="p-column-title">Sexo</span>
                                    <span class="badge badge-primary"> {{rowData['sexo_Dependente']}}</span>
                                </td>
                                <td style="width: 12%;">
                                    <span class="p-column-title">Parentesco</span>
                                    <span class="badge badge-success">{{rowData['parentesco']}}</span>
                                </td>
                                <td style="width: 12%;">
                                    <span class="p-column-title">Estado Civil</span>
                                    <span class="badge badge-secondary">{{rowData['estado_Civil']}}</span>
                                </td>
                                <td style="width: 10%;">
                                    <span class="p-column-title">Inválido</span>
                                    <span class="badge badge-info">{{rowData['invalido']}}</span>
                                </td>
                                <td style="width: 10%;">
                                    <span class="p-column-title">Ações</span>
                                    <button type="button" class="btn btn-outline-success btn-sm" data-toggle="modal"
                                        data-target="#exampleModal" (click)="addDependente(rowData)">
                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                    </button>
                                    <button type="button" class="btn btn-outline-danger btn-sm"
                                        (click)="excluir(rowData)">
                                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>


            <!-- <div class="row" style="margin-top: 20px;">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <p style="color: red;">* Após conferir seus dados e clicar
                        no botão "Salvar", você poderá gerar o formulário.</p>
                </div>
            </div> -->
            <div class="row" style="margin-top: 20px;" *ngIf="exibirMensagem">
                <div class="alert"
                    [ngClass]="{'alert-success': tipoMensagem === 'success', 'alert-danger':tipoMensagem === 'danger' }"
                    role="alert" style="width: 100%;">
                    {{mensagem}}
                </div>
            </div>
            <div class="row" style="margin-top: 20px;">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
                    <button class="btn btn-primary mr-2 mb-2 mb-md-0" type="button" [disabled]="inSpinner"
                        (click)="atualizar()" placement="top" ngbTooltip="Salvar" *ngIf="!atualizou">
                        <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf="inSpinner"></i>
                        <span class="fa fa-save" *ngIf="!inSpinner"></span>
                        {{inSpinner ? 'Aguarde...' : 'Salvar'}}
                    </button>
                    <button class="btn btn-info mr-2 mb-2 mb-md-0" type="button" [disabled]="inSpinner"
                        *ngIf="atualizou" (click)="pdf()" placement="top" ngbTooltip="Gerar Formulário em PDF"
                        style="margin-left: 10px;">
                        <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf="inSpinner"></i>
                        <span class="fa fa-file-pdf-o" *ngIf="!inSpinner"></span>
                        {{inSpinner ? 'Aguarde...' : 'Gerar Formulário em PDF'}}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>