<!-- Service grid -->
<div class="col-sm-12 col-md-4 col-xl-4 m-sm-b" *ngFor="let content of institucionaisTresColunas">
    <div class="info-card text-center">
       <div class="icon-wrap mb-3 {{content?.corIcone}}">
         <i class="{{content?.icone}} font-4x"></i>
       </div>
       <h5 class="mb-3 info-title">{{content?.titulo}}</h5>
       <p class="mb-4 font-small">{{content?.subTitulo}}</p>
       <a href="javascript:void(0)" (click)="redirecionar(content)" class="text-uppercase btn btn-outline-info btn-sm">
          SAIBA MAIS
       </a> 
    </div>
 </div>
 <div class="col-sm-12 col-md-6 col-xl-6 m-sm-b" style="margin-top: 20px;" *ngFor="let content of institucionaisDuasColunas">
    <div class="info-card text-center">
       <div class="icon-wrap mb-3 {{content?.corIcone}}">
         <i class="{{content?.icone}} font-4x"></i>
       </div>
       <h5 class="mb-3 info-title">{{content?.titulo}}</h5>
       <p class="mb-4 font-small">{{content?.subTitulo}}</p>
       <a href="javascript:void(0)" (click)="redirecionar(content)" class="text-uppercase btn btn-outline-info btn-sm">
          SAIBA MAIS
       </a> 
    </div>
 </div>
 <div class="col-sm-12 col-md-12 col-xl-12 m-sm-b" style="margin-top: 20px;" *ngFor="let content of institucionaisUmaColuna">
   <div class="info-card text-center">
      <div class="icon-wrap mb-3 {{content?.corIcone}}">
        <i class="{{content?.icone}} font-4x"></i>
      </div>
      <h5 class="mb-3 info-title">{{content?.titulo}}</h5>
      <p class="mb-4 font-small">{{content?.subTitulo}}</p>
      <a href="javascript:void(0)" (click)="redirecionar(content)" class="text-uppercase btn btn-outline-info btn-sm">
         SAIBA MAIS
      </a> 
   </div>
</div>