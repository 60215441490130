<router-outlet></router-outlet>
<p-dialog [(visible)]="display" [modal]="true" styleClass="avisosite">
    <a style="cursor: pointer;" (click)="abrir(aviso?.link)" *ngIf="aviso !== undefined && aviso?.link !== null">
        <button type="button" (click)="$event.stopPropagation(); fechar()" class="mfp-close" style="color: black;border:solid 1px #000000">×</button>
        <img [src]="aviso?.imagem" alt="aviso site derminas" class="mydialog" />
    </a>

    <a (click)="$event.stopPropagation(); fechar()" *ngIf="aviso !== undefined && aviso?.link === null">
        <button type="button" (click)="$event.stopPropagation(); fechar()" class="mfp-close" style="color: black;border:solid 1px #000000">×</button>
        <img [src]="aviso?.imagem" alt="aviso site derminas" class="mydialog" />
    </a>
</p-dialog>