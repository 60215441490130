import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-canal-denuncia-timeline',
  templateUrl: './canal-denuncia-timeline.component.html',
  styleUrls: ['./canal-denuncia-timeline.component.css']
})
export class CanalDenunciaTimelineComponent implements OnInit {
  @Input() timeEntryHeader: string;
  @Input() timeEntryContent: string;
  @Input() timeEntryPlace: string;

  constructor() { }

  ngOnInit(): void {
  }

}
