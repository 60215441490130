import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PesquisaSatisfacaoSiteComponent } from 'app/pesquisa-satisfacao-site/pesquisa-satisfacao-site.component';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { AcessoRestritoSiteService } from 'app/services/acesso-restrito.service';
import { DownloadService } from 'app/services/download.service';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ValidadorService } from 'app/services/validador.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { MinhaAreaSiteComponent } from 'app/minha-area-site/minha-area-site.component';

@Component({
  selector: 'app-acesso-restrito-relatorios',
  templateUrl: './acesso-restrito-relatorios.component.html',
  styleUrls: ['./acesso-restrito-relatorios.component.css']
})
export class AcessoRestritoRelatoriosComponent implements OnInit, OnDestroy {
  @ViewChild(MinhaAreaSiteComponent) principal: MinhaAreaSiteComponent;
  idPensionista = 0;
  nomeUsuario = '';
  retornou = false;
  cpf: string;
  idSelecionado = 0;
  tipoReport = 'R';
  arquivos: any[] = [];

  reports: any[] = [];

  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    public validadorService: ValidadorService,
    public downloadService: DownloadService,
    public servico: SiteService,
    private modalService: NgbModal,
    public acessoRestritoSiteService: AcessoRestritoSiteService,
    public router: Router,
    public mensagensService: MensagensService
  ) {
    this.pageTitleService.setTitle('ContraCheques');
    /* Page subTitle */
    this.pageTitleService.setSubTitle('');
    const usuario = JSON.parse(localStorage.getItem('localUsuarioDerminas'));
    if (!!usuario) {
      this.cpf = usuario.cpf;
      this.nomeUsuario = usuario.nomeUsuario;
      this.idPensionista = usuario.idPensionista;

    } else {
      this.router.navigate(['acesso-restrito']);
    }
  }

  ngOnInit(): void {

    this.acessoRestritoSiteService.menusRelatorioSite()
      .pipe(finalize(() => {
      }))
      .subscribe(result => {
        this.reports = result;
      },
        err => {
          this.reports = [
            {
              name: 'Política de investimentos',
              border_color: 'border-primary',
              id: 13
            },
            {
              name: 'Informações contábeis',
              border_color: 'border-primary',
              id: 22
            },
            {
              name: 'Demonstrativo de investimentos',
              border_color: 'border-primary',
              id: 23
            },
            {
              name: 'Demonstração atuarial',
              border_color: 'border-primary',
              id: 28
            },
            {
              name: 'RDI - Resolução CNPC 32/2019',
              border_color: 'border-primary',
              id: 999
            }
          ];
        });
  }

  ngOnDestroy() {
  }

  selecionar(idConteudo: number) {
    this.idSelecionado = idConteudo;
    if (idConteudo === 999) {
      this.tipoReport = 'X';
    } else {
      this.tipoReport = 'R';
      this.acessoRestritoSiteService.obterRelatoriosSite(idConteudo).
        subscribe(response => {
          this.arquivos = response;
        },
          err => console.log(err),
          () => this.arquivos
        );
    }
  }

  abrir(anexo: any) {
    window.open(anexo.link, '_blank');
  }

  sair() {
    localStorage.removeItem('localUsuarioDerminas');
    localStorage.removeItem('pesquisa');
    this.router.navigate(['acesso-restrito']);
  }

  voltar() {
    this.principal.voltando();
  }
}
