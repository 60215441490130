<div id="toporecadastramento" class="contact-widget">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="alert alert-warning" role="alert" *ngIf="!temQueAtualizar">
                    <p> Gentileza conferir seus dados, alterando as informações incorretas ou desatualizadas quando for
                        o caso.
                        Clique em "<b>SALVAR</b>" ao final desta página para enviar automaticamente a atualização.</p>
                    <p> Se você estiver com pagamento suspenso por falta de <b>RECADASTRAMENTO</b> ou estiver no seu mês
                        de
                        aniversário
                        e ainda não tiver feito o <b>RECADASTRAMENTO ANUAL</b> obrigatório, você poderá usar o
                        formulário
                        gerado ao final,
                        após clicar em "SALVAR" e a mensagem "Dados atualizados com sucesso!" ser exibida.</p>
                    <p> Gere o formulário e siga as orientações descritas na segunda página deste formulário.
                    </p>
                    <!-- <p> O <b>RECADASTRAMENTO ANUAL</b> obrigatório, por ser prova de vida, somente é aceito original e
                        IMPRESSO,
                        não sendo válido o envio dos seus dados ONLINE nesse caso.</p> -->

                    <p><i>Em observância à LGPD (Lei Geral de Proteção de Dados), informamos que os dados aqui coletados
                            têm por finalidade o cumprimento de obrigações legais e regulatórias por parte da DERMINAS e
                            que a
                            entidade os preservará em sigilo. A eventual utilização e tratamento dos dados pessoais
                            somente ocorrerá
                            quando estritamente necessária para cumprimento dos propósitos previdenciários assegurados e
                            das obrigações relacionadas,
                            sempre observadas as regras e o princípios descritos na LGPD. Caso tenha dúvidas entre em
                            contato pelo e-mail
                            <u>derminas@derminas.org.br</u> </i></p>
                </div>
                <div class="alert alert-warning" role="alert" *ngIf="temQueAtualizar">
                    <p>Esse é seu primeiro acesso, valide seus dados para continuar.</p>
                    <p><i>Em observância à LGPD (Lei Geral de Proteção de Dados), informamos que os dados aqui coletados
                            têm por finalidade o cumprimento de obrigações legais e regulatórias por parte da DERMINAS e
                            que a
                            entidade os preservará em sigilo. A eventual utilização e tratamento dos dados pessoais
                            somente ocorrerá
                            quando estritamente necessária para cumprimento dos propósitos previdenciários assegurados e
                            das obrigações relacionadas,
                            sempre observadas as regras e o princípios descritos na LGPD. Caso tenha dúvidas entre em
                            contato pelo e-mail
                            <u>derminas@derminas.org.br</u> </i></p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="alert alert-light" role="alert">
                    <p style="color: #b71c1c;"> Os campos com informações cadastrais tais como nome, data de nascimento,
                        filiação,
                        CPF,
                        identidade, não podem ser alterados pelo usuário. Gentileza entrar em contato através do e-mail
                        <u>site@derminas.org.br</u> e solicitar a atualização.
                    </p>
                </div>
            </div>
        </div>
        <form [formGroup]="formGroup" style="width: 100%;">
            <div class="row">
                <input type="hidden" formControlName="matriculaHd">
                <input type="hidden" formControlName="maspHd">
                <div class="col-xl-8">
                    <div class="form-group">
                        <label class="contro-label required">Nome</label>
                        <input name="nome" id="nome" formControlName="nomePensionista" class="form-control"
                            maxlength="500" placeholder="Nome do Pensionista" />
                        <div class="invalid-feedback">
                            Campo obrigatório!
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="contro-label required">Matrícula</label>
                        <input name="matricula" id="matricula" formControlName="matricula" class="form-control"
                            maxlength="50" placeholder="Matrícula" />
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="contro-label required">MASP</label>
                        <input name="masp" id="masp" formControlName="masp" class="form-control" maxlength="50"
                            placeholder="MASP" />
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Data Nascimento</label>
                        <div class="input-group" (click)="d.toggle()">
                            <input class="form-control" placeholder="dd/mm/aaaa" name="dp"
                                formControlName="datNascimento" ngbDatepicker #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-light btn-icon calendar" type="button">
                                    <i class="fa fa-calendar text-secondary"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">RG</label>
                        <input name="carteiraIdentidade" id="carteiraIdentidade" formControlName="carteiraIdentidade"
                            class="form-control" maxlength="20" placeholder="Carteira Identidade" />
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Órgão Emissor </label>
                        <input name="orgaoEmissor" id="orgaoEmissor" formControlName="orgaoEmissor" class="form-control"
                            maxlength="50" placeholder="Órgão Emissor" />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">CPF</label>
                        <input name="cpf" id="cpf" formControlName="cpf" class="form-control" maxlength="20"
                            mask="000.000.000-00" placeholder="CPF" />
                    </div>
                </div>
                <div class="col-xl-8">
                    <div class="form-group">
                        <label class="contro-label">Estado Civil</label>
                        <select id="estadoCivil" formControlName="estadoCivil" class="form-control"
                            style="color: #000000;"
                            [ngClass]="validadorService.mostrarCSSErro('estadoCivil', formGroup)">
                            <option *ngFor="let item of estadosCivil" [value]="item.value">
                                {{item.displayName}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="contro-label">Nome da Mãe </label>
                        <input name="nomeMae" id="nomeMae" formControlName="nomeMae" class="form-control"
                            maxlength="500" placeholder="Nome da Mãe" />
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="contro-label">Nome do Pai </label>
                        <input name="nomePai" id="nomePai" formControlName="nomePai" class="form-control"
                            maxlength="500" placeholder="Nome do Pai" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="contro-label">CEP </label>
                        <input name="cep" id="cep" formControlName="cep" class="form-control" maxlength="10"
                            mask="00.000-000" placeholder="CEP"
                            [ngClass]="validadorService.mostrarCSSErro('cep', formGroup)" />
                        <div class="invalid-feedback">
                            Campo obrigatório!
                        </div>
                    </div>
                </div>
                <div class="col-xl-7">
                    <div class="form-group">
                        <label class="contro-label">Logradouro </label>
                        <input name="logradouro" id="logradouro" formControlName="logradouro" class="form-control"
                            maxlength="500" placeholder="Logradouro"
                            [ngClass]="validadorService.mostrarCSSErro('logradouro', formGroup)" />
                        <div class="invalid-feedback">
                            Campo obrigatório!
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="contro-label">Número </label>
                        <input name="numero" id="numero" formControlName="numLogradouro" class="form-control"
                            maxlength="20" placeholder="Número" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Bairro</label>
                        <input name="bairro" id="bairro" formControlName="bairro" class="form-control" maxlength="200"
                            placeholder="Bairro" [ngClass]="validadorService.mostrarCSSErro('bairro', formGroup)" />
                        <div class="invalid-feedback">
                            Campo obrigatório!
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Cidade</label>
                        <input name="cidade" id="cidade" formControlName="cidade" class="form-control" maxlength="200"
                            placeholder="Cidade" [ngClass]="validadorService.mostrarCSSErro('cidade', formGroup)" />
                        <div class="invalid-feedback">
                            Campo obrigatório!
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Estado</label>
                        <select id="estado" formControlName="estado" class="form-control" style="color: #000000;"
                            [ngClass]="validadorService.mostrarCSSErro('estado', formGroup)"
                            [ngClass]="validadorService.mostrarCSSErro('estado', formGroup)">
                            <option *ngFor="let item of estados" [value]="item.value">
                                {{item.displayName}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="form-group">
                        <label class="contro-label">Complemento</label>
                        <input name="cidade" id="complemento" formControlName="complemento" class="form-control"
                            maxlength="4000" placeholder="Complemento" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Telefone Residencial</label>
                        <input name="telefone1" id="telefone1" #inputResidencial formControlName="numeroTelefone" class="form-control"
                            ngxPhoneMaskBr placeholder="Telefone" />
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Telefone Celular</label>
                        <input name="telefone2" id="numeroCelular" #inputCelular formControlName="numeroCelular" class="form-control"
                            style="background-color: #e1f5fe;" appDerminasPhoneMaskBr
                            placeholder="Telefone Celular" />
                        <div class="invalid-feedback">
                            Campo obrigatório ou inválido!
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="contro-label">Telefone Comercial</label>
                        <input name="telefone3" id="telefone3" #inputComercial formControlName="numeroTelefone3" class="form-control"
                            ngxPhoneMaskBr placeholder="Telefone Comercial" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="form-group">
                        <label class="contro-label">E-mail</label>
                        <input name="email" id="email" formControlName="email" type="email" class="form-control"
                            style="background-color: #e1f5fe;" maxlength="200" placeholder="E-mail" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-9">
                    <div class="form-group">
                        <label class="contro-label">Local</label>
                        <input name="local" id="local" formControlName="local" type="text" class="form-control"
                            maxlength="200" placeholder="Local"
                            [ngClass]="validadorService.mostrarCSSErro('local', formGroup)" />
                        <div class="invalid-feedback">
                            Campo obrigatório!
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="contro-label">Data</label>
                        <div class="input-group" (click)="d2.toggle()">
                            <input class="form-control" placeholder="dd/mm/aaaa" name="dp2" formControlName="dataLocal"
                                ngbDatepicker #d2="ngbDatepicker"
                                [ngClass]="validadorService.mostrarCSSErro('dataLocal', formGroup)">
                            <div class="input-group-append">
                                <button class="btn btn-outline-light btn-icon calendar" type="button">
                                    <i class="fa fa-calendar text-secondary"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12" style="margin-left: 22px;">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="pessoaExposta"
                            name="pessoaExposta" id="pessoaexposta1" value="N">
                        <label class="form-check-label" for="pessoaexposta1">
                            não exerceu, nos últimos 5 (cinco) anos, nenhum dos cargos ou funções a que se refere a
                            Instrução MPS/SPC nº 26, de 01/09/2008, e não tem conhecimento de que nenhum de seus
                            familiares, representantes ou pessoas de seu relacionamento próximo os tenha exercido, razão
                            pela qual <b>não se enquadra como pessoa politicamente exposta</b>.
                        </label>
                        <input class="form-check-input" type="radio" formControlName="pessoaExposta"
                            name="pessoaExposta" id="pessoaexposta2" value="S">
                        <label class="form-check-label" for="pessoaexposta2">
                            <b>é pessoa politicamente exposta</b>, uma vez que a sua situação enquadra-se no disposto na
                            Instrução MPS/SPC nº 26, de 01/09/2008.
                        </label>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 20px;">
                <div class="col-xl-12">
                    <p style="color: red;">* Após conferir seus dados e clicar no botão "Salvar", você poderá gerar o
                        formulário.</p>
                </div>
            </div>

            <div class="row" style="margin-top: 20px;" *ngIf="exibirMensagem">
                <div class="alert"
                    [ngClass]="{'alert-success': tipoMensagem === 'success', 'alert-danger':tipoMensagem === 'danger' }"
                    role="alert" style="width: 100%;">
                    {{mensagem}}
                </div>
            </div>

            <div class="row" style="margin-top: 20px; margin-bottom: 20px;">
                <div class="col-xl-12 text-right">
                    <button class="btn btn-primary mr-2 mb-2 mb-md-0" type="button" [disabled]="inSpinner"
                        (click)="atualizar()" placement="top" ngbTooltip="Salvar" *ngIf="!atualizou">
                        <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf="inSpinner"></i>
                        <span class="fa fa-save" *ngIf="!inSpinner"></span>
                        {{inSpinner ? 'Aguarde...' : 'Salvar'}}
                    </button>
                    <button class="btn btn-info mr-2 mb-2 mb-md-0" type="button" [disabled]="inSpinner"
                        *ngIf="atualizou" (click)="pdf()" placement="top" ngbTooltip="Gerar Formulário em PDF"
                        style="margin-left: 10px;">
                        <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf="inSpinner"></i>
                        <span class="fa fa-file-pdf-o" *ngIf="!inSpinner"></span>
                        {{inSpinner ? 'Aguarde...' : 'Gerar Formulário em PDF'}}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>