import { ResolucaoCnpcTransparenciaComponent } from './resolucoes/resolucao-cnpc-transparencia/resolucao-cnpc-transparencia.component';
import { ServioPesqusaService } from './services/servico.pesquisa.service';
import { EventosService } from './services/eventos.service';
import { NgbDateMomentParserFormatter } from './calendar-brasilian-config';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DirectivesModule } from './core/directive/directives.module';
import { AgmCoreModule } from '@agm/core';

import { NgxCaptchaModule } from 'ngx-captcha';

/* Routing */
import { AppRoutingModule } from './app-routing.module';

/* Service */
import { ChkService } from './service/chk.service';

/* components */
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { HomeComponent } from './home/home.component';
import { PricingComponent } from './pricing/pricing.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';

import { MenuItems } from './core/menu/menu-items/menu-items';
import { MenuToggleModule } from './core/menu-toggle.module';
import { PageTitleService } from './core/page-title/page-title.service';
import { WidgetsModule } from './widgets/widgets.module';
import { FeaturesComponent } from './features/features.component';
import { AboutComponent } from './about/about.component';
import { SearchComponent } from './search/search.component';
import { SupportComponent } from './support/support.component';
import { Footer2Component } from './footer2/footer2.component';
import { sidebarWidgetsComponent } from './sidebarWidgets/sidebarWidgets.component';

import { SiteService } from './services/site.service';
import { AcessoRestritoSiteService } from './services/acesso-restrito.service';
import { StringUtils } from './services/string-utils';
import { FiquePorDentroComponent } from './fique-por-dentro/fique-por-dentro.component';
import { InstitucionalDerminasComponent } from './institucional-derminas/institucional-derminas.component';
import { MaisInformacoesComponent } from './mais-informacoes/mais-informacoes.component';
import { ConteudoSiteComponent } from './conteudo-site/conteudo-site.component';
import { ContatoComponent } from './contato/contato.component';

import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import {RadioButtonModule} from 'primeng/radiobutton';
import { NgbModule, NgbDatepickerModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxPhoneMaskBrModule } from 'ngx-phone-mask-br';

// Ngx-mask
import { NgxMaskModule, IConfig } from 'ngx-mask';
// import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

import { NgxSpinnerModule } from 'ngx-spinner';
import { PdfViewerModule } from 'ng2-pdf-viewer';


import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt, 'pt-BR');

import { MensagensService } from './services/mensagens.service';
import { DownloadService } from './services/download.service';
import { ValidadorService } from './services/validador.service';
import { AuthGuard } from './services/auth.guard';
import { AcessoRestritoComponent } from './acesso-restrito/acesso-restrito.component';
import { MinhaAreaComponent } from './minha-area/minha-area.component';
import { ContraChequesComponent } from './contra-cheques/contra-cheques.component';
import { InformesComponent } from './informes/informes.component';
import { RecadastamentoComponent } from './recadastamento/recadastamento.component';
import { ExtratoAtasComponent } from './extrato-atas/extrato-atas.component';
import { SimulacaoEmprestimoComponent } from './simulacao-emprestimo/simulacao-emprestimo.component';
import { DocumentosSiteComponent } from './documentos-site/documentos-site.component';
import { AniversariantesDerminasComponent } from './aniversariantes-derminas/aniversariantes-derminas.component';
import { AniversariantesHomeComponent } from './aniversariantes-home/aniversariantes-home.component';
import { AvisosSiteComponent } from './avisos-site/avisos-site.component';
import { CadastroPensionistaComponent } from './cadastro-pensionista/cadastro-pensionista.component';
import { CadastroParticipantesComponent } from './cadastro-participantes/cadastro-participantes.component';
import { DependentesParticipanteComponent } from './dependentes-participante/dependentes-participante.component';
import { PrimeiroAcessoComponent } from './primeiro-acesso/primeiro-acesso.component';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { CookieLawModule } from 'angular2-cookie-law';
import { ResolucaoCnpcComponent } from './resolucoes/resolucao-cnpc/resolucao-cnpc.component';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha.component';
import { AcessoRestritoResetComponent } from './acesso-restrito-reset/acesso-restrito-reset.component';
import { ConsultaEmprestimoComponent } from './consulta-emprestimo/consulta-emprestimo.component';
import { NovaSenhaAcessoComponent } from './nova-senha-acesso/nova-senha-acesso.component';
import { PopoverModule } from 'ngx-smart-popover';
import { AcessoRestritoRelatoriosComponent } from './acesso-restrito-relatorios/acesso-restrito-relatorios.component';
import { PesquisaSatisfacaoComponent } from './pesquisa-satisfacao/pesquisa-satisfacao.component';
import { PesquisaSatisfacaoSiteComponent } from './pesquisa-satisfacao-site/pesquisa-satisfacao-site.component';
import { PesquisaSatisfacaoDerminasComponent } from './pesquisa-satisfacao-derminas/pesquisa-satisfacao-derminas.component';
import { PesquisaSatisfacaoAtendimentoComponent } from './pesquisa-satisfacao-atendimento/pesquisa-satisfacao-atendimento.component';
import { MinhaAreaSiteComponent } from './minha-area-site/minha-area-site.component';
import { PaginaLinksComponent } from './pagina-links/pagina-links.component';
import { LgpdComponent } from './lgpd/lgpd.component';
import { HomeDenunciaComponent } from './home-denuncia/home-denuncia.component';
import { CanalDenunciaComponent } from './canal-denuncia/canal-denuncia.component';
import { CanalDenunciaPesquisaComponent } from './canal-denuncia-pesquisa/canal-denuncia-pesquisa.component';
import { CanalDenunciaRegistroComponent } from './canal-denuncia-registro/canal-denuncia-registro.component';
import { CanalDenunciaProtocoloComponent } from './canal-denuncia-protocolo/canal-denuncia-protocolo.component';
import { CanalDenunciaTimelineComponent } from './canal-denuncia-timeline/canal-denuncia-timeline.component';
import { SubMenuRelatoriosComponent } from './sub-menu-relatorios/sub-menu-relatorios.component';
import { PortalEleicoesArquivosComponent } from './portal-eleicoes-arquivos/portal-eleicoes-arquivos.component';
import { OrganogramaComponent } from './organograma/organograma.component';
import { TransparenciaComponent } from './transparencia/transparencia.component';

@NgModule({
   declarations: [
      AppComponent,
      MainComponent,
      HomeComponent,
      PricingComponent,
      ContactComponent,
      FooterComponent,
      HeaderComponent,
      MenuComponent,
      FeaturesComponent,
      AboutComponent,
      SearchComponent,
      SupportComponent,
      Footer2Component,
      sidebarWidgetsComponent,
      FiquePorDentroComponent,
      InstitucionalDerminasComponent,
      MaisInformacoesComponent,
      ConteudoSiteComponent,
      ContatoComponent,
      AcessoRestritoComponent,
      MinhaAreaComponent,
      ContraChequesComponent,
      InformesComponent,
      RecadastamentoComponent,
      ExtratoAtasComponent,
      SimulacaoEmprestimoComponent,
      OrganogramaComponent,
      DocumentosSiteComponent,
      AniversariantesDerminasComponent,
      AniversariantesHomeComponent,
      AvisosSiteComponent,
      CadastroPensionistaComponent,
      CadastroParticipantesComponent,
      DependentesParticipanteComponent,
      PrimeiroAcessoComponent,
      ResolucaoCnpcComponent,
      ResolucaoCnpcTransparenciaComponent,
      AlterarSenhaComponent,
      AcessoRestritoResetComponent,
      ConsultaEmprestimoComponent,
      NovaSenhaAcessoComponent,
      AcessoRestritoRelatoriosComponent,
      PesquisaSatisfacaoComponent,
      PesquisaSatisfacaoSiteComponent,
      PesquisaSatisfacaoDerminasComponent,
      PesquisaSatisfacaoAtendimentoComponent,
      MinhaAreaSiteComponent,
      PaginaLinksComponent,
      LgpdComponent,
      TransparenciaComponent,
      HomeDenunciaComponent,
      CanalDenunciaComponent,
      CanalDenunciaPesquisaComponent,
      CanalDenunciaRegistroComponent,
      CanalDenunciaProtocoloComponent,
      CanalDenunciaTimelineComponent,
      SubMenuRelatoriosComponent,
      PortalEleicoesArquivosComponent
   ],
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      FormsModule,
      ReactiveFormsModule,
      HttpClientModule,
      AppRoutingModule,
      WidgetsModule,
      MenuToggleModule,
      DirectivesModule,
      CurrencyMaskModule,
      SlickCarouselModule,
      // CdkVirtualScrollViewport,
      NgbDatepickerModule,
      CalendarModule,
      CarouselModule,
      DialogModule,
      TableModule,
      ToastModule,
      PdfViewerModule,
      SliderModule,
      MultiSelectModule,
      ContextMenuModule,
      ButtonModule,
      DropdownModule,
      RadioButtonModule,
      PopoverModule,
      ProgressBarModule,
      InputTextModule,
      NgbModule,
      TooltipModule,
      NgxMaskModule.forRoot({ validation: true }), // Ngx-mask
      NgxCaptchaModule,
      CookieLawModule,
      PasswordStrengthMeterModule,
      NgxPhoneMaskBrModule,
      AgmCoreModule.forRoot({
         apiKey: 'AIzaSyD4y2luRxfM8Q8yKHSLdOOdNpkiilVhD9k'
      }),
      NgxSpinnerModule
   ],
   entryComponents: [AvisosSiteComponent, DependentesParticipanteComponent, PesquisaSatisfacaoSiteComponent],
   providers: [
      { provide: LOCALE_ID, useValue: 'pt-BR' },
      MenuItems,
      PageTitleService,
      ChkService,
      SiteService,
      AcessoRestritoSiteService,
      StringUtils,
      MensagensService,
      ValidadorService,
      ServioPesqusaService,
      AuthGuard,
      EventosService,
      DownloadService,
      {
         provide: NgbDateParserFormatter,
         useClass: NgbDateMomentParserFormatter
      },
   ],
   bootstrap: [AppComponent]
})
export class AppModule { }
