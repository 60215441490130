<div class="session-wrapper">
    <div class="container container-full-width">
        <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
            <p style="font-size: 18px; color: white">Aguarde...</p>
        </ngx-spinner>
        <div class="row" *ngIf="sucessoReplica">
            <div class="col-sm-12 col-md-10 col-lg-8 mx-auto">
                <div class="container container-full-width">
                    <div class="row">
                        <div class="col-sm-12 col-md-10 col-lg-8 mx-auto">
                            <div class="thankyou-wrap">
                                <div class="card text-white border-rad shadow-md border-0">
                                    <img class="card-img img-fluid" src="assets/img/thankyou.png" alt="Card image"
                                        width="600" height="300" />
                                    <div class="card-img-overlay border-rad">
                                        <div class="text-center">
                                            <h2 class="ty-card-title fw-200">Muito Obrigado!</h2>
                                        </div>
                                    </div>
                                </div>
                                <div style="margin-top: -16px!important;"
                                    class="card border-0 border-rad card-shadow p-4 text-center w-80 mx-auto card-hover">
                                    <p class="mb-4">{{textoFinal}}</p>
                                    <div class="social-without-bg mb-4">
                                        <ul class="list-inline mb-0">
                                            <li class="list-inline-item"><a
                                                    (click)="abrirLink('https://www.facebook.com/derminasseguridade')"
                                                    style="cursor: pointer;"><i class="fa fa-facebook"
                                                        target="_blank"></i></a></li>
                                            <li class="list-inline-item"><a
                                                    (click)="abrirLink('https://x.com/derminas1')"
                                                    style="cursor: pointer;"><i class="fa fa-twitter"
                                                        target="_blank"></i></a></li>
                                        </ul>
                                    </div>
                                    <a routerLink="/home" class="btn btn-primary btn-block submit-btn btn-lg">Ir para o
                                        site</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!sucessoReplica">
            <div class="col-sm-12 col-md-10 col-lg-8 mx-auto">
                <form [formGroup]="protocoloForm" (ngSubmit)="pesquisar()" class="send-mail d-block">
                    <div class="form-row justify-content-center">
                        <div class="form-group col-sm-12 col-md-9 col-lg-9">
                            <input type="text" class="form-control w-100" placeholder="Acompanhar andamento..."
                                aria-label="Acompanhar andamento..." formControlName="protocolo" />
                        </div>
                        <div class="form-group col-sm-12 col-md-3 col-lg-3">
                            <button type="submit" class="btn btn-dark btn-chk-dark inverse">
                                <span class="fa fa-share"></span>
                                Pesquisar Ocorrência
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="col-sm-12 col-md-10 col-lg-8 mx-auto" *ngIf="mensagemNaoEncontrado.length > 0">
                <div class="alert alert-warning" role="alert" style="width: 110%;">
                    <span>{{mensagemNaoEncontrado}}</span>
                </div>
            </div>

            <div class="col-sm-12 col-md-10 col-lg-8 mx-auto" *ngIf="sucesso">
                <div class="alert alert-info" role="alert" style="width: 110%;">
                    <span [innerHTML]="getTextoLabel()"></span>
                </div>
            </div>


            <div class="col-sm-12 col-md-10 col-lg-8 mx-auto"
                *ngIf="sucesso && !emreplica && (protocolo?.situacao !== 'F' && protocolo?.situacao !== 'X')">
                <div class="row" style="margin-top: 50px; margin-bottom: 50px;">
                    <div class="col-12" style="text-align: center; width: 100%;">
                        <button type="button" class="btn btn-secondary" style="margin-right: 20px;"
                            (click)="replica()">Responder</button>
                        <button type="button" class="btn btn-primary" (click)="finalizar()">Finalizar Protocolo</button>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-10 col-lg-8 mx-auto" *ngIf="emreplica">
                <form [formGroup]="formGroupReplica">
                    <div class="form-group mb-4" *ngIf="erromessageReplica.length > 0">
                        <div class="alert alert-danger" role="alert">
                            {{erromessageReplica}}
                        </div>
                    </div>
                    <div class="form-group mb-4">
                        <label class="contro-label required">Denúncia</label>
                        <textarea id="texto" class="form-control" rows="5" placeholder="Denúncia" maxlength="4000"
                            formControlName="texto"
                            [ngClass]="validadorService.mostrarCSSErro('texto', formGroupReplica)" required></textarea>
                        <div class="invalid-feedback">
                            Campo obrigatório!
                        </div>
                    </div>
                    <div class="form-group" style="text-align: right;">
                        <button type="button" class="btn btn-dark btn-chk-dark inverse" (click)="sendReplica()">
                            <span class="fa fa-share"></span>
                            Registrar Réplica
                        </button>
                    </div>
                </form>
            </div>


            <div class="col-12" style="background-color: #ced4d7;" *ngIf="sucesso">
                <div class="timeline">
                    <app-canal-denuncia-timeline *ngFor='let entry of protocolo?.timeline'
                        timeEntryHeader={{entry.year}} timeEntryContent={{entry.detail}} timeEntryPlace={{entry.place}}>
                    </app-canal-denuncia-timeline>
                </div>
            </div>
        </div>
    </div>
</div>