import { Injectable } from '@angular/core';

@Injectable()
export class StringUtils {
    public isNullOrEmpty(val: string): boolean {
      if (val === undefined || val === null || val.trim() === '') {
        return true;
      }
      return false;
    }

    public isNullOrUndefined(val: any): boolean {
      if (val === undefined || val === null) {
        return true;
      }
      return false;
    }
}
