<div class="blog-detail section-gap pt-5">
    <div class="container">
        <div class="section-gap-mini"></div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{area}}</li>
                        <li class="breadcrumb-item active" aria-current="page">{{subarea}}</li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="row" *ngIf="conteudo?.titulo" style="margin-bottom: 25px;">
            <div class="section-heading section-gap bg-light" style="width: 100%;">
                <div class="text-center">
                    <div class="container">
                        <h1 class="mb-3">{{conteudo?.titulo}}</h1>
                        <p class="lead">{{conteudo?.subTitulo}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="section-heading" *ngIf="!conteudo?.titulo" style="width: 100%;"></div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                <div class="thumb-content mb-5 blog-content-p">
                    <div [innerHTML]="conteudo?.conteudo"></div>
                </div>
            </div>
        </div>
        <!-- <div class="border-bottom-dash" *ngIf="anexos.length > 0" style="margin-bottom: 20px;"></div> -->
        <!-- <div class="row mb-4" *ngIf="anexos.length > 0">
            <h4 class="sidebar-title mb-3" style="margin-left: 15px;">Arquivos Associados</h4>
        </div> -->
        <div class="row" *ngIf="anexos.length > 0">


            <div class="col-sm-12 col-md-12 col-lg-12" style="text-align: center;">
                <div class="table-responsive user-list">
                    <table class="table" style="width: 100%;">
                        <thead>
                            <tr>
                                <th scope="col" style="width: 70%;">Descrição</th>
                                <th scope="col" style="width: 20%;">Data</th>
                                <th scope="col" style="width: 10%; text-align: center;">Baixar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of anexos">
                                <td class="col-md" style="width: 70%; text-align: left;">
                                    {{item?.titulo}}
                                </td>
                                <td class="col-md" style="width: 20%; text-align: left;">
                                    {{item?.dataInclusao | date: 'dd/MM/yyyy'}}
                                </td>
                                <td class="col-md" style="width: 10%; text-align: center;">
                                    <button type="button" class="btn btn-outline-primary btn-icon btn-sm"
                                        placement="top" style="margin-right: 5px;" ngbTooltip="Baixar Arquivo"
                                        (click)="abrir(item)">
                                        <i class="fa fa-file-pdf-o"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!--    <div class="col-sm-12 col-md-6 col-lg-4 mb-3" *ngFor="let item of anexos">
                 <div class="media">
                    <img src="assets/imagens/pdf.png" *ngIf="item.tipoAnexo === 'pdf'" style="cursor: pointer;"
                        (click)="abrir(item)" class="d-flex mr-3" width="100" height="131" />
                    <img src="assets/imagens/doc.png" style="cursor: pointer;"
                        *ngIf="item.tipoAnexo === 'doc' || item.tipoAnexo === 'docx'" (click)="abrir(item)"
                        class="d-flex mr-3" width="100" height="131" />
                    <img src="assets/imagens/excel.jpg" style="cursor: pointer;"
                        *ngIf="item.tipoAnexo === 'xls' || item.tipoAnexo === 'xlsx'" (click)="abrir(item)"
                        class="d-flex mr-3" width="100" height="131" />
                    <div class="media-body">
                        <h6 class="fw-700"><a style="cursor: pointer;" (click)="abrir(item)">{{item?.titulo}}</a>
                        </h6>
                        <div class="blog-meta">
                            <a style="cursor: pointer;" (click)="abrir(item)">
                                <i class="fa fa-calendar-o mr-2 text-muted font-lg"></i>
                                <span class="text-muted font-sm">{{item?.dataInclusao | date: 'dd/MM/yyyy'
                                    }}</span>
                            </a>
                        </div>
                    </div>
                </div> 
            </div>-->
        </div>
        <div class="border-bottom-dash" *ngIf="imagens.length > 0" style="margin-bottom: 20px;"></div>
        <div class="row" *ngIf="imagens.length > 0">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3" *ngFor="let item of imagens" style="text-align: center;">
                <div class="container-da-imagem">
                    <img alt="Imagem" [src]="item.link" (click)="redirecionarImagem(item)"
                        style="cursor: pointer;" />
                </div>
            </div>
        </div>
    </div>
</div>