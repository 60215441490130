<div class="section-gap-medium"></div>
<div class="contact-widget section-gap-mini">
    <div class="container">
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
                        <li class="breadcrumb-item"><a routerLink="/minha-area">Minha Área</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Alterar Minha Senha</li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <p>Seja bem vindo(a) <b>{{nomeUsuario}}</b></p>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 text-right">
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="voltar()">
                    <i class="fa fa-arrow-left" aria-hidden="true"></i> Voltar
                </button>
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="sair()"
                    style="cursor: pointer; margin-left: 10px;" ngbTooltip="Sair"><i class="fa fa-sign-out"></i>
                    Sair</button>
            </div>
        </div>
        <div class="row">

            <div class="col-sm-12 col-md-11 col-lg-9 mx-auto">
                <div class="row no-gutters">
                    <div class="col-sm-12 col-md-3 d-xs-none">
                        &nbsp; &nbsp;
                    </div>
                    <div class="col-sm-12 col-md-6 py-5">
                        <div class="card border-0 p-4 rounded-0 card-shadow">
                            <div class="form-group mb-4" *ngIf="erromessage.length > 0">
                                <div class="alert alert-danger" role="alert">
                                    {{erromessage}}
                                </div>
                            </div>
                            <form class="session-form" [formGroup]="formGroup">
                                <div class="mb-5">
                                    <div class="form-group">
                                        <label for="InputCpf">CPF</label>
                                        <input type="text" class="form-control form-control-lg" id="InputCpf"
                                            appAutocompleteOff formControlName="cpf" mask="000.000.000-00"
                                            [ngClass]="validadorService.mostrarCSSErro('cpf', formGroup)" required>
                                    </div>
                                    <div class="form-group">
                                        <label for="senha">Senha Atual</label>
                                        <input type="password" class="form-control form-control-lg" id="senha"
                                            appAutocompleteOff formControlName="senha" (keyup.enter)="alterar()"
                                            maxlength="10"
                                            [ngClass]="validadorService.mostrarCSSErro('senha', formGroup)" required>
                                    </div>
                                    <div class="form-group">
                                        <label for="novaSenha">Nova Senha</label>
                                        <input type="password" class="form-control form-control-lg" id="novaSenha"
                                            appAutocompleteOff #password2 formControlName="novaSenha" maxlength="10"
                                            (keyup.enter)="alterar()" [popover]="myPopover" minlength="6"
                                            [ngClass]="validadorService.mostrarCSSErro('novaSenha', formGroup)"
                                            required>
                                        <password-strength-meter [password]="password2.value"></password-strength-meter>
                                        <popover-content #myPopover title="Instruções!" [closeOnClickOutside]="true">
                                            A senha deve ter no <b>mínimo 6 e no máximo 10</b> caracteres,
                                            podendo conter <b>números, letras e símbolos</b>.
                                        </popover-content>
                                        <small class="text-muted"
                                            *ngIf="formGroup.get('novaSenha').hasError('minlength')"><span
                                                style="color: red;">A nova senha deve
                                                conter pelo
                                                menos 6 caracteres.</span></small>
                                    </div>
                                    <div class="form-group">
                                        <label for="repetirNovaSenha">Confirme Nova Senha</label>
                                        <input type="password" class="form-control form-control-lg" maxlength="10"
                                            minlength="6" id="repetirNovaSenha" formControlName="repetirNovaSenha"
                                            appAutocompleteOff (keyup.enter)="alterar()" [popover]="myPopover2"
                                            [ngClass]="validadorService.mostrarCSSErro('repetirNovaSenha', formGroup)"
                                            required>
                                        <popover-content #myPopover2 title="Instruções!" [closeOnClickOutside]="true">
                                            A senha deve ter no <b>mínimo 6 e no máximo 10</b> caracteres,
                                            podendo conter <b>números, letras e símbolos</b>.
                                        </popover-content>
                                        <small class="text-muted"
                                            *ngIf="formGroup.get('repetirNovaSenha').hasError('minlength')"><span
                                                style="color: red;">O Confirme
                                                nova senha deve
                                                conter pelo
                                                menos 6 caracteres.</span></small>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="button" (click)="alterar()" [disabled]="inSpinner"
                                        class="btn btn-primary btn-block btn-lg submit-btn">
                                        <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf="inSpinner"></i>
                                        {{inSpinner ? ' Validando...' : ' Salvar'}}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3 d-xs-none">
                        &nbsp; &nbsp;
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>