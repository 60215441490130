<div>
    <div id="topominhaarea" class="section-gap-medium"></div>
    <div class="contact-widget section-gap-mini">
        <div class="container">
            <div class="row" style="margin-bottom: 15px;">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
                            <li class="breadcrumb-item"><a routerLink="/minha-area">Minha Área</a></li>
                            <li class="breadcrumb-item active" aria-current="page" *ngIf="componente > 1">
                                {{retornararea()}}</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="row" *ngIf="mesAniversario">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="alert alert-warning" role="alert">
                        <p><b>Prezado Pensionista,</b></p>
                        <p>Estamos no mês de seu aniversário e o <b>RECADASTRAMENTO ANUAL</b> é obrigatório.</p>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-bottom: 25px;">
                <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                    <p>Seja bem vindo(a) <b>{{nomeUsuario}}</b></p>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4" style="text-align: right;">
                    <button type="button" class="btn btn-outline-primary btn-sm" (click)="voltando()"
                        *ngIf="componente > 1">
                        <i class="fa fa-arrow-left" aria-hidden="true"></i> Voltar
                    </button>
                    <button type="button" class="btn btn-outline-success btn-sm" (click)="alterarSenha()"
                        style="cursor: pointer;" ngbTooltip="Alterar Minha Senha"><i class="fa fa-unlock-alt"></i>
                        Alterar
                        Minha Senha</button>
                    <button type="button" class="btn btn-outline-primary btn-sm" (click)="sair()"
                        style="cursor: pointer;" ngbTooltip="Sair"><i class="fa fa-sign-out"></i> Sair</button>
                </div>
            </div>
        </div>
    </div>
    <div class="contact-info" *ngIf="componente === 1">
        <div class="container">

            <div class="row" *ngIf="tipoUsuario === 'A' || tipoUsuario === 'X'">
                <div class="col-md-4 col-sm-12 mb-4">
                    <div class="overlay-wrap">
                        <img src="assets/derminas/restrito-recadastramento.png" width="367" height="367"
                            class="img-fluid w-100" alt="gallery images" />
                        <a style="cursor: pointer;" (click)="componente = 2"
                            class="card-img-overlay primary-tp-layer pos-center text-center">
                            <span class="center-holder">
                                <a class="ih-fade-down square-40 rounded-circle bg-white shadow-md">
                                    <i class="fa fa-arrow-right align-middle"></i>
                                </a>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12 mb-4">
                    <div class="overlay-wrap">
                        <img src="assets/derminas/restrito-contra-cheque.png" width="367" height="367"
                            class="img-fluid w-100" alt="gallery images" />
                        <a style="cursor: pointer;" (click)="componente = 3"
                            class="card-img-overlay primary-tp-layer pos-center text-center">
                            <span class="center-holder">
                                <a class="ih-fade-down square-40 rounded-circle bg-white shadow-md">
                                    <i class="fa fa-arrow-right align-middle"></i>
                                </a>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12 mb-4">
                    <div class="overlay-wrap">
                        <img src="assets/derminas/restrito-informes.png" width="367" height="367"
                            class="img-fluid w-100" alt="gallery images" />
                        <a style="cursor: pointer;" (click)="componente = 4"
                            class="card-img-overlay primary-tp-layer pos-center text-center">
                            <span class="center-holder">
                                <a class="ih-fade-down square-40 rounded-circle bg-white shadow-md">
                                    <i class="fa fa-arrow-right align-middle"></i>
                                </a>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
              
               <div class="col-md-4 col-sm-12 mb-4">
                    <div class="overlay-wrap">
                        <img src="assets/derminas/eleicoes.png" width="367" height="367" class="img-fluid w-100"
                            alt="gallery images" />
                        <a style="cursor: pointer;" (click)="componente = 9"
                            class="card-img-overlay primary-tp-layer pos-center text-center">
                            <span class="center-holder">
                                <a class="ih-fade-down square-40 rounded-circle bg-white shadow-md">
                                    <i class="fa fa-arrow-right align-middle"></i>
                                </a>
                            </span>
                        </a>
                    </div>
                </div>
            </div> -->
            <div class="row">
                <div class="col-md-4 col-sm-12 mb-4">
                    <div class="overlay-wrap">
                        <img src="assets/derminas/informacoes.png" width="367" height="367" class="img-fluid w-100"
                            alt="gallery images" />
                        <a style="cursor: pointer;" (click)="componente = 10"
                            class="card-img-overlay primary-tp-layer pos-center text-center">
                            <span class="center-holder">
                                <a class="ih-fade-down square-40 rounded-circle bg-white shadow-md">
                                    <i class="fa fa-arrow-right align-middle"></i>
                                </a>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12 mb-4" *ngIf="tipoUsuario === 'P' || tipoUsuario === 'X'">
                    <div class="overlay-wrap">
                        <img src="assets/derminas/restrito-meus-dados.png" width="367" height="367"
                            class="img-fluid w-100" alt="gallery images" />
                        <a style="cursor: pointer;" (click)="componente = 7"
                            class="card-img-overlay primary-tp-layer pos-center text-center">
                            <span class="center-holder">
                                <a class="ih-fade-down square-40 rounded-circle bg-white shadow-md">
                                    <i class="fa fa-arrow-right align-middle"></i>
                                </a>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12 mb-4" *ngIf="exibirEmprestimo">
                    <div class="overlay-wrap">
                        <img src="assets/derminas/meu-emprestimo.png" width="367" height="367" class="img-fluid w-100"
                            alt="gallery images" />
                        <a style="cursor: pointer;" (click)="componente = 8"
                            class="card-img-overlay primary-tp-layer pos-center text-center">
                            <span class="center-holder">
                                <a class="ih-fade-down square-40 rounded-circle bg-white shadow-md">
                                    <i class="fa fa-arrow-right align-middle"></i>
                                </a>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <app-recadastamento *ngIf="componente === 2"></app-recadastamento>
    <app-contra-cheques *ngIf="componente === 3"></app-contra-cheques>
    <app-informes *ngIf="componente === 4"></app-informes>
    <!-- <app-extrato-atas *ngIf="componente === 5"></app-extrato-atas> -->
    <!-- <app-resolucao-cnpc *ngIf="componente === 6"></app-resolucao-cnpc> -->
    <app-cadastro-participantes *ngIf="componente === 7"></app-cadastro-participantes>
    <app-consulta-emprestimo *ngIf="componente === 8"></app-consulta-emprestimo>
    <!-- <app-acesso-restrito-relatorios *ngIf="componente === 9"></app-acesso-restrito-relatorios> -->
    <!-- <app-portal-eleicoes-arquivos *ngIf="componente === 9"></app-portal-eleicoes-arquivos> -->
    <app-sub-menu-relatorios *ngIf="componente === 10"></app-sub-menu-relatorios>