import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PesquisaSatisfacaoSiteComponent } from 'app/pesquisa-satisfacao-site/pesquisa-satisfacao-site.component';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ValidadorService } from 'app/services/validador.service';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { AcessoRestritoSiteService } from 'app/services/acesso-restrito.service';
import { DownloadService } from 'app/services/download.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer';
import { MinhaAreaSiteComponent } from 'app/minha-area-site/minha-area-site.component';
@Component({
  selector: 'app-contra-cheques',
  templateUrl: './contra-cheques.component.html',
  styleUrls: ['./contra-cheques.component.css']
})
export class ContraChequesComponent implements OnInit, OnDestroy {
  idPensionista = 0;
  nomeUsuario = '';
  retornou = false;
  cpf: string;
  disponibilizado: boolean;
  dados: any[] = [];
  @ViewChild(MinhaAreaSiteComponent) principal: MinhaAreaSiteComponent;

  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    public validadorService: ValidadorService,
    public downloadService: DownloadService,
    public servico: SiteService,
    private modalService: NgbModal,
    public acessoRestritoSiteService: AcessoRestritoSiteService,
    public router: Router,
    public mensagensService: MensagensService
  ) {
    this.pageTitleService.setTitle('ContraCheques');
    /* Page subTitle */
    this.pageTitleService.setSubTitle('');
    const usuario = JSON.parse(localStorage.getItem('localUsuarioDerminas'));
    if (!!usuario) {
      this.cpf = usuario.cpf;
      this.nomeUsuario = usuario.nomeUsuario;
      this.idPensionista = usuario.idPensionista;

    } else {
      this.router.navigate(['acesso-restrito']);
    }
  }

  ngOnInit(): void {
    this.acessoRestritoSiteService.listarContraCheques(this.cpf)
      .pipe(finalize(() => {
      }))
      .subscribe(result => {
        this.dados = result;
        this.retornou = true;
        this.disponibilizado = result.length > 0;
      },
        err => {
          const erro = this.mensagensService.recuperarMensagemErro(err);
          this.mensagensService.Error('Ocorreu um Erro!', erro);
        });
  }

  ngOnDestroy() {
  }

  sair() {
    localStorage.removeItem('localUsuarioDerminas');
    this.router.navigate(['acesso-restrito']);
  }

  baixar(id: number) {
    this.acessoRestritoSiteService.contacheque(id)
      .pipe(finalize(() => {
      }))
      .subscribe(result => {
        this.downloadService.downloadFileFromByte(result, 'application/pdf', 'contracheque_derminas.pdf');
      },
        err => {
          const erro = this.mensagensService.recuperarMensagemErro(err);
          this.mensagensService.Error('Ocorreu um Erro!', erro);
        });
  }

  voltar() {
    this.principal.voltando();
  }

}
