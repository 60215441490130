import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventosService {
    onUsuarioLogou: BehaviorSubject<any>;

    constructor(
        private _location: Location,
        private _activatedRoute: ActivatedRoute,
    ) {
        // Set the defaults
        this.onUsuarioLogou = new BehaviorSubject([]);
    }

    logou(logado: boolean) {
        this.onUsuarioLogou.next(logado);
    }

}
