import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';

@Component({
  selector: 'app-mais-informacoes',
  templateUrl: './mais-informacoes.component.html',
  styleUrls: ['./mais-informacoes.component.css']
})
export class MaisInformacoesComponent implements OnInit {

  posts: any[] = [];

  constructor(
    public servico: SiteService,
    public router: Router,
    public mensagensService: MensagensService
  ) {
    this.servico.listarInformacoes().
      subscribe(response => {
        this.posts = response;
      },
        err => console.log(err),
        () => this.posts
      );
  }

  ngOnInit(): void {
  }

  redirecionar(item: any) {
    if (item.redirecionamento.indexOf('http') > -1) {
      window.open(item.redirecionamento, '_blank');
    } else if (item.redirecionamento.indexOf('links-uteis') > -1) {
      this.router.navigate(['links-uteis']);
    } else {
      this.router.navigate([item.redirecionamento]);
    }
  }

}
