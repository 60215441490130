import { AcessoRestritoSiteService } from 'app/services/acesso-restrito.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { EventosService } from 'app/services/eventos.service';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ValidadorService } from 'app/services/validador.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-primeiro-acesso',
  templateUrl: './primeiro-acesso.component.html',
  styleUrls: ['./primeiro-acesso.component.css']
})
export class PrimeiroAcessoComponent implements OnInit, OnDestroy {

  formGroup: FormGroup;
  inSpinner = false;
  erromessage = '';
  private _unsubscribeAll: Subject<any>;

  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    private eventosService: EventosService,
    public validadorService: ValidadorService,
    public acessoRestritoSiteService: AcessoRestritoSiteService,
    public router: Router,
    public mensagensService: MensagensService
  ) {
    /* Page title */
    this.pageTitleService.setTitle('Primeiro Acesso');
    /* Page subTitle */
    this.pageTitleService.setSubTitle('');
    this.formGroup = this.formBuilder.group({
      senha: [null, Validators.required],
      idUsuario: 0,
      novaSenha: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(10)]],
      repetirNovaSenha: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(10)]],
      cpf: [null, Validators.required],
      matricula: [null, Validators.required],
      nomeMae: [null, Validators.required]
    });
    this._unsubscribeAll = new Subject();

  }

  ngOnInit(): void {
    this.eventosService.onUsuarioLogou
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(selectedTodos => {
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  entrar() {
    this.erromessage = '';
    if (this.formGroup.valid) {
      this.inSpinner = true;
      this.acessoRestritoSiteService.primeiroacesso(this.formGroup.value)
        .pipe(finalize(() => {
          this.inSpinner = false;
        }))
        .subscribe(result => {
          localStorage.setItem(
            'localUsuarioDerminas',
            JSON.stringify({
              validade: result.validade,
              nomeUsuario: result.nomeUsuario,
              userId: result.userId,
              tipo: result.tipo,
              idUsuario: result.idUsuario,
              tipoUsuario: result.tipoUsuario,
              mesAniversario: result.mesAniversario,
              cpf: result.cpf,
              primeiroAcesso: result.primeiroAcesso,
              temQueAtualizar: result.temQueAtualizar,
              exibirEmprestimo: result.exibirEmprestimo,
              idPensionista: result?.idPensionista,
              idParticipante: result?.idParticipante
            })
          );
          setTimeout(() => {
            this.eventosService.logou(true);
            this.router.navigate(['minha-area']);
          }, 10);
        },
          err => {
            this.eventosService.logou(false);
            this.inSpinner = false;
            const erro = this.mensagensService.recuperarMensagemErro(err);
            this.erromessage = erro;
          });
    } else {
      this.inSpinner = false;
      this.validadorService.validarTodosCampos(this.formGroup);
      this.erromessage = 'Existem campos obrigatórios sem o devido preenchimento';
    }
  }

}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('novaSenha');
  const passwordConfirm = control.parent.get('repetirNovaSenha');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { 'passwordsNotMatching': true };
};
