/*
 * Home contact
 * Used in another component.
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
   selector: '[angly-homeContact]',
   templateUrl: './homeContact.component.html',
   styleUrls: ['./homeContact.component.scss']
})
export class HomeContactComponent implements OnInit {

   @Input() contact: any;
   latitude = -19.9366967;
   longitude = -43.947341;

   constructor() { }

   ngOnInit() {
      // this.latitude = parseInt(this.contact.latitude, 10);
      // this.longitude = parseInt(this.contact.longitude, 10);
   }

   abrirLink(url: string) {
      window.open(url, '_blank');
   }

   abrirWhatsApp(whatsApp: string, texto: string) {
      const notificacao = `https://api.whatsapp.com/send?phone=55${whatsApp}&text=${texto}`;
      setTimeout(() => {
         window.open(notificacao, '_blank');
      }, 5);
   }

}
