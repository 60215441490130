import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ValidadorService } from 'app/services/validador.service';
import { AcessoRestritoSiteService } from 'app/services/acesso-restrito.service';
import { DownloadService } from 'app/services/download.service';
import { NgbActiveModal, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-pesquisa-satisfacao-site',
  templateUrl: './pesquisa-satisfacao-site.component.html',
  styleUrls: ['./pesquisa-satisfacao-site.component.css']
})
export class PesquisaSatisfacaoSiteComponent implements OnInit {
  formGroup: FormGroup;
  erromessage = '';
  @Input() public dados: any;
  erroresposta = '';
  inSpinner = false;

  constructor(
    private formBuilder: FormBuilder,
    public validadorService: ValidadorService,
    public downloadService: DownloadService,
    public acessoRestritoSiteService: AcessoRestritoSiteService,
    public servico: SiteService,
    public router: Router,
    public activeModal: NgbActiveModal,
    public mensagensService: MensagensService
  ) {
    this.formGroup = this.formBuilder.group({
      nomeParticipante: [null],
      observacoes: [null],
      tipoParticipante: [null, Validators.required],
      resposta: [null, Validators.required],
      idPesquisa: [null]
    });
  }

  ngOnInit(): void {
    if (this.dados !== null) {
      this.formGroup.patchValue({
        nomeParticipante: this.dados.nomeParticipante,
        tipoParticipante: this.dados.tipoParticipante,
        idPesquisa: this.dados.idPesquisa
      });
    }
  }

  salvar() {
    this.erromessage = '';
    if (this.formGroup.valid) {
      this.inSpinner = true;
      this.servico.responderPesquisaSatisfacao(this.formGroup.value)
        .pipe(finalize(() => {
          this.inSpinner = false;
        }))
        .subscribe(result => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'A Derminas agradeçe pela sua participação!',
            showConfirmButton: false,
            timer: 5000
          });
          this.activeModal.close(true);
        },
          err => {
            this.inSpinner = false;
            this.erromessage = this.mensagensService.recuperarMensagemErro(err);
          });
    } else {
      if (this.formGroup.get('resposta').status === 'INVALID') {
        this.formGroup.get('resposta').setErrors({ selecionarOpcao: true });
      }
      this.inSpinner = false;
      this.validadorService.validarTodosCampos(this.formGroup);
      this.erromessage = 'Selecione os campos solicitados';
    }
  }

  close() {
    this.activeModal.close(null);
  }

}
