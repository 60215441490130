<div class="contact-widget">
    <div class="container">
        <!-- <div class="row" style="margin-bottom: 15px;">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
                        <li class="breadcrumb-item"><a routerLink="/minha-area">Minha Área</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Contracheque</li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <p>Seja bem vindo(a) <b>{{nomeUsuario}}</b></p>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 text-right">
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="voltar()">
                    <i class="fa fa-arrow-left" aria-hidden="true"></i> Voltar
                </button>
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="sair()"
                    style="cursor: pointer; margin-left: 10px;" ngbTooltip="Sair"><i class="fa fa-sign-out"></i>
                    Sair</button>
            </div>
        </div> -->
        <div class="row" *ngIf="!disponibilizado && retornou">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <p style="text-align: center; font-size: 16px; font-weight: bold; color: red;">* Contracheque não
                    disponível,
                    entre em contato com a Derminas.</p>
            </div>
        </div>
        <div class="row" *ngIf="disponibilizado">
            <div class="col-sm-12 col-md-12 col-lg-12" style="text-align: center;">
                <div class="table-responsive user-list">
                    <table class="table" style="width: 100%;">
                        <thead>
                            <tr>
                                <th scope="col" style="width: 80%;">Mês/Ano</th>
                                <th scope="col" style="width: 20%; text-align: center;">Baixar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of dados">
                                <td class="col-md" style="width: 80%; text-align: left;">
                                    {{item.mesAno}}
                                </td>
                                <td class="col-md" style="width: 20%; text-align: center;">
                                    <button type="button" class="btn btn-outline-primary btn-icon btn-sm" placement="top"
                                        style="margin-right: 5px;" ngbTooltip="Baixar Contracheque"
                                        (click)="baixar(item.idContraCheque)">
                                        <i class="fa fa-file-pdf-o"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>