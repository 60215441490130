import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { Subscription } from 'rxjs';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-conteudo-site',
  templateUrl: './conteudo-site.component.html',
  styleUrls: ['./conteudo-site.component.scss']
})
export class ConteudoSiteComponent implements OnInit, OnDestroy {
  conteudo: any;
  navSubscription: Subscription = null;
  anexos: any[] = [];
  imagens: any[] = [];
  area = '';
  subarea = '';

  constructor(
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    public servico: SiteService,
    public mensagensService: MensagensService
  ) {
    this.navSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }
      if (event instanceof NavigationEnd) {
        this.obterConteudoSite(this._activatedRoute.snapshot.params.id);
        // Hide loading indicator
      }
      if (event instanceof NavigationError) {
        // Hide loading indicator
        // Present error to user
        console.log(event.error);
      }
    });

  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.navSubscription.unsubscribe();
  }

  getAnexo(anexo: string) {
    return environment.urlAnexo + '/' + anexo;
  }

  abrir(anexo: any) {
    window.open(anexo.link, '_blank');
  }

  abrirLink(anexo: any) {
    if (!this.isNullOrEmpty(anexo.link)) {
      if (this.isValidURL(anexo.link)) {
        window.open(anexo.link, '_blank');
      }
    }
  }

  redirecionarImagem(anexo: any) {
    if (!this.isNullOrEmpty(anexo.redirecionamento)) {
      if (this.isValidURL(anexo.redirecionamento)) {
        window.open(anexo.redirecionamento, '_blank');
      }
    }
  }

  isValidURL(url: string) {
    const res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null);
  }

  isNullOrEmpty(val): boolean {
    if (val === undefined || val === null || val.trim() === '') {
      return true;
    }
    return false;
  }

  obterConteudoSite(idConteudo) {
    this.servico.obterConteudoSite(idConteudo).
      subscribe(response => {
        this.conteudo = response;
        this.area = response.funcionalidadePai;
        this.subarea = response.funcionalidade;
        this.anexos = response.anexos;
        this.imagens = response.imagens;
        if (response.notFound) {
          this.router.navigate(['not-found']);
        }
      },
        err => console.log(err),
        () => this.conteudo
      );
  }

}
