<div class="contact-widget">
    <div class="container">
        <div class="row border-bottom">
            <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-3" *ngFor="let content of featuresContent">
                <div class="card-line-box p-4" (click)="menu(content?.url)" style="cursor: pointer;">
                    <div class="mb-4">
                        <img [src]="content?.image_path" class="img-fluid">
                    </div>
                    <h5 class="mb-3">{{content?.heading}}</h5>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-12">
                <app-extrato-atas *ngIf="componente === 'ATAS'"></app-extrato-atas>
                <!-- <app-resolucao-cnpc *ngIf="componente === 'RESOLUCAO'"></app-resolucao-cnpc> -->
                <app-acesso-restrito-relatorios *ngIf="componente === 'RELATORIOS'"></app-acesso-restrito-relatorios>
            </div>
        </div>
    </div>
</div>