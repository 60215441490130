import { AcessoRestritoSiteService } from './../../services/acesso-restrito.service';
import { MensagensService } from './../../services/mensagens.service';
import { SiteService } from './../../services/site.service';
import { ValidadorService } from './../../services/validador.service';
import { PageTitleService } from './../../core/page-title/page-title.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer';

@Component({
  selector: 'app-resolucao-cnpc-transparencia',
  templateUrl: './resolucao-cnpc-transparencia.component.html',
  styleUrls: ['./resolucao-cnpc-transparencia.component.css']
})
export class ResolucaoCnpcTransparenciaComponent implements OnInit, OnDestroy {
  idPensionista = 0;
  nomeUsuario = '';
  resolucao: any = {
    anos: [],
    arquivos: []
  };
  tiposResolucoes: any[] = [];
  resolucoesAnos: any[] = [];
  arquivosResolucoes: any[] = [];
  tipoSelecionado = '';
  idArquivosSelecionado = 0;
  anoSelecionado = 0;
  cpf = '';

  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    public validadorService: ValidadorService,
    public acessoRestritoSiteService: AcessoRestritoSiteService,
    public servico: SiteService,
    public router: Router,
    private modalService: NgbModal,
    public mensagensService: MensagensService
  ) {
    this.pageTitleService.setTitle('Resolução CNPC Nº 32 RDI');
    /* Page subTitle */
  }

  ngOnInit(): void {
    this.servico.obterRelatoriosSiteChave().
      subscribe(response => {
        this.resolucoesAnos = response.anos;
        this.resolucao = response;
      },
        err => console.log(err),
        () => this.resolucao
      );
  }

  ngOnDestroy() {
  }


  selecionarAno(ano: any) {
    this.anoSelecionado = ano;
    this.idArquivosSelecionado = 0;
    this.tipoSelecionado = '';
    this.arquivosResolucoes = [];
    // tslint:disable-next-line:max-line-length
    const unique = this.resolucao.arquivos.filter(x => x.ano === ano).map(item => item.categoriaRDI).filter((value, index, self) => self.indexOf(value) === index);
    this.tiposResolucoes = unique;
  }

  baixarResolucao(resolucao: any) {
    this.idArquivosSelecionado = resolucao.id;
    window.open(resolucao.link, '_blank');
  }

  exibirArqivos(tipo: any) {
    this.tipoSelecionado = tipo;
    this.arquivosResolucoes = this.resolucao.arquivos.filter(x => x.ano === this.anoSelecionado && x.categoriaRDI === tipo);
  }

}
