import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { AcessoRestritoSiteService } from 'app/services/acesso-restrito.service';
import { DownloadService } from 'app/services/download.service';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ValidadorService } from 'app/services/validador.service';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transparencia',
  templateUrl: './transparencia.component.html',
  styleUrls: ['./transparencia.component.css']
})
export class TransparenciaComponent implements OnInit {

  idPensionista = 0;
  nomeUsuario = '';
  retornou = false;
  cpf: string;
  idSelecionado = 0;
  tipoReport = 'R';
  arquivos: any[] = [];
  area = '';
  subarea = '';

  reports: any[] = [];

  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    public validadorService: ValidadorService,
    public downloadService: DownloadService,
    public servico: SiteService,
    public acessoRestritoSiteService: AcessoRestritoSiteService,
    public router: Router,
    public mensagensService: MensagensService
  ) {
    this.pageTitleService.setTitle('Relatórios');
    /* Page subTitle */
    this.pageTitleService.setSubTitle('');
  }

  ngOnInit(): void {

    this.servico.menusRelatorioSite()
      .pipe(finalize(() => {
      }))
      .subscribe(result => {
        this.reports = result;
      },
        err => {
          this.reports = [];
        });
  }

  selecionar(idTransparencia: number, chave: string) {
    this.idSelecionado = idTransparencia;
    if (chave === 'DEMO_INVE') {
      this.tipoReport = 'X';
    } else {
      this.tipoReport = 'R';
      this.servico.obterRelatoriosSite(idTransparencia).
        subscribe(response => {
          this.arquivos = response;
        },
          err => console.log(err),
          () => this.arquivos
        );
    }
  }

  abrir(anexo: any) {
    window.open(anexo.caminhoArquivo, '_blank');
  }
}
