import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ValidadorService } from 'app/services/validador.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css']
})
export class ContatoComponent implements OnInit {
  setores: any[] = [
    { displayName: 'Benefícios', value: 'B' },
    { displayName: 'Empréstimos', value: 'E' },
    { displayName: 'Geral', value: 'G' }
  ];
  pLang = 'pt-BR';
  pTheme = 'light';
  pType = 'image';
  pGlobal = false;
  pSize = 'normal';
  sucesso = false;
  erromessage = '';
  siteKey = '6LeDKSgUAAAAABtKiV4Uhxd2lvrXPVyhJKOY9iyJ'; // '6LeOZ4YUAAAAAJ8clQYNtOXNDHsd58KZHyJeILk6';
  formGroup: FormGroup;
  inSpinner = false;
  lat = -19.939880999999982;
  lng = -43.934166;
  dados: any;
  contact: any;
  // 6Lei17EaAAAAAJa95nrYaBLT3jk865bK35Wx4ZcY // admin.derminas.org.br // chave site V2
  // 6Lei17EaAAAAAEJJBdcve80s_2sIyWf3SzLhlelf // admin.derminas.org.br // chave secret V2

  // 6LeDKSgUAAAAABtKiV4Uhxd2lvrXPVyhJKOY9iyJ // derminas site V2
  // 6LeDKSgUAAAAADUm04PnSjWxsUbEq5nVvcFqx3Zi // derminas secret v2

  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    public validadorService: ValidadorService,
    public servico: SiteService,
    public mensagensService: MensagensService
  ) {
    /* Page title */
    this.pageTitleService.setTitle('Você em contato com a Derminas');

    /* Page subTitle */
    this.pageTitleService.setSubTitle('');
    this.formGroup = this.formBuilder.group({
      nome: [null, Validators.required],
      setor: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      assunto: [null, Validators.required],
      telefone: [null, Validators.required],
      mensagem: [null, Validators.required],
      recaptcha: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.servico.obterContato().
      subscribe(response => { this.dados = response; },
        err => console.log(err),
        () => this.dados
      );

      this.servico.obterRodape().
      subscribe(response => { this.contact = response; },
         err => console.log(err),
         () => this.contact
      );

  }

  abrirLink(url: string) {
    window.open(url, '_blank');
  }

  handleSuccess(event: any) {
    this.formGroup.patchValue({
      recaptcha: event
    });
  }

  abrirWhatsApp(whatsApp: string, texto: string) {
    const notificacao = `https://api.whatsapp.com/send?phone=55${whatsApp}&text=${texto}`;
    setTimeout(() => {
      window.open(notificacao, '_blank');
    }, 5);
  }

  irParaTopo() {
    let top = document.getElementById('topocontato');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  sendMessage() {
    this.sucesso = false;
    this.erromessage = '';
    if (this.formGroup.valid) {
      const element: HTMLElement = document.querySelector('#rc-anchor-container') as HTMLElement;
      if (element !== null && element !== undefined) {
        element.classList.remove('captha-erro');
      }
      this.inSpinner = true;
      this.servico.sendMessage(this.formGroup.value)
        .pipe(finalize(() => {
          this.inSpinner = false;
        }))
        .subscribe(result => {
          this.sucesso = true;
          this.irParaTopo();
          this.formGroup.reset();
          this.formGroup.markAsUntouched();
        },
          err => {
            this.inSpinner = false;
            const erro = this.mensagensService.recuperarMensagemErro(err);
          });
    } else {
      if (this.formGroup.get('recaptcha').invalid) {
        const element: HTMLElement = document.querySelector('#rc-anchor-container') as HTMLElement;
        if (element !== null && element !== undefined) {
          element.classList.add('captha-erro');
        }
      }
      this.inSpinner = false;
      this.validadorService.validarTodosCampos(this.formGroup);
      this.erromessage = 'Existem campos obrigatórios sem o devido preenchimento';
      this.irParaTopo();
    }
  }

}
