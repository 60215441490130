import { PesquisaSatisfacaoSiteComponent } from 'app/pesquisa-satisfacao-site/pesquisa-satisfacao-site.component';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ValidadorService } from 'app/services/validador.service';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { AcessoRestritoSiteService } from 'app/services/acesso-restrito.service';
import { DownloadService } from 'app/services/download.service';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DependentesParticipanteComponent } from './../dependentes-participante/dependentes-participante.component';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { MustMatch } from 'app/core/must-match.validator';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer';
import { MinhaAreaSiteComponent } from 'app/minha-area-site/minha-area-site.component';

@Component({
  selector: 'app-cadastro-participantes',
  templateUrl: './cadastro-participantes.component.html',
  styleUrls: ['./cadastro-participantes.component.scss']
})
export class CadastroParticipantesComponent implements OnInit, OnDestroy {

  nomeUsuario = '';
  erromessage = '';

  formGroup: FormGroup;
  inSpinner = false;
  atualizou = false;

  situacoes: any[] = [
    { displayName: 'Ativo', value: '99 - ATIVO' },
    { displayName: 'Aposentado', value: '13 - APOSENTADO' },
    { displayName: 'Mantido', value: '09 - MANTIDO' }
  ];
  locais: any[] = [
    { displayName: 'Sede', value: 'SEDE' },
    { displayName: 'Aposentado', value: 'APOSENTADOS DO DER/MG' },
    { displayName: '1ª CRG', value: '1ª CRG' },
    { displayName: '2ª CRG', value: '2ª CRG' },
    { displayName: '3ª CRG', value: '3ª CRG' },
    { displayName: '4ª CRG', value: '4ª CRG' },
    { displayName: '5ª CRG', value: '5ª CRG' },
    { displayName: '6ª CRG', value: '6ª CRG' },
    { displayName: '7ª CRG', value: '7ª CRG' },
    { displayName: '8ª CRG', value: '8ª CRG' },
    { displayName: '9ª CRG', value: '9ª CRG' },
    { displayName: '10ª CRG', value: '10ª CRG' },
    { displayName: '11ª CRG', value: '11ª CRG' },
    { displayName: '12ª CRG', value: '12ª CRG' },
    { displayName: '13ª CRG', value: '13ª CRG' },
    { displayName: '14ª CRG', value: '14ª CRG' },
    { displayName: '15ª CRG', value: '15ª CRG' },
    { displayName: '16ª CRG', value: '16ª CRG' },
    { displayName: '17ª CRG', value: '17ª CRG' },
    { displayName: '18ª CRG', value: '18ª CRG' },
    { displayName: '19ª CRG', value: '19ª CRG' },
    { displayName: '20ª CRG', value: '20ª CRG' },
    { displayName: '21ª CRG', value: '21ª CRG' },
    { displayName: '22ª CRG', value: '22ª CRG' },
    { displayName: '23ª CRG', value: '23ª CRG' },
    { displayName: '24ª CRG', value: '24ª CRG' },
    { displayName: '25ª CRG', value: '25ª CRG' },
    { displayName: '26ª CRG', value: '26ª CRG' },
    { displayName: '27ª CRG', value: '27ª CRG' },
    { displayName: '28ª CRG', value: '28ª CRG' },
    { displayName: '29ª CRG', value: '29ª CRG' },
    { displayName: '30ª CRG', value: '30ª CRG' },
    { displayName: '31ª CRG', value: '31ª CRG' },
    { displayName: '32ª CRG', value: '32ª CRG' },
    { displayName: '33ª CRG', value: '33ª CRG' },
    { displayName: '34ª CRG', value: '34ª CRG' },
    { displayName: '35ª CRG', value: '35ª CRG' },
    { displayName: '36ª CRG', value: '36ª CRG' },
    { displayName: '37ª CRG', value: '37ª CRG' },
    { displayName: '38ª CRG', value: '38ª CRG' },
    { displayName: '39ª CRG', value: '39ª CRG' },
    { displayName: '40ª CRG', value: '40ª CRG' }
  ];

  sexos: any[] = [
    { displayName: 'Masculino', value: 'Masculino' },
    { displayName: 'Feminino', value: 'Feminino' },
    { displayName: 'Não desejo Informar', value: 'N' }
  ];

  estadosCivilDependentes: any[] = [
    { displayName: 'Solteiro', value: 1 },
    { displayName: 'Casado', value: 2 }
  ];

  sexosdependentes: any[] = [
    { displayName: 'Masculino', value: 'M' },
    { displayName: 'Feminino', value: 'F' },
    { displayName: 'Não desejo Informar', value: 'N' }
  ];

  parentescos: any[] = [
    { value: '001', displayName: 'Esposa' },
    { value: '002', displayName: 'Esposo' },
    { value: '003', displayName: 'Filha' },
    { value: '004', displayName: 'Filho' },
    { value: '017', displayName: 'Companheiro' },
    { value: '018', displayName: 'Companheira' },
    { value: '998', displayName: 'Indicado' }
  ];

  estadosCivil: any[] = [
    { value: '01 - SOLTEIRO', displayName: 'Solteiro' },
    { value: '02 - CASADO', displayName: 'Casado' },
    { value: '03 - VIUVO', displayName: 'Viúvo' },
    { value: '04 - DESQUITADO', displayName: 'Desquitado' },
    { value: '05 - DIVORCIADO', displayName: 'Divorciado' },
    { value: '06 - SEPARADO', displayName: 'Separado' },
    { value: '07 - CONCUBINADO', displayName: 'Concubinado' },
    { value: '08 - FALECIDO', displayName: 'Falecido' },
    { value: '09 - MANTIDO', displayName: 'Mantido' },
    { value: '17 - DEMITIDO', displayName: 'Demitido' },
    { value: '30 - CANCELADO', displayName: 'Cancelado' },
    { value: '99 - NAO INFORMADO', displayName: 'Não informado' }
  ];
  estados: any[] = [
    { displayName: 'Acre', value: 'AC' },
    { displayName: 'Alagoas', value: 'AL' },
    { displayName: 'Amazonas', value: 'AM' },
    { displayName: 'Amapá', value: 'AP' },
    { displayName: 'Bahia', value: 'BA' },
    { displayName: 'Ceará', value: 'CE' },
    { displayName: 'Distrito Federal', value: 'DF' },
    { displayName: 'Espírito Santo', value: 'ES' },
    { displayName: 'Goiás', value: 'GO' },
    { displayName: 'Maranhão', value: 'MA' },
    { displayName: 'Minas Gerais', value: 'MG' },
    { displayName: 'Mato Grosso do Sul', value: 'MS' },
    { displayName: 'Mato Grosso', value: 'MT' },
    { displayName: 'Pará', value: 'PA' },
    { displayName: 'Paraíba', value: 'PB' },
    { displayName: 'Pernambuco', value: 'PE' },
    { displayName: 'Piauí', value: 'PI' },
    { displayName: 'Paraná', value: 'PR' },
    { displayName: 'Rio de Janeiro', value: 'RJ' },
    { displayName: 'Rio Grande do Norte', value: 'RN' },
    { displayName: 'Rondônia', value: 'RO' },
    { displayName: 'Roraima', value: 'RR' },
    { displayName: 'Rio Grande do Sul', value: 'RS' },
    { displayName: 'Santa Catarina', value: 'SC' },
    { displayName: 'Sergipe', value: 'SE' },
    { displayName: 'São Paulo', value: 'SP' },
    { displayName: 'Tocantins', value: 'TO' }
  ];
  idParticipante: number;

  popOverPessoa = `Declaração de Pessoa Politicamente Exposta <span><strong>
  <u>Declaração de Pessoa Politicamente Exposta</u></strong><br>
  <p>
      A declaração contida neste campo decorre de uma exigência feita pelo órgão responsável
  pela fiscalização das entidades de previdência complementar, que, a rigor, nem se
  aplica ao público da DERMINAS, mas, como a determinação abrange a todas as entidades
  do gênero, indistintamente, também os participantes do nosso plano precisam cumpri-la.
  </p>
  <p>
      O objetivo por trás da Instrução MPS/SPC nº 26/2008, referida neste campo, seria
  evitar que pessoas que ocupam cargos relevantes na administração pública (no Governo
  Federal ou nos Estados e suas capitais), ou seja, aqueles que possam ser classificados
  como "Pessoa Politicamente Exposta", se sirvam das entidades de previdência complementar,
  patrocinadas pelos órgãos públicos, para cometer crimes de "lavagem de dinheiro".
  </p>
  <p>
      É perfeitamente compreensível que esta exigência nos cause estranheza, pois trata-se
  de algo impossível de acontecer na DERMINAS, já que as únicas contribuições feitas
  pelos participantes ao Plano Único, administrado pela entidade, são aquelas descontadas
  em folha processada pela SEPLAG/MG, não havendo possibilidade do plano ser usado
  para tipo de desvio de recursos ou "lavagem de dinheiro".
  </p>
  <p>
      Entretanto, para atender à exigência do órgão regulador e fiscalizador do sistema,
  incluímos este campo no formulário, sendo que praticamente todos os nossos participantes
  devem assinalar a primeira opção nele contida (onde se lê: "não me enquadro na condição
  de PESSOA POLITICAMENTE EXPOSTA"), apenas para fins de regularização da sua situação
  perante o plano de benefícios.
  </p>
</span>`;

  cols: any[];
  displayModal = false;
  dependentes: any[] = [];
  exibirMensagem = false;
  tipoMensagem = '';
  mensagem = '';
  temQueAtualizar = false;
  alive = true;
  segundavez = false;
  cpf = '';
  @ViewChild(MinhaAreaSiteComponent) principal: MinhaAreaSiteComponent;
  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    public validadorService: ValidadorService,
    public downloadService: DownloadService,
    private modalService: NgbModal,
    public acessoRestritoSiteService: AcessoRestritoSiteService,
    public servico: SiteService,
    public router: Router,
    public mensagensService: MensagensService
  ) {
    this.pageTitleService.setTitle('Recadastramento');

    this.exibirMensagem = false;

    /* Page subTitle */
    this.pageTitleService.setSubTitle('');
    const usuario = JSON.parse(localStorage.getItem('localUsuarioDerminas'));
    const pq = JSON.parse(localStorage.getItem('pesquisa'));
    this.segundavez = pq === null ? false :  pq.exibir === 'S';
    if (!!usuario) {
      this.cpf = usuario.cpf;
      this.nomeUsuario = usuario.nomeUsuario;
      this.idParticipante = usuario.idParticipante;
      this.temQueAtualizar = usuario.temQueAtualizar === 'S';
      this.formGroup = this.formBuilder.group({
        nomeParticipante: [{ value: null, disabled: true }],
        nomeParticipanteValue: [null],
        codParticipante: [null],
        matriculaValue: [{ value: null, disabled: true }],
        maspValue: [{ value: null, disabled: true }],
        matricula: [null],
        masp: [null],
        situacao: [null],
        dataNascimento: [{ value: null, disabled: true }],
        dataNascimentoValue: [null],
        cpf: [{ value: null, disabled: true }],
        cpfValue: [null],
        sexo: [{ value: null, disabled: true }],
        sexoValue: [null],
        numeroTelefone1: [null],
        numeroTelefone2: [null],
        numeroTelefone3: [null],
        carteiraIdentidade: [{ value: null, disabled: true }],
        orgaoEmissor: [{ value: null, disabled: true }],
        carteiraIdentidadeValue: [null],
        orgaoEmissorValue: [null],
        estadoCivil: [null],
        naturalidade: [{ value: null, disabled: true }],
        nomeMae: [{ value: null, disabled: true }],
        naturalidadeValue: [null],
        nomeMaeValue: [null],
        cep: [null, Validators.required],
        logradouro: [null, Validators.required],
        numeroLogradouro: [null, Validators.required],
        complemento: [null],
        bairro: [null, Validators.required],
        cidade: [null, Validators.required],
        estado: [null, Validators.required],
        email: [null],
        cargo: [null],
        localTrabalho: [null],
        dataTrabalho: [{ value: null, disabled: true }],
        dataTrabalhoValue: [null],
        pessoaExposta: false,
        local: [null, Validators.required]
      });

    } else {
      this.router.navigate(['acesso-restrito']);
    }
  }

  ngOnInit(): void {
    // if (this.segundavez) {
    //   Observable.timer(0, 120000)
    //     .takeWhile(() => this.alive)
    //     .subscribe(() => {
    //       this.fazerpesquisa(this.cpf);
    //     });
    // }
    this.servico.obterParticipante(this.idParticipante)
      .pipe(finalize(() => {
      }))
      .subscribe(result => {
        this.dependentes = result.dependentes;
        const dataNascimento = result.dataNascimento !== null ? moment(result.dataNascimento).toDate() : null;
        const dia = parseInt(moment(dataNascimento).format('D'), 10);
        const mes = parseInt(moment(dataNascimento).format('M'), 10);
        const ano = parseInt(moment(dataNascimento).format('YYYY'), 10);

        const dataTrabalho = result.dataTrabalho !== null ? moment(result.dataTrabalho).toDate() : null;
        const dia2 = parseInt(moment(dataTrabalho).format('D'), 10);
        const mes2 = parseInt(moment(dataTrabalho).format('M'), 10);
        const ano2 = parseInt(moment(dataTrabalho).format('YYYY'), 10);

        this.formGroup.patchValue({
          nomeParticipante: result.nomeParticipante,
          nomeParticipanteValue: result.nomeParticipante,
          codParticipante: result.codParticipante,
          matriculaValue: result.matricula,
          maspValue: result.masp,
          matricula: result.matricula,
          masp: result.masp,
          situacao: result.situacao,
          // tslint:disable-next-line:max-line-length
          dataNascimento: dataNascimento !== null ? new NgbDate(ano, mes, dia) : null,
          dataNascimentoValue: dataNascimento !== null ? new NgbDate(ano, mes, dia) : null,
          cpf: result.cpf,
          cpfValue: result.cpf,
          sexo: result.sexo,
          sexoValue: result.sexo,
          numeroTelefone1: result.numeroTelefone1,
          numeroTelefone2: result.numeroTelefone2,
          numeroTelefone3: result.numeroTelefone3,
          carteiraIdentidade: result.carteiraIdentidade,
          carteiraIdentidadeValue: result.carteiraIdentidade,
          orgaoEmissor: result.orgaoEmissor,
          orgaoEmissorValue: result.orgaoEmissor,
          estadoCivil: result.estadoCivil,
          naturalidade: result.naturalidade,
          naturalidadeValue: result.naturalidade,
          nomeMae: result.nomeMae,
          nomeMaeValue: result.nomeMae,
          cep: result.cep,
          logradouro: result.logradouro,
          numeroLogradouro: result.numeroLogradouro,
          complemento: result.complemento,
          bairro: result.bairro,
          cidade: result.cidade,
          estado: result.estado,
          email: result.email,
          cargo: result.cargo,
          localTrabalho: result.localTrabalho,
          // tslint:disable-next-line:max-line-length
          dataTrabalho: dataTrabalho !== null ? new NgbDate(ano2, mes2, dia2) : null,
          dataTrabalhoValue: dataTrabalho !== null ? new NgbDate(ano2, mes2, dia2) : null,
          pessoaExposta: 'N',
        });
      },
        err => {
          const erro = this.mensagensService.recuperarMensagemErro(err);
          this.mensagensService.Error('Ocorreu um Erro!', erro);
        });

    this.cols = [
      { field: 'nome_Dependente', header: 'Nome Dependente' },
      { field: 'data_Nascimento', header: 'Data Nascimento' },
      { field: 'sexo_Dependente', header: 'Sexo' },
      { field: 'parentesco', header: 'Parentesco' },
      { field: 'estado_Civil', header: 'Estado Civil' },
      { field: 'invalido', header: 'Inválido' },
      { field: 'acoes', header: 'Ações' }
    ];
  }

  fazerpesquisa(cpf) {
    this.servico.pesquisapendente(cpf)
      .pipe(finalize(() => {
      }))
      .subscribe(result => {
        if (result.pendente) {
          const options = {
            title: 'Pesquisa de Satisfação!',
            text: 'Você deseja responder nossa pesquisa agora?',
            type: 'warning',
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim'
          };
          Swal.fire(options).then((resultado) => {
            if (resultado.value) {
              // tslint:disable-next-line:max-line-length
              const modalRef = this.modalService.open(PesquisaSatisfacaoSiteComponent, { size: 'md', backdrop: 'static', keyboard: false });
              modalRef.componentInstance.dados = result;
              modalRef.result.then((resposta) => {
                if (resposta !== null) { }
              }).catch((res) => { });
            }
          });
        }
      },
        err => {
          const erro = this.mensagensService.recuperarMensagemErro(err);
        });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  voltar() {
    this.principal.voltando();
  }

  sair() {
    localStorage.removeItem('localUsuarioDerminas');
    localStorage.removeItem('pesquisa');
    this.router.navigate(['acesso-restrito']);
  }

  addDependente(dep: any) {
    const modalRef = this.modalService.open(DependentesParticipanteComponent, { size: 'md', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.dados = dep;
    modalRef.componentInstance.idParticipante = this.idParticipante;
    modalRef.result.then((result) => {
      if (result !== null) { this.dependentes = result; }
    }).catch((res) => { });
  }

  excluir(dep: any) {
    const options = {
      type: 'info',
      title: 'Exclusão de Dependente!',
      text: 'Confirma exclusão do Dependente?',
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: '#29b6f6',
      cancelButtonColor: '#ef9a9a',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    };

    Swal.fire(options).then(
      s => {
        if (s.value) {
          this.inSpinner = true;
          this.acessoRestritoSiteService.excluirDependente(dep.cod_Dependente, dep.cod_Participante)
            .pipe(finalize(() => {
              this.inSpinner = false;
            }))
            .subscribe(result => {
              this.dependentes = result;
              this.mensagensService.Success('Sucesso!', 'Dependente excluído com sucesso.');
            },
              err => {
                const erro = this.mensagensService.recuperarMensagemErro(err);
                this.mensagensService.Error('Ocorreu um Erro!', erro);
              });
        }
      }
    );
  }

  pdf() {
    this.formGroup.enable();
    const year = this.formGroup.value.dataNascimentoValue.year;
    const date = this.formGroup.value.dataNascimentoValue.day;
    const month = this.formGroup.value.dataNascimentoValue.month;
    const newDate = new Date(year, month - 1, date);
    const fdate = moment(newDate).format('YYYY-MM-DD');

    const year2 = this.formGroup.value.dataTrabalhoValue.year;
    const date2 = this.formGroup.value.dataTrabalhoValue.day;
    const month2 = this.formGroup.value.dataTrabalhoValue.month;
    const newDate2 = new Date(year2, month2 - 1, date2);
    const fdate2 = moment(newDate2).format('YYYY-MM-DD');

    const model: any = {
      nomeParticipante: this.formGroup.value.nomeParticipanteValue,
      codParticipante: this.formGroup.value.codParticipante,
      matricula: this.formGroup.value.matricula,
      masp: this.formGroup.value.masp,
      situacao: this.formGroup.value.situacao,
      sexo: this.formGroup.value.sexoValue,
      // tslint:disable-next-line:max-line-length
      dataNascimento: fdate,
      carteiraIdentidade: this.formGroup.value.carteiraIdentidadeValue,
      orgaoEmissor: this.formGroup.value.orgaoEmissorValue,
      numeroTelefone1: this.formGroup.value.numeroTelefone1,
      numeroTelefone3: this.formGroup.value.numeroTelefone3,
      numeroTelefone2: this.formGroup.value.numeroTelefone2,
      cpf: this.formGroup.value.cpfValue,
      nomePai: this.formGroup.value.nomePai,
      estadoCivil: this.formGroup.value.estadoCivil,
      pessoaExposta: this.formGroup.value.pessoaExposta === 'S',
      nomeMae: this.formGroup.value.nomeMaeValue,
      cep: this.formGroup.value.cep,
      logradouro: this.formGroup.value.logradouro,
      numeroLogradouro: this.formGroup.value.numeroLogradouro,
      complemento: this.formGroup.value.complemento,
      bairro: this.formGroup.value.bairro,
      cidade: this.formGroup.value.cidade,
      estado: this.formGroup.value.estado,
      email: this.formGroup.value.email,
      cargo: this.formGroup.value.cargo,
      naturalidade: this.formGroup.value.naturalidadeValue,
      localTrabalho: this.formGroup.value.localTrabalho,
      // tslint:disable-next-line:max-line-length
      dataTrabalho: fdate2,
      dependentes: this.dependentes,
      local: this.formGroup.value.local
    };
    this.inSpinner = true;
    this.acessoRestritoSiteService.printParticipante(model)
      .pipe(finalize(() => {
        this.inSpinner = false;
      }))
      .subscribe(result => {
        this.exibirMensagem = true;
        this.tipoMensagem = 'success';
        this.mensagem = 'PDF gerado com sucesso!';
        this.downloadService.downloadFileFromByte(result, 'application/pdf', 'participante_derminas.pdf');
      },
        err => {
          const erro = this.mensagensService.recuperarMensagemErro(err);
          this.mensagensService.Error('Ocorreu um Erro!', erro);
        });
  }

  atualizar() {
    if (this.formGroup.valid) {
      const year = this.formGroup.getRawValue().dataNascimentoValue.year;
      const date = this.formGroup.getRawValue().dataNascimentoValue.day;
      const month = this.formGroup.getRawValue().dataNascimentoValue.month;
      const newDate = new Date(year, month - 1, date);
      const fdate = moment(newDate).format('YYYY-MM-DD');

      const year2 = this.formGroup.getRawValue().dataTrabalhoValue.year;
      const date2 = this.formGroup.getRawValue().dataTrabalhoValue.day;
      const month2 = this.formGroup.getRawValue().dataTrabalhoValue.month;
      const newDate2 = new Date(year2, month2 - 1, date2);
      const fdate2 = moment(newDate2).format('YYYY-MM-DD');

      const model: any = {
        nomeParticipante: this.formGroup.getRawValue().nomeParticipanteValue,
        codParticipante: this.formGroup.getRawValue().codParticipante,
        matricula: this.formGroup.getRawValue().matricula,
        masp: this.formGroup.getRawValue().masp,
        situacao: this.formGroup.getRawValue().situacao,
        sexo: this.formGroup.getRawValue().sexoValue,
        // tslint:disable-next-line:max-line-length
        dataNascimento: fdate,
        carteiraIdentidade: this.formGroup.getRawValue().carteiraIdentidadeValue,
        orgaoEmissor: this.formGroup.getRawValue().orgaoEmissorValue,
        numeroTelefone1: this.formGroup.getRawValue().numeroTelefone1,
        numeroTelefone3: this.formGroup.getRawValue().numeroTelefone3,
        numeroTelefone2: this.formGroup.getRawValue().numeroTelefone2,
        cpf: this.formGroup.getRawValue().cpfValue,
        nomePai: this.formGroup.getRawValue().nomePai,
        estadoCivil: this.formGroup.getRawValue().estadoCivil,
        pessoaExposta: this.formGroup.getRawValue().pessoaExposta === 'S',
        nomeMae: this.formGroup.getRawValue().nomeMaeValue,
        cep: this.formGroup.getRawValue().cep,
        logradouro: this.formGroup.getRawValue().logradouro,
        numeroLogradouro: this.formGroup.getRawValue().numeroLogradouro,
        complemento: this.formGroup.getRawValue().complemento,
        bairro: this.formGroup.getRawValue().bairro,
        cidade: this.formGroup.getRawValue().cidade,
        estado: this.formGroup.getRawValue().estado,
        email: this.formGroup.getRawValue().email,
        cargo: this.formGroup.getRawValue().cargo,
        naturalidade: this.formGroup.getRawValue().naturalidadeValue,
        localTrabalho: this.formGroup.getRawValue().localTrabalho,
        // tslint:disable-next-line:max-line-length
        dataTrabalho: fdate2,
      };
      const usuario = JSON.parse(localStorage.getItem('localUsuarioDerminas'));
      this.inSpinner = true;
      this.acessoRestritoSiteService.atualizarParticipante(model)
        .pipe(finalize(() => {
          this.inSpinner = false;
        }))
        .subscribe(result => {
          if (usuario.temQueAtualizar === 'S') {
            usuario.temQueAtualizar = 'N';
            this.refazerLocalStorage(usuario);
            let timerInterval;
            Swal.fire({
              title: 'Sucesso!',
              html: 'Dados atualizados com sucesso.',
              timer: 5000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              }
            }).then((resultado) => {
              this.router.navigate(['minha-area']);
            });
          } else {
            this.atualizou = true;
            usuario.temQueAtualizar = 'N';
            this.refazerLocalStorage(usuario);
            this.exibirMensagem = true;
            this.tipoMensagem = 'success';
            this.mensagem = 'Dados atualizados com sucesso, agora você já pode gerar o PDF clicando em "Gerar Formulário em PDF"!';
          }
        },
          err => {
            const erro = this.mensagensService.recuperarMensagemErro(err);
            this.mensagensService.Error('Ocorreu um Erro!', erro);
          });
    } else {
      this.inSpinner = false;
      this.validadorService.validarTodosCampos(this.formGroup);
      this.erromessage = 'Existem campos obrigatórios sem o devido preenchimento';
    }
  }

  refazerLocalStorage(usuario) {
    localStorage.setItem('localUsuarioDerminas', JSON.stringify(usuario));
  }

}
