<div class="col-sm-12 col-md-6 col-lg-5 py-5 contact-home-info">
   <ul class="list-unstyled">
      <li class="media mb-5">
         <div class="d-flex mr-4"><i class="ion-ios-pin font-3x text-warning"></i></div>
         <div class="media-body">
            <h5 class="mb-3"> Endereço </h5>
            <span>{{contact?.logradouro}}, {{contact?.numero}}</span>
            <span *ngIf="contact?.complemento"><br />{{contact?.complemento}}</span>
            <span *ngIf="contact?.bairro"><br />{{contact?.bairro}} - {{contact?.cidade}} / {{contact?.estado}} </span>
            <span *ngIf="contact?.cep"><br />{{contact?.cep}}</span>
         </div>
      </li>
      <li class="media">
         <div class="d-flex mr-4"><i class="ion-ios-chatboxes font-3x text-warning"></i></div>
         <div class="media-body">
            <h5 class="mb-3"> Contato </h5>
            <span *ngIf="contact?.email" class="d-block mb-1 text-dark">
               {{contact?.email}}
            </span>
            <span *ngIf="contact?.telefone1" class="d-block mb-1 text-dark">
               Telefone Geral: {{contact?.telefone1}}
            </span>
            <span *ngIf="contact?.telefone2" class="d-block mb-1 text-dark">
               Telefone: {{contact?.telefone2}}
            </span>
            <span *ngIf="contact?.whatsApp" class="d-block mb-1 text-dark" style="cursor: pointer;"
               (click)="abrirWhatsApp(contact?.whatsApp, contact?.mensagemWhatsApp)">
               WhatsApp: {{contact?.whatsApp}}
            </span>
            <span *ngIf="contact?.horario">
               Funcionamento: {{contact?.horario}}
            </span>
            <span *ngIf="contact?.horaInicio">
               <br /> de {{contact?.horaInicio}} às {{contact?.horaFim}} - {{contact?.horaInicio2}} às
               {{contact?.horaFim2}}
            </span>
         </div>
      </li>
   </ul>
</div>
<div class="col-sm-12 col-md-6 col-lg-7 col-xl-7">
   <div class="resp-container">
      <iframe class="resp-iframe"
         src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3750.6320060016587!2d-43.9377442856335!3d-19.939906343765486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa699c550497a91%3A0xecfb05ce6bf782d4!2sDerminas!5e0!3m2!1spt-BR!2sbr!4v1615324007507!5m2!1spt-BR!2sbr"
         width="500" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
   </div>
   <!-- Google Map -->
   <!-- <div class="google-maps">
      <div class="mapouter">
         <div class="gmap_canvas">
            <agm-map [latitude]="latitude" [longitude]="longitude" class="border-rad">
               <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
            </agm-map>
         </div>
      </div>
   </div> -->
</div>
<div class="follow-us text-center" style="width: 100%; padding-bottom: 20px;">
   <div class="container">
      <div class="mb-5">
         <h5> Siga-nos nas redes sociais.</h5>
      </div>
      <div class="social-icon-widget">
         <ul class="list-inline mb-0">
            <li class="list-inline-item mx-2"><a (click)="abrirLink('https://www.facebook.com/derminasseguridade')"
                  style="cursor: pointer;" class="square-60 rounded-circle bg-facebook"><i
                     class="fa fa-facebook fa-inverse"></i></a></li>
            <li class="list-inline-item mx-2"><a (click)="abrirLink('https://x.com/derminas1')" style="cursor: pointer;"
                  class="square-60 rounded-circle bg-twitter-x"><i class="fa fa-twitter fa-inverse"></i></a></li>
            <li class="list-inline-item mx-2"><a (click)="abrirWhatsApp(contact?.whatsApp, contact?.mensagemWhatsApp)" style="cursor: pointer;"
                  class="square-60 rounded-circle bg-whatsapp"><i class="fa fa-whatsapp fa-inverse"></i></a></li>
         </ul>
      </div>
   </div>
</div>