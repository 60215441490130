import { environment } from './../../environments/environment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ValidadorService } from 'app/services/validador.service';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { NgbActiveModal, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer';

@Component({
  selector: 'app-minha-area-site',
  templateUrl: './minha-area-site.component.html',
  styleUrls: ['./minha-area-site.component.css']
})
export class MinhaAreaSiteComponent implements OnInit, OnDestroy {
  componente = 1;
  formGroup: FormGroup;
  inSpinner = false;
  erromessage = '';
  prestadores: string;
  mesAniversario = false;
  nomeUsuario: string;
  tipoUsuario: string;
  exibirEmprestimo = false;
  cpf = '';
  exibirpesquisa = false;
  alive = true;
  pular1vez = true;

  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    public validadorService: ValidadorService,
    public servico: SiteService,
    private _activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    public router: Router,
    public mensagensService: MensagensService) {
    /* Page title */
    this.pageTitleService.setTitle('Minha Área');
    /* Page subTitle */
    this.pageTitleService.setSubTitle('');
    this.prestadores = environment.pdfPrestadores;
    const usuario = JSON.parse(localStorage.getItem('localUsuarioDerminas'));
    const pq = JSON.parse(localStorage.getItem('pesquisa'));
    this.pular1vez = true;

    if (!!usuario) {
      this.exibirpesquisa = usuario.exibirPesquisa;
      this.cpf = usuario.cpf;
      this.mesAniversario = usuario.mesAniversario;
      this.tipoUsuario = usuario.tipoUsuario;
      this.nomeUsuario = usuario.nomeUsuario;
      this.exibirEmprestimo = usuario.exibirEmprestimo;
      if (environment.production) {
        if (usuario.temQueAtualizar === 'S') {
          if (usuario.tipo === 'PARTICIPANTE') {
            this.router.navigate(['meus-dados']);
          } else {
            this.router.navigate(['recadastramento']);
          }
        }
      }
    } else {
      this.router.navigate(['acesso-restrito']);
    }
  }

  ngOnInit(): void {
    Observable.timer(0, 120000)
      .takeWhile(() => this.alive)
      .subscribe(() => {
        if (!this.pular1vez) {
          this.fazerpesquisa(this.cpf);
        }
        setTimeout(() => {
          this.pular1vez = false;
        }, 80);
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  voltando() {
    this.componente = 1;
    this.fazerpesquisa(this.cpf);
  }

  alterarSenha() {
    this.router.navigate(['alterar-senha']);
  }

  retornararea() {
    if (this.componente === 1) { return ''; }
    if (this.componente === 2) { return ' Recadastramento'; }
    if (this.componente === 3) { return ' Contracheque'; }
    if (this.componente === 4) { return ' Informes'; }
    if (this.componente === 5) { return ' Extrato das Atas das Reuniões'; }
    if (this.componente === 6) { return ' Resolução CNPC Nº 32 – RDI'; }
    if (this.componente === 7) { return ' Meu Cadastro'; }
    if (this.componente === 8) { return ' Empréstimos'; }
    if (this.componente === 9) { return ' Relatórios'; }
  }

  sair() {
    localStorage.removeItem('localUsuarioDerminas');
    this.router.navigate(['acesso-restrito']);
  }

  fazerpesquisa(cpf) {
    this.servico.pesquisapendente(cpf)
      .pipe(finalize(() => {
      }))
      .subscribe(result => {
        if (result.pendente) {
          const options = {
            html: `<p>Gostaríamos de saber o que você tem achado do nosso site. Sua opinião é muito importante para nós!</p>
                    <p>Clique no botão "Responder" abaixo para avaliar sua experiência, leva menos de 1 minuto!</p>`,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Agora não',
            confirmButtonText: 'Responder',
          };
          Swal.fire(options).then((resultado) => {
            if (resultado.value) {
              this.router.navigate([`responder-pesquisa-satisfacao/${result.idPesquisa}`]);
            }
          });
        }
      },
        err => {
          const erro = this.mensagensService.recuperarMensagemErro(err);
        });
  }



  fornecedores() {
    window.open(this.prestadores, '_blank');
  }


}
