<div class="section-gap-medium"></div>
<div class="contact-widget section-gap-mini">
    <div class="container">
        <form [formGroup]="formGroup" style="width: 100%;">
            <div class="row no-print" style="margin-bottom: 15px;">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Simulação de Empréstimo</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="alert alert-warning" role="alert">
                        * Os valores consultados são de caráter simulativo, não representando garantia de margem.
                        Para mais informações consulte diretamente a Derminas pelo email
                        <b>emprestimos@derminas.org.br</b>.
                    </div>
                </div>
            </div>
            <div class="row no-print" style="margin-top: 20px;" *ngIf="erromessage.length > 0">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="alert alert-danger" role="alert">
                        {{erromessage}}
                    </div>
                </div>
            </div>
            <div class="row no-print">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                        <label class="contro-label"> Salário Líquido (R$)</label>
                        <input name="salarioLiquido" id="salarioLiquido" formControlName="salarioLiquido"
                            (blur)="onBlurSalario()" currencyMask
                            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control"
                            maxlength="15" placeholder="R$ 0,00"
                            [ngClass]="validadorService.mostrarCSSErro('salarioLiquido', formGroup)" />
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                        <label class="contro-label"> Margem (R$)</label>
                        <input name="margem" id="margem" formControlName="margem" class="form-control" maxlength="15"
                            placeholder="R$ 0,00" currencyMask
                            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                            [ngClass]="validadorService.mostrarCSSErro('margem', formGroup)" />
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                        <label class="contro-label"> Valor Solicitado (R$)</label>
                        <input name="valorSolicitado" id="valorSolicitado" formControlName="valorSolicitado"
                            class="form-control" maxlength="15" placeholder="R$ 0,00" currencyMask
                            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                            [ngClass]="validadorService.mostrarCSSErro('valorSolicitado', formGroup)" />
                    </div>
                </div>
            </div>
            <div class="row no-print">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                        <label class="contro-label"> Idade</label>
                        <select id="idade" formControlName="idade" class="form-control" style="color: #000000;">
                            <option *ngFor="let item of idades" [value]="item.value">
                                {{item.displayName}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                        <label class="contro-label"> Prazo (Meses)</label>
                        <input name="prazo" id="prazo" formControlName="prazo" type="number" min="1" max="99"
                            class="form-control" maxlength="2" placeholder="Prazo (Meses)"
                            [ngClass]="validadorService.mostrarCSSErro('prazo', formGroup)" />
                    </div>
                </div>
            </div>
            <div class="row no-print" style="margin-top: 15px;">
                <div class="col-sm-12 col-md-12 col-lg-12" style="text-align: center;">
                    <button type="button" class="btn btn-outline-success" style="margin-right: 5px;"
                        (click)="calcular()">Calcular</button>
                    <button type="button" class="btn btn-outline-secondary" style="margin-right: 5px;"
                        *ngIf="calculo !== undefined" (click)="print()">Imprimir</button>
                    <button type="button" class="btn btn-outline-primary" (click)="limpar()">Limpar</button>
                </div>
            </div>
            <div class="row" *ngIf="calculo?.mensagem !== null" style="margin-top: 10px;">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="alert alert-success" role="alert">
                        {{calculo?.mensagem}}
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="calculo !== undefined" style="margin-top: 10px;">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="table-responsive user-list">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col" style="width: 25%;">Valor a Financiar</th>
                                    <th scope="col" style="width: 30%;">Valor Líquido a Receber</th>
                                    <th scope="col" style="width: 25%;">Valor da Prestação</th>
                                    <th scope="col" style="width: 20%;">Nº Parcelas</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="col-md" style="width: 25%;">
                                        {{calculo?.valorFinanciar | currency:'BRL'}}
                                    </td>
                                    <td class="col-md" style="width: 30%;">
                                        {{calculo?.valorLiquidoReceber | currency:'BRL'}}
                                    </td>
                                    <td class="col-md" style="width: 25%;">
                                        {{calculo?.valorPrestacao | currency:'BRL'}}
                                    </td>
                                    <td class="col-md" style="width: 20%;">
                                        {{calculo?.parcelas}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>