import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ValidadorService } from 'app/services/validador.service';
import { AcessoRestritoSiteService } from 'app/services/acesso-restrito.service';
import { DownloadService } from 'app/services/download.service';
import { NgbActiveModal, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-dependentes-participante',
  templateUrl: './dependentes-participante.component.html',
  styleUrls: ['./dependentes-participante.component.css']
})
export class DependentesParticipanteComponent implements OnInit {
  formGroupDep: FormGroup;
  erromessageDep = '';
  inSpinnerDep = false;
  @Input() public dados: any;
  @Input() public idParticipante: number;

  estadosCivilDependentes: any[] = [
    { displayName: 'Solteiro', value: 1 },
    { displayName: 'Casado', value: 2 }
  ];

  sexosdependentes: any[] = [
    { displayName: 'Masculino', value: 'M' },
    { displayName: 'Feminino', value: 'F' },
    { displayName: 'Não desejo Informar', value: 'N' }
  ];

  parentescos: any[] = [
    { value: '001', displayName: 'Esposa' },
    { value: '002', displayName: 'Esposo' },
    { value: '003', displayName: 'Filha' },
    { value: '004', displayName: 'Filho' },
    { value: '017', displayName: 'Companheiro' },
    { value: '018', displayName: 'Companheira' },
    { value: '998', displayName: 'Indicado' }
  ];

  constructor(
    private formBuilder: FormBuilder,
    public validadorService: ValidadorService,
    public downloadService: DownloadService,
    public acessoRestritoSiteService: AcessoRestritoSiteService,
    public servico: SiteService,
    public router: Router,
    public activeModal: NgbActiveModal,
    public mensagensService: MensagensService
  ) {
    this.formGroupDep = this.formBuilder.group({
      cod_Dependente: [0, Validators.required],
      cod_Participante: [null, Validators.required],
      nome_Dependente: [null, Validators.required],
      data_Nascimento: [null, Validators.required],
      sexo_Dependente: [null, Validators.required],
      parentesco: [null, Validators.required],
      estado_Civil: [null, Validators.required],
      invalido: 'Não',
      invalidoValue: [false, Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.dados !== null) {
      // const dataNascimento = this.dados.data_Nascimento !== null ? moment(this.dados.data_Nascimento).toDate() : null;
      // const dia = parseInt(moment(dataNascimento).format('D'), 10);
      // const mes = parseInt(moment(dataNascimento).format('M'), 10);
      // const ano = parseInt(moment(dataNascimento).format('YYYY'), 10);

      this.formGroupDep.patchValue({
        cod_Dependente: this.dados.cod_Dependente,
        cod_Participante: this.dados.cod_Participante,
        nome_Dependente: this.dados.nome_Dependente,
        // tslint:disable-next-line:max-line-length
        data_Nascimento: moment(this.dados.data_Nascimento).toDate(), // dataNascimento !== null ? new NgbDate(ano, mes, dia) : null,
        sexo_Dependente: this.dados.sexo_DependenteValue,
        parentesco: this.dados.parentescoValue,
        estado_Civil: this.dados.estado_CivilValue,
        invalido: this.dados.invalido,
        invalidoValue: this.dados.invalidoValue,
      });
    } else {
      this.formGroupDep.patchValue({
        cod_Participante: this.idParticipante
      });
    }
  }

  salvarDependente() {
    if (this.formGroupDep.valid) {
      // const year = this.formGroupDep.value.data_Nascimento.year;
      // const date = this.formGroupDep.value.data_Nascimento.day;
      // const month = this.formGroupDep.value.data_Nascimento.month;
      // const newDate = new Date(year, month - 1, date);
      // const fdate = moment(newDate).format('YYYY-MM-DD');

      const model = {
        cod_Dependente: this.formGroupDep.value.cod_Dependente,
        cod_Participante: this.formGroupDep.value.cod_Participante,
        nome_Dependente: this.formGroupDep.value.nome_Dependente,
        // tslint:disable-next-line:max-line-length
        data_Nascimento: moment(this.formGroupDep.value.data_Nascimento).format('YYYY-MM-DD'), // fdate,
        sexo_Dependente: this.formGroupDep.value.sexo_Dependente,
        parentesco: this.formGroupDep.value.parentesco,
        estado_Civil: this.formGroupDep.value.estado_Civil,
        invalido: this.formGroupDep.value.invalidoValue,
      };
      this.inSpinnerDep = true;
      this.acessoRestritoSiteService.salvarDependente(model)
        .pipe(finalize(() => {
          this.inSpinnerDep = false;
        }))
        .subscribe(result => {
          this.activeModal.close(result);
        },
          err => {
            const erro = this.mensagensService.recuperarMensagemErro(err);
            this.mensagensService.Error('Ocorreu um Erro!', erro);
          });
    } else {
      this.inSpinnerDep = false;
      this.validadorService.validarTodosCampos(this.formGroupDep);
      this.erromessageDep = 'Existem campos obrigatórios sem o devido preenchimento';
    }
  }

  close() {
    this.activeModal.close(null);
  }

}
