import { PesquisaSatisfacaoSiteComponent } from 'app/pesquisa-satisfacao-site/pesquisa-satisfacao-site.component';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ValidadorService } from 'app/services/validador.service';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { AcessoRestritoSiteService } from 'app/services/acesso-restrito.service';
import { DownloadService } from 'app/services/download.service';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer';
import { MustMatch } from 'app/core/must-match.validator';
import { MinhaAreaSiteComponent } from 'app/minha-area-site/minha-area-site.component';

@Component({
  selector: 'app-recadastamento',
  templateUrl: './recadastamento.component.html',
  styleUrls: ['./recadastamento.component.css']
})
export class RecadastamentoComponent implements OnInit, OnDestroy {

  nomeUsuario = '';
  erromessage = '';
  formGroup: FormGroup;
  inSpinner = false;
  atualizou = false;
  @ViewChild('inputCelular') inputCelular: ElementRef;
  @ViewChild('inputResidencial') inputResidencial: ElementRef;
  @ViewChild('inputComercial') inputComercial: ElementRef;

  estadosCivil: any[] = [
    { value: 'SOLTEIRO', displayName: 'Solteiro' },
    { value: 'CASADO', displayName: 'Casado' },
    { value: 'VIUVO', displayName: 'Viúvo' },
    { value: 'DESQUITADO', displayName: 'Desquitado' },
    { value: 'DIVORCIADO', displayName: 'Divorciado' },
    { value: 'SEPARADO', displayName: 'Separado' },
    { value: 'CONCUBINADO', displayName: 'Concubinado' },
    { value: 'FALECIDO', displayName: 'Falecido' },
    { value: 'MANTIDO', displayName: 'Mantido' },
    { value: 'DEMITIDO', displayName: 'Demitido' },
    { value: 'CANCELADO', displayName: 'Cancelado' },
    { value: 'NAO INFORMADO', displayName: 'Não informado' }
  ];
  estados: any[] = [
    { displayName: 'Acre', value: 'AC' },
    { displayName: 'Alagoas', value: 'AL' },
    { displayName: 'Amazonas', value: 'AM' },
    { displayName: 'Amapá', value: 'AP' },
    { displayName: 'Bahia', value: 'BA' },
    { displayName: 'Ceará', value: 'CE' },
    { displayName: 'Distrito Federal', value: 'DF' },
    { displayName: 'Espírito Santo', value: 'ES' },
    { displayName: 'Goiás', value: 'GO' },
    { displayName: 'Maranhão', value: 'MA' },
    { displayName: 'Minas Gerais', value: 'MG' },
    { displayName: 'Mato Grosso do Sul', value: 'MS' },
    { displayName: 'Mato Grosso', value: 'MT' },
    { displayName: 'Pará', value: 'PA' },
    { displayName: 'Paraíba', value: 'PB' },
    { displayName: 'Pernambuco', value: 'PE' },
    { displayName: 'Piauí', value: 'PI' },
    { displayName: 'Paraná', value: 'PR' },
    { displayName: 'Rio de Janeiro', value: 'RJ' },
    { displayName: 'Rio Grande do Norte', value: 'RN' },
    { displayName: 'Rondônia', value: 'RO' },
    { displayName: 'Roraima', value: 'RR' },
    { displayName: 'Rio Grande do Sul', value: 'RS' },
    { displayName: 'Santa Catarina', value: 'SC' },
    { displayName: 'Sergipe', value: 'SE' },
    { displayName: 'São Paulo', value: 'SP' },
    { displayName: 'Tocantins', value: 'TO' }
  ];
  idPensionista = 0;
  exibirMensagem = false;
  tipoMensagem = '';
  mensagem = '';
  temQueAtualizar = false;
  mascaraCelular = '(00) 00000-0000';
  @ViewChild(MinhaAreaSiteComponent) principal: MinhaAreaSiteComponent;
  cpf = '';

  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    public validadorService: ValidadorService,
    public downloadService: DownloadService,
    private modalService: NgbModal,
    public acessoRestritoSiteService: AcessoRestritoSiteService,
    public servico: SiteService,
    public router: Router,
    public mensagensService: MensagensService
  ) {
    this.pageTitleService.setTitle('Recadastramento');
    /* Page subTitle */
    this.pageTitleService.setSubTitle('');
    const usuario = JSON.parse(localStorage.getItem('localUsuarioDerminas'));
    if (!!usuario) {
      this.cpf = usuario.cpf;
      this.temQueAtualizar = usuario.temQueAtualizar === 'S';
      this.nomeUsuario = usuario.nomeUsuario;
      this.idPensionista = usuario.idPensionista;

      this.formGroup = this.formBuilder.group({
        nomePensionista: [{ value: null, disabled: true }],
        nomePensionistaValue: [null],
        codPensionista: [null],
        matricula: [{ value: null, disabled: true }],
        masp: [{ value: null, disabled: true }],
        datNascimento: [{ value: null, disabled: true }],
        datNascimentoValue: [null],
        matriculaHd: [null],
        maspHd: [null],
        carteiraIdentidade: [{ value: null, disabled: true }],
        orgaoEmissor: [{ value: null, disabled: true }],
        carteiraIdentidadeValue: [null],
        orgaoEmissorValue: [null],
        numeroTelefone: [null],
        numeroTelefone3: [null],
        numeroCelular: [null],
        cpf: [{ value: null, disabled: true }],
        cpfHd: [null],
        nomePai: [{ value: null, disabled: true }],
        nomePaiValue: [null],
        estadoCivil: [null],
        pessoaExposta: [null],
        nomeMae: [{ value: null, disabled: true }],
        nomeMaeValue: [null],
        cep: [null, Validators.required],
        logradouro: [null, Validators.required],
        numLogradouro: [null],
        complemento: [null],
        bairro: [null, Validators.required],
        cidade: [null, Validators.required],
        estado: [null, Validators.required],
        email: [null],
        local: [null, Validators.required],
        dataLocal: [null, Validators.required]
      });
    } else {
      this.router.navigate(['acesso-restrito']);
    }
  }

  ngOnInit(): void {
    this.servico.obterPensionista(this.idPensionista)
      .pipe(finalize(() => {
      }))
      .subscribe(result => {
        const datNascimento = result.datNascimento !== null ? moment(result.datNascimento).toDate() : null;

        const dia = parseInt(moment(datNascimento).format('D'), 10);
        const mes = parseInt(moment(datNascimento).format('M'), 10);
        const ano = parseInt(moment(datNascimento).format('YYYY'), 10);

        const hoje = new Date();
        const dia2 = parseInt(moment(hoje).format('D'), 10);
        const mes2 = parseInt(moment(hoje).format('M'), 10);
        const ano2 = parseInt(moment(hoje).format('YYYY'), 10);


        if (result.numeroCelular === null) {
          this.mascaraCelular = '(00) 00000-0000';
        } else if (result.numeroCelular.length < 11) {
          this.mascaraCelular = '(00) 0000-0000';
        } else {
          this.mascaraCelular = '(00) 00000-0000';
        }

        this.formGroup.setValue({
          nomePensionista: result.nomePensionista,
          nomePensionistaValue: result.nomePensionista,
          codPensionista: result.codPensionista,
          matricula: result.matricula,
          masp: result.masp,
          matriculaHd: result.matricula,
          maspHd: result.masp,
          // tslint:disable-next-line:max-line-length
          datNascimento: datNascimento !== null ? new NgbDate(ano, mes, dia) : null,
          datNascimentoValue: datNascimento !== null ? new NgbDate(ano, mes, dia) : null,
          carteiraIdentidade: result.carteiraIdentidade,
          orgaoEmissor: result.orgaoEmissor,
          carteiraIdentidadeValue: result.carteiraIdentidade,
          orgaoEmissorValue: result.orgaoEmissor,
          numeroTelefone: result.numeroTelefone,
          numeroTelefone3: result.numeroTelefone3,
          numeroCelular: result.numeroCelular,
          cpf: result.cpf,
          cpfHd: result.cpf,
          nomePai: result.nomePai,
          nomePaiValue: result.nomePai,
          estadoCivil: result.estadoCivil,
          pessoaExposta: 'N',
          nomeMae: result.nomeMae,
          nomeMaeValue: result.nomeMae,
          cep: result.cep,
          logradouro: result.logradouro,
          numLogradouro: result.numLogradouro,
          complemento: result.complemento,
          bairro: result.bairro,
          cidade: result.cidade,
          estado: result.estado,
          email: result.email,
          local: '',
          dataLocal: new NgbDate(ano2, mes2, dia2)
        });

      },
        err => {
          const erro = this.mensagensService.recuperarMensagemErro(err);
          this.mensagensService.Error('Ocorreu um Erro!', erro);
        });
  }

  ngOnDestroy() {
  }

  voltar() {
    this.principal.voltando();
  }

  sair() {
    localStorage.removeItem('localUsuarioDerminas');
    this.router.navigate(['acesso-restrito']);
  }

  pdf() {
    const year = this.formGroup.value.datNascimentoValue.year;
    const date = this.formGroup.value.datNascimentoValue.day;
    const month = this.formGroup.value.datNascimentoValue.month;
    const newDate = new Date(year, month - 1, date);
    const fdate = moment(newDate).format('YYYY-MM-DD');

    const year2 = this.formGroup.value.dataLocal.year;
    const date2 = this.formGroup.value.dataLocal.day;
    const month2 = this.formGroup.value.dataLocal.month;
    const newDate2 = new Date(year2, month2 - 1, date2);
    const fdate2 = moment(newDate2).format('YYYY-MM-DD');

    const model: any = {
      nomePensionista: this.formGroup.value.nomePensionistaValue,
      codPensionista: this.formGroup.value.codPensionista,
      matricula: this.formGroup.value.matriculaHd,
      masp: this.formGroup.value.maspHd,
      // tslint:disable-next-line:max-line-length
      datNascimento: fdate,
      carteiraIdentidade: this.formGroup.value.carteiraIdentidadeValue,
      orgaoEmissor: this.formGroup.value.orgaoEmissorValue,
      numeroTelefone: this.inputResidencial.nativeElement.value,
      numeroTelefone3: this.inputComercial.nativeElement.value,
      numeroCelular: this.inputCelular.nativeElement.value,
      cpf: this.formGroup.value.cpfHd,
      nomePai: this.formGroup.value.nomePaiValue,
      estadoCivil: this.formGroup.value.estadoCivil,
      pessoaExposta: this.formGroup.value.pessoaExposta === 'S',
      nomeMae: this.formGroup.value.nomeMaeValue,
      cep: this.formGroup.value.cep,
      logradouro: this.formGroup.value.logradouro,
      numLogradouro: this.formGroup.value.numLogradouro,
      complemento: this.formGroup.value.complemento,
      bairro: this.formGroup.value.bairro,
      cidade: this.formGroup.value.cidade,
      estado: this.formGroup.value.estado,
      email: this.formGroup.value.email,
      data_Atualizacao: null,
      local: this.formGroup.value.local,
      dataLocal: fdate2,
    };
    this.inSpinner = true;
    this.acessoRestritoSiteService.printRecadastramento(model)
      .pipe(finalize(() => {
        this.inSpinner = false;
      }))
      .subscribe(result => {
        this.downloadService.downloadFileFromByte(result, 'application/pdf', 'recadastramento_derminas.pdf');
        this.exibirMensagem = true;
        this.tipoMensagem = 'success';
        this.mensagem = 'PDF gerado com sucesso!';
      },
        err => {
          const erro = this.mensagensService.recuperarMensagemErro(err);
          this.mensagensService.Error('Ocorreu um Erro!', erro);
        });
  }

  atualizar() {
    this.exibirMensagem = false;
    if (this.formGroup.valid) {
      const year = this.formGroup.value.datNascimentoValue.year;
      const date = this.formGroup.value.datNascimentoValue.day;
      const month = this.formGroup.value.datNascimentoValue.month;
      const newDate = new Date(year, month - 1, date);
      const fdate = moment(newDate).format('YYYY-MM-DD');

      const year2 = this.formGroup.value.dataLocal.year;
      const date2 = this.formGroup.value.dataLocal.day;
      const month2 = this.formGroup.value.dataLocal.month;
      const newDate2 = new Date(year2, month2 - 1, date2);
      const fdate2 = moment(newDate2).format('YYYY-MM-DD');

      const model: any = {
        nomePensionista: this.formGroup.value.nomePensionistaValue,
        codPensionista: this.formGroup.value.codPensionista,
        matricula: this.formGroup.value.matriculaHd,
        masp: this.formGroup.value.maspHd,
        // tslint:disable-next-line:max-line-length
        datNascimento: fdate,
        carteiraIdentidade: this.formGroup.value.carteiraIdentidadeValue,
        orgaoEmissor: this.formGroup.value.orgaoEmissorValue,
        numeroTelefone: this.inputResidencial.nativeElement.value,
        numeroTelefone3: this.inputComercial.nativeElement.value,
        numeroCelular: this.inputCelular.nativeElement.value,
        cpf: this.formGroup.value.cpfHd,
        nomePai: this.formGroup.value.nomePaiValue,
        estadoCivil: this.formGroup.value.estadoCivil,
        pessoaExposta: this.formGroup.value.pessoaExposta === 'S',
        nomeMae: this.formGroup.value.nomeMaeValue,
        cep: this.formGroup.value.cep,
        logradouro: this.formGroup.value.logradouro,
        numLogradouro: this.formGroup.value.numLogradouro,
        complemento: this.formGroup.value.complemento,
        bairro: this.formGroup.value.bairro,
        cidade: this.formGroup.value.cidade,
        estado: this.formGroup.value.estado,
        email: this.formGroup.value.email,
        data_Atualizacao: null,
        local: this.formGroup.value.local,
        dataLocal: fdate2,
      };

      const usuario = JSON.parse(localStorage.getItem('localUsuarioDerminas'));
      this.inSpinner = true;
      this.servico.recadastramento(model)
        .pipe(finalize(() => {
          this.inSpinner = false;
        }))
        .subscribe(result => {
          if (usuario.temQueAtualizar === 'S') {
            usuario.temQueAtualizar = 'N';
            this.refazerLocalStorage(usuario);
            let timerInterval;
            Swal.fire({
              title: 'Sucesso!',
              html: 'Dados atualizados com sucesso.',
              timer: 5000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              }
            }).then((resultado) => {
              this.router.navigate(['minha-area']);
            });
          } else {
            this.atualizou = true;
            usuario.temQueAtualizar = 'N';
            this.refazerLocalStorage(usuario);
            this.exibirMensagem = true;
            this.tipoMensagem = 'success';
            this.mensagem = 'Dados atualizados com sucesso, agora você já pode gerar o PDF clicando em "Gerar Formulário em PDF"!';
          }
        },
          err => {
            const erro = this.mensagensService.recuperarMensagemErro(err);
            this.mensagensService.Error('Ocorreu um Erro!', erro);
          });
    } else {
      this.inSpinner = false;
      this.validadorService.validarTodosCampos(this.formGroup);
      this.exibirMensagem = true;
      this.tipoMensagem = 'danger';
      this.mensagem = 'Existem campos obrigatórios sem o devido preenchimento ou com dados inválidos.';
    }
  }

  refazerLocalStorage(usuario) {
    localStorage.setItem('localUsuarioDerminas', JSON.stringify(usuario));
  }

}
