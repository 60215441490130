import { PesquisaSatisfacaoSiteComponent } from 'app/pesquisa-satisfacao-site/pesquisa-satisfacao-site.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ValidadorService } from 'app/services/validador.service';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { AcessoRestritoSiteService } from 'app/services/acesso-restrito.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer';
import { MinhaAreaSiteComponent } from 'app/minha-area-site/minha-area-site.component';

@Component({
  selector: 'app-resolucao-cnpc',
  templateUrl: './resolucao-cnpc.component.html',
  styleUrls: ['./resolucao-cnpc.component.css']
})
export class ResolucaoCnpcComponent implements OnInit, OnDestroy {
  idPensionista = 0;
  nomeUsuario = '';
  resolucao: any = {
    anos: [],
    arquivos: []
  };
  tipos: any[] = [];
  arquivos: any[] = [];
  tipoSelecionado = '';
  idArquivosSelecionado = 0;
  anoSelecionado = 0;
  @ViewChild(MinhaAreaSiteComponent) principal: MinhaAreaSiteComponent;
  cpf = '';

  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    public validadorService: ValidadorService,
    public acessoRestritoSiteService: AcessoRestritoSiteService,
    public servico: SiteService,
    public router: Router,
    private modalService: NgbModal,
    public mensagensService: MensagensService
  ) {
    this.pageTitleService.setTitle('Resolução CNPC Nº 32 – RDI');
    /* Page subTitle */
    this.pageTitleService.setSubTitle('');
    const usuario = JSON.parse(localStorage.getItem('localUsuarioDerminas'));
    if (!!usuario) {
      this.cpf = usuario.cpf;
      this.nomeUsuario = usuario.nomeUsuario;
      this.idPensionista = usuario.idPensionista;

    } else {
      this.router.navigate(['acesso-restrito']);
    }
  }

  ngOnInit(): void {
    this.acessoRestritoSiteService.resolucoes()
      .pipe(finalize(() => {
      }))
      .subscribe(result => {
        this.resolucao = result;
      },
        err => {
          const erro = this.mensagensService.recuperarMensagemErro(err);
          this.mensagensService.Error('Ocorreu um Erro!', erro);
        });
  }

  ngOnDestroy() {
  }

  sair() {
    localStorage.removeItem('localUsuarioDerminas');
    this.router.navigate(['acesso-restrito']);
  }

  selecionarAno(ano: number) {
    this.anoSelecionado = ano;
    this.idArquivosSelecionado = 0;
    this.tipoSelecionado = '';
    this.arquivos = [];
    // tslint:disable-next-line:max-line-length
    const unique = this.resolucao.arquivos.filter(x => x.ano === ano).map(item => item.tipo).filter((value, index, self) => self.indexOf(value) === index);
    this.tipos = unique;
  }

  baixarResolucao(resolucao: any) {
    this.idArquivosSelecionado = resolucao.id;
    window.open(resolucao.link, '_blank');
  }

  exibirArqivos(tipo: any) {
    this.tipoSelecionado = tipo;
    this.arquivos = this.resolucao.arquivos.filter(x => x.ano === this.anoSelecionado && x.tipo === tipo);
  }

  voltar() {
    this.principal.voltando();
  }

}
