<div id="testimonial-slide" class="carousel slide" data-ride="carousel" style="min-height: 400px;">
   <div class="carousel-inner">
      <div class="row">
         <div class="col-sm-12 col-md-7 mx-auto" style="margin-top: 12px;">
            <div class="carousel-item text-center" *ngFor="let content of noticias; let x = index" [class.active]="x === 0">
               <h2 class="mb-5 text-white" style="margin-top: 20px;">{{content?.titulo}}</h2>
               <div class="testimonial-user mb-4">
                  <img [src]="content?.imagem" class="img-fluid rounded-circle shadow-md" alt="testimonial user"
                     width="120" height="120">
               </div>
               <div class="mb-4" *ngIf="content?.tipo !== 'T'">
                  <a href="javascript:void(0)" (click)="redirecionar(content)" style="background-color: #51CAE3!important;color: #FFFFFF;" class="text-uppercase btn btn-outline-info btn-sm">
                     SAIBA MAIS
                  </a>
               </div>
               <p class="text-white text-center" style="min-height: 100px; font-size: 18px; font-weight: bold;"> {{content?.conteudo}}</p>
            </div>
         </div>
      </div>
   </div>
   <a class="carousel-control-prev" href="#testimonial-slide" role="button" data-slide="prev">
      <span class="carousel-control-prev" aria-hidden="true">
         <i class="ion-ios-arrow-dropleft-circle font-2x"></i>
      </span>
      <span class="sr-only">Previous</span>
   </a>
   <a class="carousel-control-next" href="#testimonial-slide" role="button" data-slide="next">
      <span class="carousel-control-next" aria-hidden="true">
         <i class="ion-ios-arrow-dropright-circle font-2x"></i>
      </span>
      <span class="sr-only">Next</span>
   </a>
</div>