import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Injectable()
export class ValidadorService {
    constructor() { }


    // set options datatable

    getlanguageDatatable() {
        const dtOption = {
            language: {
                sEmptyTable: 'Nenhum registro encontrado',
                sInfo: 'Mostrando de _START_ até _END_ de _TOTAL_ registros',
                sInfoEmpty: 'Mostrando 0 até 0 de 0 registros',
                sInfoFiltered: '(Filtrados de _MAX_ registros)',
                sInfoPostFix: '',
                sInfoThousands: '.',
                sLengthMenu: '_MENU_ resultados por página',
                sLoadingRecords: 'Carregando...',
                sProcessing: 'Processando...',
                sZeroRecords: 'Nenhum registro encontrado',
                sSearch: 'Pesquisar',
                oPaginate: {
                    sNext: 'Próximo',
                    sPrevious: 'Anterior',
                    sFirst: 'Primeiro',
                    sLast: 'Último'
                },
                oAria: {
                    sSortAscending: ': Ordenar colunas de forma ascendente',
                    sSortDescending: ': Ordenar colunas de forma descendente'
                }
            }
        };

        return dtOption;
    }

    getMesExtenso(mes: number) {
        if (mes === 0) { return 'Janeiro'; }
        if (mes === 1) { return 'Fevereiro'; }
        if (mes === 2) { return 'Março'; }
        if (mes === 3) { return 'Abril'; }
        if (mes === 4) { return 'Maio'; }
        if (mes === 5) { return 'Junho'; }
        if (mes === 6) { return 'Julho'; }
        if (mes === 7) { return 'Agosto'; }
        if (mes === 8) { return 'Setembro'; }
        if (mes === 9) { return 'Outubro'; }
        if (mes === 10) { return 'Novembro'; }
        if (mes === 11) { return 'Dezembro'; }
        return '';
    }

    getPrimePtBr() {
        return {
            firstDayOfWeek: 0,
            dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
            dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
            monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
                'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            today: 'Hoje',
            clear: 'Limpar'
        };
    }

    recuperarMensagemErro(err: any) {
        if (err.status === 400) {
            if (this.isNullOrEmpty(err.error.message)) {
                return err.error;
            }
            return err.error.message;
        } else if (err.status === 404) {
            if (this.isNullOrEmpty(err.error.message)) {
                return err.error;
            }
            return err.error.message;
        } else if (err.status === 500) {
            if (this.isNullOrEmpty(err.error.message)) {
                return err.error;
            } else if (!this.isNullOrEmpty(err.error.exceptionMessage)) {
                return err.error.message + ' - ' + err.error.exceptionMessage;
            }
            return err.error.message;
        }
    }

    // -------------------------------------------------------------------------------------------------------------------------

    public isNullOrEmpty(val: string): boolean {
        if (val === undefined || val === null || val.trim() === '') {
            return true;
        }
        return false;
    }

    public isNullOrUndefined(val: any): boolean {
        if (val === undefined || val === null) {
            return true;
        }
        return false;
    }

    public isEmailValido(email: string) {
        if (email === '' || email === undefined || email === null) {
            return true;
        }

        const regex = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+\.([a-z]+)?$/i;

        if (regex.test(email)) {
            return true;
        } else {
            return false;
        }
    }

    public isCelularValido(telefone: string) {
        if (telefone === '') { return true; }
        if (telefone === undefined) { return true; }

        telefone = telefone.replace(/\D/g, '');

        if (telefone.length < 11) { return false; }

        if (telefone.length === 11 && parseInt(telefone.substring(2, 3), 10) !== 9) { return false; }

        for (let n = 0; n < 10; n++) {
            if (telefone === new Array(11).join(n.toString()) || telefone === new Array(12).join(n.toString())) { return false; }
        }
        const codigosDdd = [11, 12, 13, 14, 15, 16, 17, 18, 19,
            21, 22, 24, 27, 28, 31, 32, 33, 34,
            35, 37, 38, 41, 42, 43, 44, 45, 46,
            47, 48, 49, 51, 53, 54, 55, 61, 62,
            64, 63, 65, 66, 67, 68, 69, 71, 73,
            74, 75, 77, 79, 81, 82, 83, 84, 85,
            86, 87, 88, 89, 91, 92, 93, 94, 95,
            96, 97, 98, 99];
        if (codigosDdd.indexOf(parseInt(telefone.substring(0, 2), 10)) === -1) { return false; }

        if (telefone.length === 10 && [2, 3, 4, 5, 7].indexOf(parseInt(telefone.substring(2, 3), 10)) === -1) { return false; }

        return true;
    }

    public isTelefoneValido(telefone: string) {
        if (telefone === '') { return true; }
        if (telefone === undefined) { return true; }

        telefone = telefone.replace(/\D/g, '');

        if (!(telefone.length >= 10 && telefone.length <= 11)) { return false; }

        if (telefone.length === 11 && parseInt(telefone.substring(2, 3), 10) !== 9) { return false; }

        for (let n = 0; n < 10; n++) {
            if (telefone === new Array(11).join(n.toString()) || telefone === new Array(12).join(n.toString())) { return false; }
        }
        const codigosDdd = [11, 12, 13, 14, 15, 16, 17, 18, 19,
            21, 22, 24, 27, 28, 31, 32, 33, 34,
            35, 37, 38, 41, 42, 43, 44, 45, 46,
            47, 48, 49, 51, 53, 54, 55, 61, 62,
            64, 63, 65, 66, 67, 68, 69, 71, 73,
            74, 75, 77, 79, 81, 82, 83, 84, 85,
            86, 87, 88, 89, 91, 92, 93, 94, 95,
            96, 97, 98, 99];
        if (codigosDdd.indexOf(parseInt(telefone.substring(0, 2), 10)) === -1) { return false; }

        if (telefone.length === 10 && [2, 3, 4, 5, 7].indexOf(parseInt(telefone.substring(2, 3), 10)) === -1) { return false; }

        return true;
    }

    public isCpfValido(cpf: string) {
        if (!cpf) { return false; }

        const BLACKLIST = [
            '00000000000',
            '11111111111',
            '22222222222',
            '33333333333',
            '44444444444',
            '55555555555',
            '66666666666',
            '77777777777',
            '88888888888',
            '99999999999',
            '12345678909'
        ];

        let soma;
        let resto;
        soma = 0;
        if (cpf.trim() === '' || cpf === null || cpf === undefined) {
            return false;
        }
        cpf = (cpf || '').toString().replace(/[^\d]/g, '');
        if (BLACKLIST.includes(cpf)) {
            return false;
        }

        for (let i = 1; i <= 9; i++) {
            soma = soma + parseInt(cpf.substring(i - 1, i), null) * (11 - i);
        }
        resto = (soma * 10) % 11;

        if ((resto === 10) || (resto === 11)) {
            resto = 0;
        }

        if (resto !== parseInt(cpf.substring(9, 10), null)) {
            return false;
        }

        soma = 0;
        for (let i = 1; i <= 10; i++) {
            soma = soma + parseInt(cpf.substring(i - 1, i), null) * (12 - i);
        }
        resto = (soma * 10) % 11;
        if ((resto === 10) || (resto === 11)) {
            resto = 0;
        }

        if (resto !== parseInt(cpf.substring(10, 11), null)) {
            return false;
        }

        return true;
    }


    // -------------------------------------------------------------------------------------------------------------------------
    // validações do formulário
    mostrarCSSErro(campo: string, form: FormGroup) {
        return {
            'is-invalid': this.campoValido(campo, form),
            '': this.campoValido(campo, form)
        };
    }

    mostrarCSSErroDinamico(campo: string, form: FormGroup, validar: boolean) {
        if (validar) {
            return {
                'is-invalid': this.campoValido(campo, form),
                '': this.campoValido(campo, form)
            };
        } else {
            return '';
        }
    }

    campoValido(campo: string, form: FormGroup) {
        return !form.get(campo).valid &&
            form.get(campo).touched;
    }

    validarTodosCampos(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validarTodosCampos(control);
            }
        });
    }
}
