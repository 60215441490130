import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AcessoRestritoSiteService } from 'app/services/acesso-restrito.service';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ValidadorService } from 'app/services/validador.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-aniversariantes-derminas',
  templateUrl: './aniversariantes-derminas.component.html',
  styleUrls: ['./aniversariantes-derminas.component.css']
})
export class AniversariantesDerminasComponent implements OnInit {

  aniversariantes: any;
  diames: any;

  constructor(
    public validadorService: ValidadorService,
    public acessoRestritoSiteService: AcessoRestritoSiteService,
    public servico: SiteService,
    public router: Router,
    public mensagensService: MensagensService
  ) {
    const data = new Date();
    this.diames = this.validadorService.getMesExtenso(data.getMonth());
  }

  ngOnInit(): void {
    this.servico.aniversariantes()
      .pipe(finalize(() => {
      }))
      .subscribe(result => {
        this.aniversariantes = result;
      },
        err => {
          const erro = this.mensagensService.recuperarMensagemErro(err);
          this.mensagensService.Error('Ocorreu um Erro!', erro);
        });
  }

}
