import { TransparenciaComponent } from './transparencia/transparencia.component';
import { OrganogramaComponent } from './organograma/organograma.component';
import { PesquisaSatisfacaoDerminasComponent } from './pesquisa-satisfacao-derminas/pesquisa-satisfacao-derminas.component';
import { ConsultaEmprestimoComponent } from './consulta-emprestimo/consulta-emprestimo.component';
import { AcessoRestritoResetComponent } from './acesso-restrito-reset/acesso-restrito-reset.component';
import { DocumentosSiteComponent } from './documentos-site/documentos-site.component';
import { SimulacaoEmprestimoComponent } from './simulacao-emprestimo/simulacao-emprestimo.component';
import { ExtratoAtasComponent } from './extrato-atas/extrato-atas.component';
import { InformesComponent } from './informes/informes.component';
import { ContraChequesComponent } from './contra-cheques/contra-cheques.component';
import { RecadastamentoComponent } from './recadastamento/recadastamento.component';
import { MinhaAreaComponent } from './minha-area/minha-area.component';
import { AcessoRestritoComponent } from './acesso-restrito/acesso-restrito.component';
import { ContatoComponent } from './contato/contato.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { MainComponent } from './main/main.component';
import { HomeComponent } from './home/home.component';
import { PricingComponent } from './pricing/pricing.component';
import { ContactComponent } from './contact/contact.component';
import { FeaturesComponent } from './features/features.component';
import { AboutComponent } from './about/about.component';
import { SearchComponent } from './search/search.component';
import { SupportComponent } from './support/support.component';
import { sidebarWidgetsComponent } from './sidebarWidgets/sidebarWidgets.component';
import { ConteudoSiteComponent } from './conteudo-site/conteudo-site.component';
import { AuthGuard } from './services/auth.guard';
import { AniversariantesDerminasComponent } from './aniversariantes-derminas/aniversariantes-derminas.component';
import { CadastroParticipantesComponent } from './cadastro-participantes/cadastro-participantes.component';
import { PrimeiroAcessoComponent } from './primeiro-acesso/primeiro-acesso.component';
import { ResolucaoCnpcComponent } from './resolucoes/resolucao-cnpc/resolucao-cnpc.component';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha.component';
import { NovaSenhaAcessoComponent } from './nova-senha-acesso/nova-senha-acesso.component';
import { AcessoRestritoRelatoriosComponent } from './acesso-restrito-relatorios/acesso-restrito-relatorios.component';
import { PesquisaSatisfacaoComponent } from './pesquisa-satisfacao/pesquisa-satisfacao.component';
import { PesquisaSatisfacaoAtendimentoComponent } from './pesquisa-satisfacao-atendimento/pesquisa-satisfacao-atendimento.component';
import { MinhaAreaSiteComponent } from './minha-area-site/minha-area-site.component';
import { PaginaLinksComponent } from './pagina-links/pagina-links.component';
import { LgpdComponent } from './lgpd/lgpd.component';
import { CanalDenunciaComponent } from './canal-denuncia/canal-denuncia.component';
import { CanalDenunciaRegistroComponent } from './canal-denuncia-registro/canal-denuncia-registro.component';
import { CanalDenunciaPesquisaComponent } from './canal-denuncia-pesquisa/canal-denuncia-pesquisa.component';
import { CanalDenunciaProtocoloComponent } from './canal-denuncia-protocolo/canal-denuncia-protocolo.component';

export const AppRoutes: Routes = [{
   path: '',
   redirectTo: 'home',
   pathMatch: 'full',
}, {
   path: '',
   component: MainComponent,
   children: [
      {
         path: 'home',
         component: HomeComponent
      },
      {
         path: 'lgpd',
         component: LgpdComponent
      },
      {
         path: 'relatorios-site',
         component: TransparenciaComponent
      },
      {
         path: 'contato',
         component: ContatoComponent
      },
      {
         path: 'acesso-restrito',
         component: AcessoRestritoComponent
      },
      {
         path: 'recuperar-acesso',
         component: AcessoRestritoResetComponent
      },
      {
         path: 'primeiro-acesso',
         component: PrimeiroAcessoComponent
      },
      {
         path: 'simulacao-emprestimo',
         component: SimulacaoEmprestimoComponent
      },
      {
         path: 'novo-organograma',
         component: OrganogramaComponent
      },
      {
         path: 'canal-denuncia',
         component: CanalDenunciaComponent
      },
      {
         path: 'canal-denuncia-registro',
         component: CanalDenunciaRegistroComponent
      },
      {
         path: 'canal-denuncia-protocolo',
         component: CanalDenunciaProtocoloComponent
      },
      {
         path: 'aniversariantes',
         component: AniversariantesDerminasComponent
      },
      {
         path: 'nova-senha-acesso',
         component: NovaSenhaAcessoComponent
      },
      {
         path: 'pesquisa/:token',
         component: PesquisaSatisfacaoComponent
      },
      {
         path: 'pesquisa-satisfacao',
         component: PesquisaSatisfacaoDerminasComponent
      },
      {
         path: 'links-uteis',
         component: PaginaLinksComponent
      },
      {
         path: 'pesquisa-satisfacao-atendimento',
         component: PesquisaSatisfacaoAtendimentoComponent
      },
      {
         path: 'pesquisa-satisfacao-atendimento/:token',
         component: PesquisaSatisfacaoAtendimentoComponent
      },
      {
         path: 'responder-pesquisa-satisfacao/:token',
         component: PesquisaSatisfacaoComponent
      },
      {
         path: 'minha-area',
         component: MinhaAreaSiteComponent,
         canActivate: [AuthGuard],
      },
      {
         path: 'minha-area/:local',
         component: MinhaAreaSiteComponent,
         canActivate: [AuthGuard],
      },
      {
         path: 'meus-dados',
         component: CadastroParticipantesComponent,
         canActivate: [AuthGuard],
      },
      {
         path: 'meu-emprestimo',
         component: ConsultaEmprestimoComponent,
         canActivate: [AuthGuard],
      },
      {
         path: 'relatorios',
         component: AcessoRestritoRelatoriosComponent,
         canActivate: [AuthGuard],
      },
      {
         path: 'recadastramento',
         component: RecadastamentoComponent,
         canActivate: [AuthGuard],
      },
      {
         path: 'contra-cheque',
         component: ContraChequesComponent,
         canActivate: [AuthGuard],
      },
      {
         path: 'informes',
         component: InformesComponent,
         canActivate: [AuthGuard],
      },
      {
         path: 'resolucao-cnpc',
         component: ResolucaoCnpcComponent,
         canActivate: [AuthGuard],
      },
      {
         path: 'alterar-senha',
         component: AlterarSenhaComponent,
         canActivate: [AuthGuard],
      },
      {
         path: 'extrato-atas',
         component: ExtratoAtasComponent,
         canActivate: [AuthGuard],
      },
      {
         path: 'documento/:id',
         component: DocumentosSiteComponent
      },
      {
         path: 'conteudo/:id',
         component: ConteudoSiteComponent
      }, {
         path: '',
         loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)
      }, {
         path: 'pricing',
         component: PricingComponent
      }, {
         path: 'contact',
         component: ContactComponent
      }, {
         path: 'features',
         component: FeaturesComponent
      }, {
         path: 'about',
         component: AboutComponent
      }, {
         path: 'search',
         component: SearchComponent
      }, {
         path: 'support',
         component: SupportComponent
      }, {
         path: '',
         loadChildren: () => import('./portfolio/portfolio.module').then(m => m.PortfolioModule)
      }, {
         path: '',
         loadChildren: () => import('./testimonial/testimonial.module').then(m => m.TestimonialModule)
      }, {
         path: 'sidebar-widgets',
         component: sidebarWidgetsComponent
      }, {
         path: '',
         loadChildren: () => import('./session/session.module').then(m => m.SessionModule)
      }, {
         path: '',
         loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
      }, {
         path: 'about/:keyword', component: AboutComponent
      }
   ]
}];

@NgModule({
   imports: [
      CommonModule,
      RouterModule.forRoot(AppRoutes)
   ],
   exports: [RouterModule],
   declarations: []
})
export class AppRoutingModule { }
