<!--  Top menu -->
<nav id="mainNav"
	class="navbar navbar-expand-md navbar-dark fixed-top bg-primary {{router?.url == '/home' ? '' : 'bg-primary'}}"
	style="background-color: #FFFFFF!important;border-bottom: 1.5px solid #CCC;">
	<div class="container">
		<a class="navbar-brand" [routerLink]="['/', 'home']" routerLinkActive="active-link">
			<img src="assets/derminas/logo_derminas.png" class="img-fluid" style="width: 290px;">
		</a>
		<div style="text-align: right;">
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
				aria-controls="navbarCollapse" aria-expanded="true" aria-label="Toggle navigation">
				<i class="fa fa-bars" aria-hidden="true"></i>
			</button>
			<button class="navbar-toggler" type="button" (click)="restrito()" style="cursor: pointer;"
				ngbTooltip="Acesso Restrito" *ngIf="!logado">
				<i class="fa fa-lock" aria-hidden="true"></i>
			</button>
			<button class="navbar-toggler" type="button" (click)="sair()" style="cursor: pointer;" ngbTooltip="Sair"
				*ngIf="logado">
				<i class="fa fa-sign-out" aria-hidden="true"></i>
			</button>
		</div>
		<div class="collapse navbar-collapse" id="navbarCollapse">
			<ul menuToggleDirective id="main" class="navbar-nav ml-auto main-menu list-unstyled" style="width: 935px!important;">
				<li menuToggleLink class="nav-item" routerLinkActive="open" *ngFor="let menuItem of menus">
					<a menuToggle class="nav-link" routerLinkActive="active-link" *ngIf="menuItem.type == 'link'"
						(click)="navegar(menuItem)">
						<!-- <a menuToggle class="nav-link" [routerLink]="['/', menuItem.state]" routerLinkActive="active-link" *ngIf="menuItem.type == 'link'" (click)="removeCollapseInClass()"></a> -->
						{{ menuItem?.name }}
					</a>
					<a menuToggle *ngIf="menuItem.type == 'sub'" class="nav-link" href="javascript:void(0)">{{
						menuItem?.name }}&nbsp;
						<i class="{{ menuItem?.icon }}"></i>
					</a>
					<ul *ngIf="menuItem.type == 'sub' && menuItem?.children" class="sub-menu arrow-up list-unstyled">
						<li class="nav-item" routerLinkActive="open" *ngFor="let grandchildItem of menuItem?.children">
							<a class="nav-link" routerLinkActive="active-link" *ngIf="grandchildItem.type == 'link'"
								(click)="navegar(grandchildItem)">
								{{ grandchildItem?.name }}
							</a>
						</li>
					</ul>
				</li>
				<li>
					<!-- <div class="search-form" click-outside (clickOutside)="onClickOutside($event)">
						<span data-target="#search-style-simple" (click)="searchActiveFunction()">
							<i class="fa fa-search"></i>
						</span>
						<div class="module-container" [ngClass]="{'search-active': searchactive}"
							id="search-style-simple">
							<form role="search" method="get" class="search-box" action="javascript:void(0);">
								<input type="search" class="form-control" placeholder="Search" value="" name="" />
								<button type="submit"><i class="fa fa-search"></i></button>
							</form>
						</div>
					</div> -->

					<div class="search-form no-print">
						<!-- <span (click)="restrito()" style="cursor: pointer;" ngbTooltip="Acesso Restrito">
							<i class="fa fa-user"></i> Área do Participante
						</span> -->
						<!-- <span><i class="fa fa-user fa-2x" style="color: #e0ce01;"></i></span> -->
						<button type="button" class="btn btn-outline" (click)="restrito()"
							style="cursor: pointer; background-color: #e0ce01; color: #FFFFFF; border-radius: 24px!important;"
							ngbTooltip="Acesse sua área restrita"> Acesso Restrito</button>

						<!-- <span (click)="sair()" style="cursor: pointer;" ngbTooltip="Clique aqui para sair"
							*ngIf="logado">
							<i class="fa fa-sign-out" aria-hidden="true"></i>
						</span> -->
					</div>
				</li>
			</ul>
		</div>
	</div>
</nav>