
import { Injectable } from '@angular/core';
import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';
import { DataService } from './data.services';

@Injectable()
export class SiteService extends DataService {

    context = 'Site';

    listarMenus(): Observable<any[]> {
        return this.get<any[]>('listarMenus');
    }

    obterRodape(): Observable<any> {
        return this.get<any>('obterRodape');
    }

    exibiralerta(): Observable<any> {
        return this.get<any>('exibiralerta');
    }

    obterContato(): Observable<any> {
        return this.get<any>('obterContato');
    }

    pesquisaSatisfacao(token: string): Observable<any> {
        return this.get<any>(`pesquisa?token=${token}`);
    }

    responderPesquisaSatisfacao(model: any): Observable<any> {
        return this.post<any>('responder', model);
    }

    respondersite(model: any): Observable<any> {
        return this.post<any>('respondersite', model);
    }
    responderpesquisa(model: any): Observable<any> {
        return this.post<any>('responderpesquisa', model);
    }
    responderatendimento(model: any): Observable<any> {
        return this.post<any>('responderatendimento', model);
    }

    listarNoticias(): Observable<any[]> {
        return this.get<any[]>('listarNoticias');
    }

    aniversariantes(): Observable<any> {
        return this.get<any>('aniversariantes');
    }

    aniversariantesHome(): Observable<any[]> {
        return this.get<any[]>('aniversariantesHome');
    }

    listarInformacoes(): Observable<any[]> {
        return this.get<any[]>('listarInformacoes');
    }

    listarInstitucional(): Observable<any> {
        return this.get<any>('listarInstitucional');
    }

    obterConteudoSite(id: number): Observable<any> {
        return this.get<any>('obterConteudoSite', id);
    }

    obterDocumentosSite(id: number): Observable<any> {
        return this.get<any>('obterDocumentosSite', id);
    }

    sendMessage(model: any): Observable<any> {
        return this.post<any>('contato', model);
    }

    arearestrita(model: any): Observable<any> {
        return this.post<any>('arearestrita', model);
    }

    obterPensionista(id: number): Observable<any> {
        return this.get<any>('obterPensionista', id);
    }

    obterParticipante(id: number): Observable<any> {
        return this.get<any>('obterParticipante', id);
    }

    view(): Observable<any> {
        return this.get<any>('view');
    }

    recadastramento(model: any): Observable<any> {
        return this.post<any>('recadastramento', model);
    }
    salvarDependente(model: any): Observable<any[]> {
        return this.post<any[]>('salvarDependente', model);
    }
    calculo(model: any): Observable<any> {
        return this.post<any>('calculo', model);
    }
    pesquisapendente(cpf: string): Observable<any> {
        return this.get<any>(`pesquisapendente?cpf=${cpf}`);
    }

    // canal de denuncias
    protocolo(protocolo: string): Observable<any> {
        return this.get<any>(`protocolo?protocolo=${protocolo}`);
    }
    denuncia(model: any): Observable<any> {
        return this.post<any>('denuncia', model);
    }
    replica(model: any): Observable<any> {
        return this.post<any>('replica', model);
    }
    finalizar(protocolo: string): Observable<any> {
        return this.get<any>(`finalizar?protocolo=${protocolo}`);
    }

    menusRelatorioSite(): Observable<any[]> {
        return this.get<any[]>('menusRelatorioSite');
    }
    obterRelatoriosSite(id: number): Observable<any> {
        return this.get<any>('obterRelatoriosSite', id);
    }

    obterRelatoriosSiteChave(): Observable<any> {
        return this.get<any>('resolucoes');
    }
}
