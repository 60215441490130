import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

export interface FileModel {
    id: string;
    name: string;
    title: string;
    data: string;
    contentType: string;
    extension: string;
    fileType: number;
    isValid: boolean;
}


@Injectable({
    providedIn: 'root'
})
export class DownloadService {
    public downloadFileFromFileModel(fileModel: FileModel) {

        const blob = this.getBlob(fileModel.data, fileModel.contentType, 1);
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = fileModel.name;
        a.click();

        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
    }

    public downloadFileFromByte(data, contentType, name) {
        const blob = this.getBlob(data, contentType, 1);
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = name;
        a.click();

        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
    }

    public OpenFileFromByte(data, contentType, name) {
        const blob = this.getBlob(data, contentType, 1);
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
    }

    public OpenFileFromURL(file) {
        window.open(file, '_blank');
    }

    // passar o arquivo do file upload ($event.target.files)
    public imagemToByte(filesSelected: any, form: FormGroup) {
        if (filesSelected.length > 0) {
            const fileToLoad = filesSelected[0];
            let srcData: any;
            const fileReader = new FileReader();
            fileReader.onload = (fileLoadedEvent) => {
                srcData = (fileLoadedEvent.target as FileReader).result;
                form.patchValue({ arquivo: srcData });
            };
            fileReader.readAsDataURL(fileToLoad);
        }
    }

    public downloadJpegFromByte(byte: string, fileName: string) {
        const blob = this.getBlob(byte, 'image/jpeg', 1);
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = fileName;
        a.click();

        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
    }

    private getBlob(b64Data: string, contentType: string = '', sliceSize: number = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) { byteNumbers[i] = slice.charCodeAt(i); }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
    }

    blobToBase64(blob: any) {
        const reader = new FileReader();
        let blobAsBytes;
        reader.readAsArrayBuffer(blob);
        reader.onloadend = (event) => {
            // The contents of the BLOB are in reader.result:
            blobAsBytes = reader.result;
        };

        return blobAsBytes;
    }

    arrayBufferToBase64(uarr) {
        const strings = [];
        const chunksize = 0xffff;
        const len = uarr.length;

        for (let i = 0; i * chunksize < len; i++) {
            strings.push(String.fromCharCode.apply(null, uarr.subarray(i * chunksize, (i + 1) * chunksize)));
        }

        return strings.join('');
    }
}
