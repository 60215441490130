import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { SiteService } from './services/site.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'app';
  display = false;
  aviso: any;

  @HostListener('window:beforeunload', ['$event'])
  verificarPageReload(event) {
    localStorage.removeItem('localUsuarioDerminas');
    localStorage.removeItem('pesquisa');
  }

  constructor(
    public servico: SiteService,
    public router: Router
  ) {
    this.servico.exibiralerta()
      .subscribe(result => {
        if (result.ativo) {
          if (window.location.href.indexOf('/home') !== -1) {
            this.aviso = result;
            this.display = true;
          }
        }
      },
        err => {
          console.log(err);
        });
  }

  ngOnInit() {
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
  }

  abrir(link: string) {
    this.display = false;
    window.open(link, '_blank');
  }

  fechar() {
    this.display = false;
  }
}
