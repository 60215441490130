import { finalize, takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import Swal from 'sweetalert2';
import { BehaviorSubject, Subject, Subscription, timer } from 'rxjs';
import { PesquisaSatisfacaoSiteComponent } from 'app/pesquisa-satisfacao-site/pesquisa-satisfacao-site.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SiteService } from './site.service';
import { MensagensService } from './mensagens.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer';

@Injectable()
export class ServioPesqusaService {
    onPesquisaChanged: BehaviorSubject<any>;
    private _unsubscribeAll: Subject<any>;
    public timer: Subscription;
    public isPause = false;
    public alive = true;

    constructor(private modalService: NgbModal,
        public mensagensService: MensagensService,
        public servico: SiteService) {
        this.onPesquisaChanged = new BehaviorSubject([]);
    }

    showPopupAfterSpecificTime(cpf, time = 120000) {
        this.isPause = true;
        const numbers = timer(time);
        this.timer = numbers.subscribe(x => {
            this.isPause = false;
            this.servico.pesquisapendente(cpf)
                .pipe(finalize(() => {
                }))
                .subscribe(result => {
                    if (result.pendente) {
                        const options = {
                            title: 'Pesquisa de Satisfação!',
                            text: 'Você deseja responder nossa pesquisa agora?',
                            type: 'warning',
                            showCancelButton: true,
                            allowOutsideClick: false,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            cancelButtonText: 'Não',
                            confirmButtonText: 'Sim'
                        };
                        Swal.fire(options).then((resultado) => {
                            if (resultado.value) {
                                // tslint:disable-next-line:max-line-length
                                const modalRef = this.modalService.open(PesquisaSatisfacaoSiteComponent, { size: 'md', backdrop: 'static', keyboard: false });
                                modalRef.componentInstance.dados = result;
                                modalRef.result.then((resposta) => {
                                    if (resposta !== null) { }
                                }).catch((res) => { });
                            } else {
                                this.showPopupAfterSpecificTime(cpf, time = 9000);
                            }
                        });
                    }
                },
                    err => {
                        const erro = this.mensagensService.recuperarMensagemErro(err);
                    });
        });
    }

    iniciarContador() {
        this.onPesquisaChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(item => {
                setTimeout(() => {
                    this.onPesquisaChanged.next(true);
                }, 2000);
            });
    }

    pararContador() {
        this.onPesquisaChanged.complete();
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    mostrarPesquisa(cpf) {
        // Observable.timer(0, 120000)
        //     .takeWhile(() => this.alive)
        //     .subscribe(() => {
        //         console.log('123564');
        //         this.fazerpesquisa(cpf);
        //     });
    }

    fazerpesquisa(cpf) {
        this.servico.pesquisapendente(cpf)
          .pipe(finalize(() => {
          }))
          .subscribe(result => {
            if (result.pendente) {
              const options = {
                title: 'Pesquisa de Satisfação!',
                text: 'Você deseja responder nossa pesquisa agora?',
                type: 'warning',
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Não',
                confirmButtonText: 'Sim'
              };
              Swal.fire(options).then((resultado) => {
                if (resultado.value) {
                  // tslint:disable-next-line:max-line-length
                  const modalRef = this.modalService.open(PesquisaSatisfacaoSiteComponent, { size: 'md', backdrop: 'static', keyboard: false });
                  modalRef.componentInstance.dados = result;
                  modalRef.result.then((resposta) => {
                    if (resposta !== null) { }
                  }).catch((res) => { });
                }
              });
            }
          },
            err => {
              const erro = this.mensagensService.recuperarMensagemErro(err);
            });
      }
}

