import { AcessoRestritoSiteService } from 'app/services/acesso-restrito.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { EventosService } from 'app/services/eventos.service';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ValidadorService } from 'app/services/validador.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.css']
})
export class AlterarSenhaComponent implements OnInit, OnDestroy {

  formGroup: FormGroup;
  inSpinner = false;
  erromessage = '';
  nomeUsuario: string;
  idPensionista = 0;
  usuario: any;

  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    private eventosService: EventosService,
    public validadorService: ValidadorService,
    public acessoRestritoSiteService: AcessoRestritoSiteService,
    public router: Router,
    public mensagensService: MensagensService
  ) {
    /* Page title */
    this.pageTitleService.setTitle('Alterar Minha Senha');
    /* Page subTitle */
    this.pageTitleService.setSubTitle('');

    this.usuario = JSON.parse(localStorage.getItem('localUsuarioDerminas'));
    if (!!this.usuario) {
      this.nomeUsuario = this.usuario.nomeUsuario;
      this.idPensionista = this.usuario.idPensionista;
    } else {
      this.router.navigate(['acesso-restrito']);
    }

  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      senha: [null, Validators.required],
      idUsuario: this.usuario.userId,
      novaSenha: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(10)]],
      repetirNovaSenha: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(10)]],
      cpf: [{ value: this.usuario.cpf, disabled: true }],
    });
  }

  ngOnDestroy(): void {
  }

  sair() {
    localStorage.removeItem('localUsuarioDerminas');
    localStorage.removeItem('pesquisa');
    this.router.navigate(['acesso-restrito']);
  }

  voltar() {
    this.router.navigate(['minha-area']);
  }

  alterar() {
    this.erromessage = '';
    if (this.formGroup.valid) {
      this.inSpinner = true;
      this.acessoRestritoSiteService.alterarminhasenha(this.formGroup.value)
        .pipe(finalize(() => {
          this.inSpinner = false;
        }))
        .subscribe(result => {
          let timerInterval;
          Swal.fire({
            title: 'Sucesso!',
            html: 'Senha alterada com sucesso.',
            timer: 4000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              timerInterval = setInterval(() => {
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          }).then((resultado) => {
            this.router.navigate(['minha-area']);
          });
        },
          err => {
            this.eventosService.logou(false);
            this.inSpinner = false;
            const erro = this.mensagensService.recuperarMensagemErro(err);
            this.erromessage = erro;
          });
    } else {
      this.inSpinner = false;
      this.validadorService.validarTodosCampos(this.formGroup);
      this.erromessage = 'Existem campos obrigatórios sem o devido preenchimento';
    }
  }

}
