import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MensagensService } from 'app/services/mensagens.service';
import { SiteService } from 'app/services/site.service';
import { ChkService } from '../service/chk.service';
declare var $: any;

@Component({
   selector: 'angly-home',
   templateUrl: './home.component.html',
   styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

   /* Variables */
   homeContent: any;
   services: any;
   projectGallary: any;
   posts: any;
   team: any;
   pricingContent: any;
   contact: any;
   videoContent: any;
   mobileFeatured: any;
   noticias: any;
   cookieLawSeen: boolean;
   @ViewChild('cookieLaw') cookieLawEl: any;
   subscribeFormClasses: any = { rowClass: 'row', fieldClass: 'col-sm-12 col-md-6' };
   display = false;
   aviso: any;

   constructor(
      public servico: SiteService,
      public router: Router,
      public mensagensService: MensagensService
   ) {

      this.servico.listarInformacoes().
         subscribe(response => { this.posts = response; },
            err => console.log(err),
            () => this.posts
         );

      this.servico.obterRodape().
         subscribe(response => { this.contact = response; },
            err => console.log(err),
            () => this.contact
         );

      this.servico.listarNoticias().
         subscribe(response => { this.noticias = response; },
            err => console.log(err),
            () => this.noticias
         );

      // this.servico.exibiralerta()
      //    .subscribe(result => {
      //       if (result.ativo) {
      //          if (window.location.href.indexOf('/home') !== -1) {
      //             this.aviso = result;
      //             this.display = true;
      //          }
      //       }
      //    },
      //       err => {
      //          console.log(err);
      //       });
   }

   ngOnInit() {

   }

   abrirLink(url: string) {
      window.open(url, '_blank');
   }

   abrirWhatsApp(whatsApp: string, texto: string) {
      const notificacao = `https://api.whatsapp.com/send?phone=55${whatsApp}&text=${texto}`;
      setTimeout(() => {
         window.open(notificacao, '_blank');
      }, 5);
   }

   ngAfterViewInit(): void {
      const cookei = JSON.parse(localStorage.getItem('aceitouCookie'));
      if (cookei === null) {
         if (this.cookieLawEl !== undefined) { this.cookieLawSeen = this.cookieLawEl.cookieLawSeen; }
      }
   }

   abrir(link: string) {
      this.display = false;
      window.open(link, '_blank');
   }

   fechar() {
      this.display = false;
   }

   dismiss(): void {
      const data = {
         dataAceite: new Date(),
         aceite: 'SIM'
      };
      localStorage.setItem('aceitouCookie', JSON.stringify(data));
      this.cookieLawEl.dismiss();
   }
   /*
    * getContent is used for get the home page content.
    * Used variables is videoContent and mobileFeatured.
    */
   getContent(content) {
      this.videoContent = content.video_content;
      this.mobileFeatured = content.mobile_featured;
   }
}
