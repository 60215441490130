<div id="topocontato" class="section-gap-mini"></div>
<div class="contact-widget section-gap">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Relatórios</li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div class="category-list">
                    <ul class="list-unstyled" *ngIf="reports">
                        <li *ngFor="let category of reports" class="mb-2 border-left  {{category?.border_color}}"
                            [ngClass]="{'rep-selecionado': idSelecionado === category?.id }">
                            <a href="javascript:void(0)" class="p-2 d-block"
                                (click)="selecionar(category?.id, category?.chave)"
                                [ngClass]="{'rep-selecionado-font': idSelecionado === category?.id }">
                                <span class="align-top">{{category?.name}}</span>
                                <i class="fa fa-hand-o-right pull-right text-right" aria-hidden="true"
                                    style="color: #FFFFFF;" *ngIf="idSelecionado === category?.id"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">

                <div class="alert alert-primary col-12" role="alert" *ngIf="idSelecionado === 0">
                    Selecione o relatório para exibir as informações!
                </div>

                <div class="table-responsive user-list" *ngIf="idSelecionado > 0 && tipoReport === 'R'">
                    <table class="table" style="width: 100%;">
                        <thead>
                            <tr>
                                <th scope="col" style="width: 70%;">Descrição</th>
                                <th scope="col" style="width: 20%;">Data</th>
                                <th scope="col" style="width: 10%; text-align: center;">Baixar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of arquivos">
                                <td class="col-md" style="width: 70%; text-align: left;">
                                    {{item?.descricao}}
                                </td>
                                <td class="col-md" style="width: 20%; text-align: left;">
                                    {{item?.dataArquivo | date: 'MM/yyyy'}}
                                </td>
                                <td class="col-md" style="width: 10%; text-align: center;">
                                    <button type="button" class="btn btn-outline-primary btn-icon btn-sm"
                                        placement="top" style="margin-right: 5px;" ngbTooltip="Baixar Arquivo"
                                        (click)="abrir(item)">
                                        <i class="fa fa-file-pdf-o"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="table-responsive user-list" *ngIf="idSelecionado > 0 && tipoReport === 'X'">
                    <app-resolucao-cnpc-transparencia></app-resolucao-cnpc-transparencia>
                </div>
            </div>

        </div>
    </div>
</div>