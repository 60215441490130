<div class="modal-header" style="background:#f5f5f5">
    <h6 class="modal-title pull-left" style="color:#000000">
        <i class="fa fa-files-o"></i> Pesquisa de Satisfação
    </h6>
    <button type="button" (click)="close()" class="close pull-right" data-dismiss="modal" aria-label="Close"
        style="color:#000000">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">

    <div class="row">
        <div class="col-xl-12">
            <form [formGroup]="formGroup">
                <div class="form-group mb-4" *ngIf="erromessage.length > 0">
                    <div class="alert alert-danger" role="alert">
                        {{erromessage}}
                    </div>
                </div>
                <div class="form-group mb-4">
                    <div class="check-payment">
                        <p>Como foi sua experiência?</p>
                        <div class="mb-3">
                            <label class="custom-control custom-radio mb-0">
                                <input class="custom-control-input" id="radio5" name="resposta" value="5" type="radio"
                                    formControlName="resposta">
                                <span class="custom-control-indicator"></span>
                                <span class="custom-control-description">Muito satisfeito</span>
                            </label>
                        </div>
                        <div class="mb-3">
                            <label class="custom-control custom-radio mb-0">
                                <input class="custom-control-input" id="radio4" name="resposta" value="4" type="radio"
                                    formControlName="resposta">
                                <span class="custom-control-indicator"></span>
                                <span class="custom-control-description">Satisfeito</span>
                            </label>
                        </div>
                        <div class="mb-3">
                            <label class="custom-control custom-radio mb-0">
                                <input class="custom-control-input" id="radio3" name="resposta" value="3" type="radio"
                                    formControlName="resposta">
                                <span class="custom-control-indicator"></span>
                                <span class="custom-control-description">Pouco satisfeito</span>
                            </label>
                        </div>
                        <div class="mb-3">
                            <label class="custom-control custom-radio mb-0">
                                <input class="custom-control-input" id="radio2" name="resposta" value="2" type="radio"
                                    formControlName="resposta">
                                <span class="custom-control-indicator"></span>
                                <span class="custom-control-description">Insatisfeito</span>
                            </label>
                        </div>
                        <div class="mb-3">
                            <label class="custom-control custom-radio mb-0">
                                <input class="custom-control-input" id="radio1" name="resposta" value="1" type="radio"
                                    formControlName="resposta">
                                <span class="custom-control-indicator"></span>
                                <span class="custom-control-description">Muito insatisfeito</span>
                            </label>
                        </div>
                        <p>
                            <small class="text-muted"
                                *ngIf="formGroup.get('resposta').hasError('selecionarOpcao')"><span
                                    style="color: red;">Selecione uma resposta.</span></small>
                        </p>
                    </div>
                </div>
                <div class="form-group mb-4">
                    <textarea id="mensagem" class="form-control" rows="5" placeholder="Observações" maxlength="1000"
                        formControlName="observacoes"></textarea>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div class="text-left" style="float:left">
        <button type="button" class="btn btn-secondary" (click)="close()">
            <span class="feather icon-x-circle"></span> Fechar
        </button>
    </div>
    <div class="text-right" style="float:right">
        <button class="btn btn-primary mr-2 mb-2 mb-md-0" type="button" [disabled]="inSpinner"
            (click)="salvar()" placement="top" ngbTooltip="Enviar Anexo">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="inSpinner"></span>
            <span class="fa fa-save" *ngIf="!inSpinner"></span>
            {{inSpinner ? 'Aguarde...' : 'Salvar'}}
        </button>
    </div>
</div>