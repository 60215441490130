import { Component, OnInit } from '@angular/core';
import { PageTitleService } from './../core/page-title/page-title.service';

@Component({
  selector: 'app-pagina-links',
  templateUrl: './pagina-links.component.html',
  styleUrls: ['./pagina-links.component.css']
})
export class PaginaLinksComponent implements OnInit {

  links: any[] = [
    {
      image_path: '../assets/derminas/banner_1024x768.png',
      title: 'UniAbrapp',
      content: `
      A UniAbrapp acredita que o conhecimento é uma ferramenta poderosa para o empoderamento financeiro e a construção de um futuro previdente, assim, é com grande satisfação que estamos disponibilizando valiosos conteúdos de Educação Financeira e Previdenciária de forma gratuita, consolidados em um único lugar.
      Estamos certos de que a promoção dessas práticas contribui não apenas para o bem-estar financeiro individual, mas também para o desenvolvimento sustentável de nossa comunidade.
      Juntos, fortaleceremos o entendimento financeiro e previdenciário, construindo um futuro mais seguro e próspero para todos.
      `,
      read_more_url: 'https://www.abrapp.org.br/educacao-financeira-previdenciaria/?utm_source=akna&utm_medium=email&utm_campaign=Campanha-de-Educacao-Financeira-e-Previdenciaria-l-UNIABRAPP'
    },
    {
      image_path: '../assets/derminas/site_investidor.png',
      title: 'Portal do Investidor',
      content: `
      O Comitê Consultivo de Educação, instituído pela Comissão de Valores Mobiliários por meio da Deliberação
      CVM nº 498/06, congrega a CVM e diversas entidades privadas representativas do mercado de capitais com o
       propósito principal de promover e apoiar projetos educacionais que contribuam para a melhoria dos
       padrões de educação financeira da população brasileira. Para tanto, além das atividades educacionais de
       cada associação, instituto ou órgão, são também empreendidas ações conjuntas dos seus membros, desenvolvendo
        programas de caráter plural e gratuito para diferentes segmentos da sociedade.
      `,
      read_more_url: 'https://www.investidor.gov.br/index.html'
    }
    // {
    //   image_path: '../assets/derminas/site_vida_dinheiro.png',
    //   title: 'Vida e Dinheiro',
    //   content: `
    //   A Estratégia Nacional de Educação Financeira – ENEF – é uma mobilização em torno da promoção de ações de educação financeira,
    //   securitária, previdenciária e fiscal no Brasil. O objetivo da ENEF, criada através do Decreto Federal 7.397/2010,
    //    e renovada pelo Decreto Federal nº 10.393, de 9 de junho de 2020, é contribuir para o fortalecimento da cidadania ao
    //    fornecer e apoiar ações que ajudem a população a tomar decisões financeiras mais autônomas e conscientes.
    // tslint:disable-next-line:max-line-length
    //    A nova ENEF reúne representantes de 8 órgãos e entidades governamentais, que juntos integram o Fórum Brasileiro de Educação Financeira – FBEF.
    //   `,
    //   read_more_url: 'https://www.vidaedinheiro.gov.br'
    // }
  ];

  constructor(private pageTitleService: PageTitleService) {
    /* Page title */
    this.pageTitleService.setTitle('Educação Financeira');
    /* Page subTitle */
    this.pageTitleService.setSubTitle('Links úteis');
  }

  ngOnInit(): void {
  }

}
