<div class="section-gap-medium"></div>
<div class="contact-widget section-gap-mini">
    <div class="container">
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Aniversariantes</li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="alert alert-info" role="alert">
                    <p style="text-align: center;"><b>A Derminas parabeniza a todos os aniversariantes do mês de 
                            {{diames}}</b></p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6" *ngFor="let card of aniversariantes?.card1">
                <h5 class="card-title mb-2 mt-2">{{card.label}}</h5>
                <span class="text-primary font-italic mb-3"> {{card.nome}}</span>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6" *ngFor="let card of aniversariantes?.card2">
                <h5 class="card-title mb-2 mt-2">{{card.label}}</h5>
                <span class="text-primary font-italic mb-3"> {{card.nome}}</span>
            </div>
        </div>
    </div>
</div>