import { SiteService } from './../services/site.service';
import { Component, OnInit, HostListener, Inject, OnDestroy, } from '@angular/core';
import { MenuItems } from '../core/menu/menu-items/menu-items';
import { Router } from '@angular/router';
import { ClickOutside } from '../core/directive/click-outside.directive';
import { MensagensService } from 'app/services/mensagens.service';
import * as moment from 'moment';
import { EventosService } from 'app/services/eventos.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: '[angly-menu]',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  searchactive = false;
  menus: any;
  logado = false;
  private _unsubscribeAll: Subject<any>;

  constructor(
    public router: Router,
    public servico: SiteService,
    public menuItems: MenuItems,
    private eventosService: EventosService,
    public mensagensService: MensagensService) {
    this.servico.listarMenus()
      .subscribe(result => {
        this.menus = result;
      },
        err => {
          const error = this.mensagensService.recuperarMensagemErro(err);
          console.log(error);
        });
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.eventosService.onUsuarioLogou
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(logado => {
        this.logado = logado;
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getMainMenu(): any[] {
    return this.menus;
  }

  sair() {
    localStorage.removeItem('localUsuarioDerminas');
    localStorage.removeItem('pesquisa');
    this.eventosService.logou(false);
    this.logado = false;
    this.router.navigate(['acesso-restrito']);
  }

  restrito() {
    $('#navbarCollapse').removeClass('show');
    const usuario = JSON.parse(localStorage.getItem('localUsuarioDerminas'));
    const agora = moment();
    if (!!usuario) {
      if (moment(usuario.validade).isAfter(agora)) {
        this.router.navigate(['minha-area']);
        this.logado = true;
        this.eventosService.logou(true);
        return;
      } else {
        this.router.navigate(['acesso-restrito']);
        this.logado = false;
        this.eventosService.logou(false);
        return;
      }
    }
    this.logado = false;
    this.eventosService.logou(false);
    this.router.navigate(['acesso-restrito']);
  }

  searchActiveFunction() {
    this.searchactive = !this.searchactive;
  }

  onClickOutside(event: Object) {
    if (event && event['value'] === true) {
      this.searchactive = false;
    }
  }

  public navegar(item: any) {
    $('#navbarCollapse').removeClass('show');
    if (item.state === 'conteudo') {
      if (item.isLink) {
        if (item.isInterno) {
          window.location.replace(item.redirecionamento);
        } else {
          window.open(item.redirecionamento, '_blank');
        }
      } else {
        this.router.navigate([item.state + '/' + item.idFuncionalidade]);
      }
    } else if (item.state === 'documento') {
      this.router.navigate([item.state + '/' + item.idFuncionalidade]);
    } else {
      this.router.navigate([item.state]);
    }
  }
}
